@import '../../../../shared/styles/main.scss';



.c-scoresheet-btn {
  min-width: 90px;
  color: inherit;
  border: 3px solid #FAAD14;
  background-color: #FFFBE6;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  position: relative;

  &:focus:active {
    background-color: #FAAD14;
  }

  &--disabled {
    min-width: 90px;
    position: relative;
    background: none;
    color: inherit;
    border: 3px solid #D4D4D4;
    padding: 8px 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    cursor: not-allowed;
  }

  &__warning-icon {
    position: absolute;
    top: -10px;
    left: -20px;
  }
}