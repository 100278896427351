@import '../../../../shared/styles/main.scss';
@import '../button.scss';

.btn-secondary-container {
  button {
    height: unset;
    border-radius: 5px;
    // border-radius: 4px;
    // &:disabled {
    //   color: $endeavor-blue-disabled !important;
    //   background-color: $white !important;
    //   border-color: $endeavor-blue-disabled !important;
    // }
    // &[disabled] {
    // color: $endeavor-blue-disabled !important;
    // background-color: $white !important;
    // border-color: $endeavor-blue-disabled !important;
    // }
    
  }
}
.btn-secondary {
  text-shadow: none;
  background-color: $white;
  color: $endeavor-blue;
  border: 1px solid $endeavor-blue;
  border-radius: 4px;
  &:hover {
    color: #fff;
    background-color: $aquamarine;
    border-color: $aquamarine;
  }
}