.partner-tile {
  &__name {
    font-weight: bold;
    
  }

  &__phone {
    margin-top: 10px;
  }
  &__meta-content {
    width: 50%;
    // width: 75%;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
  }
  &__meta-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
}
