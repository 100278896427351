.no-file-chosen {
  height: 48px;
  border-radius: 24px;
  background-color: #E5EFF7;
  width: 100%;
  p {
    color: #333333;
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 48px;
    padding-left: 24px;
  }
}