@import '../../../../shared/styles/main.scss';

.scoresheet-module {
  border: 1px solid #C6C4C6;
  background-color: $white;
  min-height: 100px;
  display: flex;
  margin-bottom: 24px;
  padding-bottom: 24px;
  flex-direction: column;
  @include md {
    flex-direction: row;
  }


  &__name {
    background-color: #231F20;
    color: $white;
    width: 110px;
    text-align: center;
    align-self: start;
    padding: 6px 0px; 
    text-transform: uppercase;
  }

  &__content {
    width: 100%;
    padding-right: 44px;
  }

  &__fields-wrapper {
    margin-top: 12px;
  }

  &__field-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10px 0 0 0;
    width: 100%;
    flex-direction: column;
    &:not(:last-child) {
      border-bottom: solid 1px #DDDCDD;
    }
    @include md {
      flex-direction: row;
    }
  }

  &__field-module-eight {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10px 0 0 0;
    flex-direction: column;
    width: 100%;

    @include md {
      flex-direction: row;
    }
  }
  &__field {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10px 0 0 0;
    flex-direction: column;
    &:not(:last-child) {
      border-bottom: solid 1px #DDDCDD;
    }
    @include md {
      flex-direction: row;
    }
  }

  &__field-description {
    font-family: "Roboto", sans-serif;
    font-size: 1.6rem;
    line-height: 22px;
    font-weight: bold;
  }

  &__field-list {
    font-family: "Roboto", sans-serif;
    font-size: 1.6rem;
    line-height: 22px;
  }

  &__field-label {
    // padding-right: 60px;
    max-width: 500px;
    padding-bottom: 12px;
    padding-right: 5px;
  }

  &__radio-wrapper {
    margin-left: auto;
    flex-wrap: wrap;
    justify-content: flex-end;
    display: flex;
    align-items: flex-end;
  }
  &__radio-option {
    border: 1px solid #C1C1C1;
    background-color: #FFFFFF;
    margin-bottom: 12px;
    padding: 10px 30px;
    &:not(:first-child) {
      margin-left: 12px;
    }

    &--selected {
      background-color: $endeavor-blue;
      padding: 10px 30px;

    }
  }
}