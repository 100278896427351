@import '../../../../../../shared/styles/_breakpoints.scss';
@import '../../../../../../shared/components/Paragraph/paragraph.scss';
@import '../../../../../../shared/components/Link/Link.scss';


.import {
  margin-top: 40px;


  &__btn-delete {
    align-items: center;
    display: flex;
  }

  &__change-file {
    display: flex;
    margin-top: 20px;

    &__btn {
      margin-left: auto;
      color: #0066B3;
      font-family: Roboto;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 22px;
      text-align: center;
      width: 180px;
    }
  }
  &__row {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @include md {
      flex-direction: row;
    }
  }
  &__choose-file {
    margin-left: 20px;
  }

  &__list {
    background-color: #FFFFFF;
    // margin: 20px 5px 12px 5px;
    margin-top: 20px;
    padding: 24px 16px 16px 16px;
    // box-shadow: 0 2px 8px 0 rgba(0,0,0,0.15);
    overflow-y: auto;
    max-height: 377px;
    border: 2px solid #1890FF;

    &--errors {
      border: 2px solid #F5222D !important;
    }


    &-wrapper {
      // margin-top: 24px;
    }

  }
  &__invitee {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 8px;
    // padding: 8px 0px 8px 15px;
    padding: 8px 15px;
    // align-items: flex-start;
    // width: 100%;
    &-error-icon {
      width: 16px;
      height: 16px;
      color: #ED1C24;
    }
    &-error-message-row {
      display: flex;
      align-items: center;
    }
    &-error-message {
      color: #ED1C24;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 20px;
      margin-bottom: 0;
      padding-left: 6px;
    }
    &-email {
      min-height: 20px;
    }
    &--error {
      background-color: #FFF1F0;
    }
    &-col {
      display: flex;
      flex-direction: row;
      flex-basis: 100%;
      flex: 1;
      // align-items: center;
    }
    &-role-col {
      display: flex;
      flex-direction: column;
      flex-basis: 100%;
      flex: 1;
      // align-items: center;

    }
    &-avatar {
      margin-right: 12px;
      // border-radius: 50%;
      // object-fit: cover;
      // object-position: center;
    }
    &-name {
      color: #333333;
      font-family: Roboto;
      font-size: 1.4rem;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 18px;
    }

    &-role {
      color: #333333;
      font-family: Roboto;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 18px;
    }
  }
}