.add-link {
  font-size: 1.6rem;
  font-weight: 400;
  color: white;
  padding: 0;
  // margin-left: 20px;
  &__icon {
    padding-right: 8px;
  }
  &--white {
    color: white
  }
  &--primaryBlue {
    color: #0066B3;
  }

  &-inner {
    display: flex;
    align-items: center;
  }
}