@import '../../../shared/styles/main.scss';

.user-invitee-tile {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  &__avatar {
    margin-right: 12px;
  }

  &__text-container {

    white-space: nowrap;

    
  }
}