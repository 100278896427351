@import '../../../shared/styles/_breakpoints.scss';
@import '../../../shared/styles/_color.scss';
@import '../../../shared/styles/_font.scss';

.event-header {
  padding-bottom: 12px;
  margin: auto;
  position: relative;
  z-index: 10;

  &__content {
    max-width: 956px;
    margin: auto;
  }

  .left-shape {
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    background-position-x: right;
  }

  .right-shape {
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: left;
    position: absolute;
  }

  &__logo-wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__arrow {
    height: 14px;
    width: 8px;
    margin-right: 6px;

    &:hover {
      color: $endeavor-blue  !important;
    }
  }

  &__info {
    color: $white;
    font-family: $roboto;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    font-weight: 300;
    display: flex;
  }

  &__point {
    padding-left: 8px;
    padding-right: 8px;
  }

  &__back {
    padding-top: 30px;
    color: $white;
    font-family: $roboto;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 22px;
    font-weight: 300;

    &:hover,
    &:focus,
    &:active {
      color: $white  !important;
      text-decoration: underline;
    }
  }

  &__title {
    display: flex;
    align-items: baseline;
    flex-direction: column;
    // justify-content: space-between;
    // margin-top: 32px;
    border-bottom: 1px solid #4085AF;
    max-width: 940px;
    // margin-left: auto;
    padding-bottom: 10px;

    @include md {
      flex-wrap: wrap;
      flex-direction: row;
    }
  }

  &__admin {
    width: 33.33%;
  }
}