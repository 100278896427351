@import '../../../shared/styles/main.scss';

.event-time-block {
    margin: 0px 10px;
    width: 100%;
    background-color: white;
    margin-bottom: 20px;
    .ant-collapse-item {
        border: none;
    }

    .ant-collapse-header {
        background-color: #F4F4F4;
        padding: 8px 15px !important;

        .ant-collapse-arrow {
            color: #0066B3;
        }

        .time-picker-header {
            
            .title {
                color: #333333;
                font-family: Roboto;
                font-size: 14px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 20px;
                margin-right: 20px;
            }

            .checkbox {
                margin-bottom: 0px;

                .ant-checkbox {
                    font-size: 10px;
                }

                .span {
                    color: #333333;
                    font-family: Roboto;
                    font-size: 12px;
                    letter-spacing: 0;
                    line-height: 20px;
                }
            }

            @include md-max {
                .checkbox-column {
                    margin-top: 5px;
                }
            }
        }
    }

    .ant-collapse-content-box {
        padding: 12px 25px !important;

        .select-warpper {
            margin-bottom: 8px;

            @include xs-max {
                margin-bottom: 30px;
            }
        }

        .single-select-title {
            margin-right: 10px;
            color: #333333;
            font-family: Roboto;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 20px;
        }

        .select-title {
            width: 200px;
            color: #333333;
            font-family: Roboto;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 20px;
        }

        .select-start-list, .select-end-list {
            box-sizing: border-box;
            width: 110px;
            border: 1px solid #EBEBEB;
            background-color: #FFFFFF;
            box-shadow: 0 3px 5px 0 rgba(0,0,0,0.1);


            @include md-max {
                width: 100% !important;
            }

            .ant-select {
                width: 100%;
            }

            .ant-select-selection {
                background-color: white;

                &-selected-value {
                    color: #333333;
                    font-family: Roboto;
                    font-size: 14px;
                    letter-spacing: 0;
                }
            }
        }

        .select-component {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .select-to {
            display: flex;
            justify-content: center;
        }

        @include xs-max {
            .select-to {
                justify-content: flex-start;
            }

            .select-component {
                justify-content: flex-start;
            }
        }
    }
}


.event-time-blocker {
    margin: 0px 10px;
    width: 100%;
    background-color: white;
    margin-bottom: 20px;
    position: relative;

    .ant-collapse-item {
        border: none;
    }

    .ant-collapse-header {
        background-color: #F4F4F4;
        padding: 8px 15px !important;

        .ant-collapse-arrow {
            color: #0066B3;
        }

        .time-blocker-header {
            margin-right: 25px;
            
            .title {
                color: #333333;
                font-family: Roboto;
                font-size: 14px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 20px;
                margin-right: 20px;
            }

            .checkbox {
                margin-bottom: 0px;
            }

            .ant-checkbox + span {
                color: #333333;
                font-family: Roboto;
                font-size: 12px;
                letter-spacing: 0;
                line-height: 20px;
                display: flex;
                align-items: center;
            }

            @include md-max {
                .checkbox-column {
                    margin-top: 5px;
                }
            }
        }
    }
    &.header-disabled {
        .ant-collapse-header {
            background-color: #FFFBE6;
        }
    }
    .add-another-blocker-link {
        padding-left: 40px;
    }

    .error-message-block {
        color: red;
        margin-left: 40px;
    }

    .timer-blocker-item-wrapper {
        display: flex;
        flex-wrap: wrap;
        padding: 10px 40px;

        .block-separator {
            height: 1px;
            width: 100%;
            border-radius: 4px;
            background-color: #DDDCDD;
            margin: 20px 0px;
        }  

        .anticon, .ant-select-arrow-icon {
            color: #0066B3 !important;
        }

        .ant-select-selection, .ant-input {
            border-radius: 0px;
        }
    }

    .timer-blocker-delete {
        position: absolute;
        width: 32px;
        margin-left: -50px;
        margin-top: 26px;
    }

    .blocker-label {
        color: #333333;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
        margin-bottom: 4px;
    }

    .blocker-name-wrapper {
        display: flex;
        flex-direction: column;
        margin-right: 44px;

        .blocker-name-input {
            height: 36px;
            width: 250px;
        }
    }

    .blocker-days-wrapper {
        display: flex;
        flex-direction: column;

        .blocker-days-select {
            min-height: 36px;
            min-width: 260px;
            max-width: 360px;
            
            .ant-select-selection__rendered {
                min-height: 36px;
            }

            li {
                height: 28px;
                line-height: 28px;
            }

            .ant-select-lg, .ant-select-selection--multiple {
                min-height: 36px;
                padding-bottom: 0px;
            }
        }

    }

    .blocker-time-range-wrapper {
        display: flex;
        flex-direction: column;
        
        .blocker-timer-select {
            display: flex;
            flex-direction: row;
            align-items: center;

            .select-start-list, .select-end-list {
                box-sizing: border-box;
                width: 110px;
                border: 1px solid #EBEBEB;
                background-color: #FFFFFF;
                box-shadow: 0 3px 5px 0 rgba(0,0,0,0.1);

                .ant-select {
                    width: 100%;
                }
    
                .ant-select-selection {
                    background-color: white;
    
                    &-selected-value {
                        color: #333333;
                        font-family: Roboto;
                        font-size: 14px;
                        letter-spacing: 0;
                    }
                }
            }
    
            .select-start-list {
                margin-right: 13px;
            }
    
            .select-end-list {
                margin-left: 13px;
            }
        }
    }

    .duration-block-title {
        color: #333333;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
        margin-bottom: 8px;
        margin-top: 24px;
    }

    .duration-select-wrapper {
        border: 1px solid #EBEBEB;
        background-color: #FFFFFF;
        box-shadow: 0 3px 5px 0 rgba(0,0,0,0.1);
        .dropdown .ant-select-selection {
            background-color: white;
        }

        .ant-select-selection-selected-value {
            color: #333333;
            font-family: Roboto;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 20px;
            margin: 8px 0px;
        }
    }

    .duration-select-at {
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 38px;
        display: flex;
        align-items: center;
        justify-content: center
    }

    .duration-row {
        margin-top: 24px;
        .duration-input-block {
            min-width: 120x;
            margin-right: 30px;
    
            color: #333333;
            font-family: Roboto;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 20px;
    
            &-label {
                margin-bottom: 4px;
            }
    
            &-input-wrapper {
                input {
                    height: 36px;
                    border-radius: 0px !important;
                }
                .ant-input-group-addon {
                    background-color: unset;
                    border: none;
                }
            }
        }
    }

    .judging-session-wrapper, .practice-session-wrapper, .official-session-wrapper, .scoring-session-wrapper {
        position: relative;
    }

    .opacity-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255,255,255, 0.6);
    }
}

@include md-max {
    .duration-select-at {
        justify-content: flex-start !important;
    }
}