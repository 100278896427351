@import '../../../../shared/components/Paragraph/paragraph.scss';
@import '../../../../shared/styles/_breakpoints.scss';
@import '../../../../shared/styles/_color.scss';
@import '../../../../shared/components/Link/Link.scss';
@import '../../../../shared/components/Heading/heading.scss';

.event-schedule-header {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @include md {
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }

  &__right {
    display: flex;
    width: 100%;
    @include xs {
      width: 75%;
    }
    @include md {
      align-items: center;
      width: 75%;
      flex-direction: row;
    }
    .ant-form-item {
      margin-bottom: 0;
    }
    .btn-primary {
      width: 100%;
    }

    .btn-secondary {
      width: 100%;
    }

    .ant-form {
      width: 100%;
    }
  }

  &__dropdown {
    width: 100%;
    background: red !important;
    margin-right: 14px;
    min-width: 400px;
    @include md {
      width: 409px;
      
    }
  }
  .ant-select-disabled .ant-select-selection {
    cursor: initial;
  }
}