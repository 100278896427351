@import '../../../shared/styles/_breakpoints.scss';
@import '../../../shared/styles/_color.scss';

.edit-team-modal {
  .ant-modal-body {
    padding: 30px;
    @include xs {
      padding: 60px;
    }
  }
  @include xs {
    .custom-form {
      margin-left: 0 !important;
    }
  }
}

.edit-team-modal {
  .ant-modal-body {
    padding: 30px;
    @include xs {
      padding: 60px;
    }
  }
  @include xs {
    .ant-form {
      margin-left: 100px;
    }
  }
}

.edit-team-container {
  display: flex;
  flex-direction: column;

  .edit-save-button {
    margin-top: 48px;
    width: 700px;
    display: flex;

    @include xs {
      margin-left: 100px;
    }
  }

  .ant-btn-primary {
    padding: 13px 61px;
    border-radius: 4px;
    height: unset;
    background-color: #0066B3;
  }
}


.edit-team {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;

  &__heading {
    color: #333333;
    font-family: Roboto;
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 38px;
    margin-bottom: 40px;
  }


  &__dropdown {
    border-radius: 0px !important;
    // width: 180px;
    height: 48px !important;
    border: 1px solid #EBEBEB;
    background-color: $white;
    box-shadow: 0 3px 5px 0 rgba(0,0,0,0.1);
    .ant-select-selection {
      height: 48px !important;
      border-radius: 0px;
      background-color: $white;
      border-color: transparent;
      
    }
    .ant-select-selection__rendered {
      line-height: 48px !important;
  
    }
    .ant-select-lg {
      height: 48px !important;
      .ant-select-selection__rendered {
        line-height: 48px !important;
      }
    }
    
    .ant-select-lg .ant-select-selection__rendered {
      line-height: 48px !important;
  }

  .ant-select-selection-selected-value {
    font-size: 16px;
  }

  .ant-select-dropdown-menu-item {
    font-size: 16px !important;
  }
  
    .ant-select-selection__placeholder {
      font-size: 16px;
      letter-spacing: 0;
      line-height: 22px;
      color: $charcoal-gray;
    }
  }

}