@import '../../../../shared/styles/main.scss';

.c-fll-rubric-section {
  margin-bottom: 64px;

  &__header-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
    margin-top: 18px;
    
    .rubric-toggle-button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 17px;
      background-color: #DDDCDD;
    }
  }

  &__header {
    display: flex;
    flex: 1;
    justify-content: space-between;
    background-color: $endeavor-blue;
    min-height: 48px;
    align-items: center;
    padding: 13px 15px 13px 20px;
    color: $white;
  }
}

.fll_e_header{
  background-color: #00b85a;
  color: #fff !important;
  padding-top: 10px;
  padding-bottom: 10px;
}