.c-publish-status {
  &--published {
    position: relative;
    height: 48px;
    background-color: #52C41A;
    padding: 13px 47px;
    border-radius: 24px;
    svg {
      position: absolute;
      top: 12px;
      left: 14px;
    }
  }

  &--unpublished {
    cursor: pointer;
  }

  &--pending {
    position: relative;
    height: 32px;
    background-color: #FFFBE6;
    padding: 5px 17px 5px 26px;
    border-radius: 16px;
    margin-right: 24px;
    &:before {
      position: absolute;
      content: '';
      top: 11px;
      left: 10px;
      width: 10px;
      height: 10px;
      background-color: #FAAD14;
      border-radius: 50%;
    }
  }
}
