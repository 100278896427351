@import '../../../../shared/styles/main.scss';

.c-rubric-upload {
  margin-top: 36px;
  &__delete-btn {
    position: absolute;
    top: 8px;
    right: 16px;
    // padding: 0 !important;
    height: unset;
  }
  &__loading {
    text-align: center;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    min-height: 48px;
    align-items: center;  
    padding: 13px 15px 13px 20px;
    color: $white;
    background-color: $endeavor-blue;
  }
  &__content {
    padding: 40px;
    border: 1px solid #C6C4C6;
    border-top: transparent;
  }

  &__step {
    display: flex;
    flex-direction: column;
    
    @include md {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  &__download {
    box-shadow: 0 3px 5px 0 rgba(0,0,0,0.1);
    width: 100%;
    max-width: 410px;
    padding: 24px;
    border-color: transparent;
    background: transparent;
    border-radius: 0 !important;
    cursor: pointer;
    border: none;
    outline:none;
    display: flex;
    align-items: center;
    @include md {
      padding: 24px 60px 24px 24px;
    }
    &:hover {
      box-shadow: 0 5px 8px 0 rgba(0,0,0,0.1);

    }
    &-icons {
      display: flex;
      align-items: center;
      padding-right: 24px;
    }
    &-text {
      text-align: left;
    }
  }

  &__upload {
    border: 2px dashed rgba(0,0,0,0.15);
    background-color: rgba(0,0,0,0.02);
    width: 100%;
    max-width: 410px;
    padding: 24px;
    display: flex;
    align-items: center;

    @include md {
      padding: 24px 60px 24px 24px;
    }
  }


  &__judging-comments {
    &__container {
      margin-top: 24px;
      background-color: $sand-gray;
      min-height: 100px;
      display: flex;
      padding: 20px;
      margin-bottom: 36px;
      flex-direction: column;
    }
    &__header {
      width: 100%;
      margin-bottom: 24px;
    }
    &__title {
      display: flex;
      flex-direction: column;
      @include sm {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
      }
    }
    &__add {
      color: $endeavor-blue;
      font-size: 1.6rem;
      display: flex;
      align-items: center;
    }
    
    &__notes {
      list-style: none;
      padding: 0 !important;
    }

    &__block {
      margin-bottom: 12px;
    }
  }
}