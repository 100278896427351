@import '../../../shared/styles/_breakpoints.scss';
@import '../../../shared/components/Paragraph/paragraph.scss';

$challenge-red: #ED1C24;
$explore-green: #01B75A;
$discover-purple: #7131A2;
$ftc-orange: #F57E25;

.event-tile {
  height: 100px;
  border: 1px solid #EBEBEB;
  background-color: #FFFFFF;
  box-shadow: 0 3px 5px 0 rgba(0,0,0,0.1);
  position: relative;

  &::before {
    content: '';
    position: absolute;
    background-color: red;
    width: 4px;
    height: 100%;
    bottom: 0;
    left: 0;
  }

  &--red {
    &::before {
      background-color: $challenge-red;
    }
  }
  
  &--purple {
    &::before {
      background-color: $discover-purple;
    }
  }

  &--green {
    &::before {
      background-color: $explore-green;
    }
  }

  &--orange {
    &::before {
      background-color: $ftc-orange;
    }
  }
  
  .test-event-ribbon-wrapper {
    width: 85px;
    height: 88px;
    overflow: hidden;
    position: absolute;
    top: -3px;
    left: -3px;
  
    .ribbon {
      text-align: center;
      text-shadow: rgba(255, 255, 255, 0.5) 0px 1px 0px;
      transform: rotate(-45deg);
      position: relative;
      padding: 2px 0;
      padding-right: 22px;
      left: -29px;
      top: 0px;
      width: 120px;
      color: #fff;
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
      text-transform: uppercase;
  
      &::after {
        content: "";
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        position: absolute;
        bottom: -3px;
        left: 0px;
      }
  
      &::before {
        right: 0;
      }

      &--red {
        background-color: $challenge-red;
      }
      
      &--purple {
        background-color: $discover-purple;
      }
    
      &--green {
        background-color: $explore-green;
      }
    
      &--orange {
        background-color: $ftc-orange;
      }
    }
  }

  &-inner {
    height: 100px;
    flex-direction: column;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-right: 15px;
    overflow: hidden;
    @include md {
      flex-direction: row; 
    }
  }

  &__link {
    display: block;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
    &:focus {
      box-shadow: 0px 2px 10px 8px rgba(0,0,0,0.1) !important;
    }
  }

  &__playmarker {
    width: 116px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__logo {
    height: 60px;
    width: 70.98px;
  }

  &__col {
    display: flex;
    align-items: center;
  }

  &__col-left {
    display: flex;
    @include md {
      width: 70%;
    }
  }

  &__location {
    padding-top: 13px;
    padding-bottom: 12px;
  }

  &__month-year {
    width: 100px;
  }

  &__date {
    background-color: #F4F4F4;
    padding-left: 18px;  
    @include md {
      margin-right: 22px;
      display: flex;
      align-items: center;
    }
    font-family: Roboto;
    color: #333333;
    letter-spacing: 0;

    &__num {
      height: 38px;
      font-size: 30px;
      font-weight: bold;
      line-height: 38px;
      text-align: right;
      margin-right: 7px;
      width: 40px;
    }
    &__day {
      height: 20px;
      font-size: 12px;
      font-weight: bold;
      line-height: 20px;
    }
    &__month_year {
      height: 20px;
      font-size: 12px;
      line-height: 20px;
    }
  }

  &__event-name {
    height: 22px;
    color: #333333;
    font-family: Roboto;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
    width: 475px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__region-name {
    height: 20px;
    color: #333333;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
  }

  &__coach-head-referee {
    height: 20px;
    color: #333333;
    font-family: Roboto;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 20px;
  }

  &__location-separator {
    height: 1px;
    width: 475px;
    background-color: #DDDCDD;
    margin: 3px 0;
  }

  &__delete-icon {
    position: absolute;
    top: -12px;
    right: -12px;
    @include md {
      display: none;
    }
  }

  &:hover, &:focus {
    box-shadow: 0 3px 23px 0 rgba(0,0,0,0.36);
    .event-tile__delete-icon {
      display: block;
    }
  }
}
