@import '../../../shared/styles/main.scss';

.change-file {
  display: flex;
  margin-top: 20px;
  &__upload {
    margin-left: auto;
  }
  &__btn {
    color: #0066B3;
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;
    min-width: 180px;
  }

}