@import '../../../../shared/styles/main.scss';

.event-scoring-evidence {
  padding: 48px 0 36px 0;
  background-color: $sand-gray;

  // @media (min-height: 800px) {
  //   position: -webkit-sticky;
  //   position: sticky;
  //   top: 0;
  //   z-index: 1000;
  // }
  @include md-max {
    .c-carousel__slider, .c-carousel__meta {
      padding: 0px 40px;

      .c-carousel__slide-content {
        height: unset;
      }

      .swiper-button-prev {
        left: 0px;
        &:after {
          font-size: calc(var(--swiper-navigation-size) / 3 * 2);
        }
      }

      .swiper-button-next {
        right: 0px;
        &:after {
          font-size: calc(var(--swiper-navigation-size) / 3 * 2);
        }
      }

      .swiper-slide {
        width: 100% !important;
      }
    }
  }
}