@import '../../../shared/styles/main.scss';

.event-overview-session-card {
  
  background-color: #FFFFFF;
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 140px;
  margin-top: 16px;

  &:last-child {
    margin-bottom: 64px;
  }
  &__icon {
    color: $white;
    height: 36px;
    width: 36px;
  }
  &__rectangle {
    min-width: 56px;
    // margin-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &--gray {
      background-color: $session-gray;

    }

    &--navy-blue {
      background-color: $session-navy-blue;
    }

    &--dark-blue {
      background-color: $session-dark-blue;
    }
    &--blue {
      background-color: $endeavor-blue;
    }


    &--turquoise {
      background-color: $session-turquoise;
    }
  }

  &__content {
    border-top: 1px solid #DDDDDD;
    border-bottom: 1px solid #DDDDDD;
    border-right: 1px solid #DDDDDD;
    display: flex;
    // align-items: center;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 22px;
    padding-right: 20px;
    padding-left: 20px;
    &-title {
      display: flex;
      flex-direction: row;
      margin-top: 20px;
      justify-content: space-between;
      align-items: flex-start;
    }

    &-info {
      padding-top: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      @include xs-max {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  .status-with-extra-buttons {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    padding-right: 8px;

    .status-badge-container {

      .status-badge {
        margin-left: 8px;
      }

      flex-direction: row-reverse;
      margin-bottom: 11px;
    }
  }
}