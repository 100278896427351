$alto-gray: #D9D9D9;
$black: #000000;
$carnation: #F77462;
$endeavor-blue: #0066B3;
$gallery-gray: #ECECEC;
$lime-green: #52C41A;
$nobel-gray: #B7B7B7;
$silver: #BBBBBB;
$red-primary: #ED1C24;
$dark-red: #C50E14;
$sand-gray: #F4F4F4;
$sun-orange: #FAAD14;
$white: #FFFFFF;
$charcoal-gray: #333333;
$endeavor-blue-disabled: #B2D1E9;
$aquamarine: #1890FF;
$bg-color: radial-gradient(circle, #00648F 0%, #003C66 100%);
$cobalt-blue: #4085AF;
$light-yellow: #FFFBE6;
$cornflower-blue: #0F6F8E;

$session-gray: #9A989A;
$session-dark-blue: #0F6F8E;
$session-turquoise: #03D2E2;
$session-navy-blue: #0D193F;


