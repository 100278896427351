@import '../../../../shared/styles/main.scss';

.event-session-card {
  display: flex;
  flex-direction: row;
  min-height: 120px;
  // position: relative;

  &-btn {
    min-height: 120px !important;
    width: 100% !important;
    text-align: left !important;
    border-radius: 0 !important;
    padding: 0 !important;

    &[disabled]{
      cursor: default;
    }
    &:disabled{
      cursor: default;
    }
  }

  &--complete {
   
    background-color: #FFFFFF;
    box-shadow: 0 3px 5px 0 rgba(0,0,0,0.1);
  }

  &:not(:first-child) {
    margin-top: 10px;
  }
  @include md {
    &:not(:first-child) {
      margin-left: 10px;
      margin-top: 0;
    }
    
  }
  &__alert-icon {
    color: #9A989A;
    height: 36px;
    width: 36px;
  }

  &__event-icon {
    color: #FFFFFF;
    width: 35px;
    height: 39px;
  }
  &__rectangle {
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;

    &--incomplete {
      background-color: #DDDCDD;
    }

    &--complete {
      background-color: #0D193F;
    }

    &--judging {
      background-color: $session-navy-blue;
    }

    &--scoring {
      background-color: $session-turquoise;
    }

    &--meeting {
      background-color: $session-dark-blue;
    }
  }

  &__data {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 16px;
    padding-right: 16px;

    &__title {
      max-width: 180px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    &--incomplete {
      border: 2px #DDDCDD dashed;
      border-left: 0;
    }
    &--complete {
       border: 1px solid #EBEBEB;
       border-left: 0;
    }


    &__date-time {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
    }

  }
  &__join-btn {
    // position: absolute;
    // bottom: 10px;
    // right: 0;
    display: flex;
  }

}