.choose-file {
  .ant-btn-link {
    padding: 0;
  }
  &__text {
    font-size: 1.6rem;
    color: #0066B3;
    text-decoration: underline;
    p {
      margin: 0;
    }
  }
}