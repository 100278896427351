@import '../../../shared/styles/main.scss';
.registration {

  &__stepper-header {
    margin: 8px auto;
    max-width: 600px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    &__back {
      cursor: pointer;
    }
  }

  &__setup-btn {
    color: $charcoal-gray;
    border-radius: 4px;
    padding: 14px 0;
    border: 1px solid #C1C1C1;
    text-align: center;
    background: $white;
    min-width: 300px;
    @include xs {
      min-width: 350px;
    }
    &:disabled {
      cursor: not-allowed;
    }
    &:hover {
      background: $endeavor-blue !important;
      border-color: transparent;
      color: $white !important;
    }
  }

  &__logo {
    box-shadow: 0 2px 50px 0 rgba(255,255,255,0.55);
  }
  .ant-form-item-label {
    margin-bottom: 8px;
  }

  .ant-form-item:not(:first-child) {
    margin-top: 21px;
  }

  &__signin-form {
    width: 100%;
    padding: 34px 0 24px;
    position: relative;

    .login-loading-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(255,255,255,0.6);
    }
  }

  &__signup-form {
    width: 100%;
    padding: 14px 0 70px;
  }

  &__new-account {
    width: 100%;
    padding: 14px 0 36px;
    text-align: center;
    &-email {
      text-align: center;
    }
    &-form {
      padding: 25px 20px 16px;
      background-color: #F4F4F4;
      text-align: left;
      .input-password--large {
        margin-bottom: 18px !important;
      }
      @include xs {
        padding: 25px 75px 16px;
      }
    }
    &-btn-wrapper {
      text-align: center;
    }
    &-btn {
      border-radius: 4px;
      padding: 12px 42px;
      height: unset;
      // button {
      //   border-radius: 4px;
      //   padding: 12px 42px;
      //   height: unset;
      //   // width: 300px;
      // }
    
    }
  }

  &__account-signin {
    width: 100%;
    &-form {
      padding: 24px 25px;
      background-color: #F4F4F4;
    }
  }

  &__forgot-pass {
    width: 100%;
    padding: 34px 0 36px;
    &-form {
      padding: 24px 25px;
      background-color: #F4F4F4;
    }
  }

  &__reset-pass {
    width: 100%;
    padding: 14px 0 50px;
    &-form {
      padding: 24px 25px;
      background-color: #F4F4F4;
    }
  }

  &__goback {
    margin-bottom: 6px;
    margin-left: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    > svg {
      width: 8px;
      margin-right: 5px;
    }
    @include xs {
      margin-left: -13px;
    }
  }

  &__submit {
    margin-top: 36px;
    width: 100%;
    text-align: center;
    button {
      width: 300px;
    }
  }

  &__forget {
    margin-top: 24px;
    width: 100%;
    text-align: center;
  }

  &__divider {
    width: 100%; 
    text-align: center; 
    border-bottom: 1px solid #a6a4a4; 
    line-height: 0.1em;
    margin: 10px 0 20px; 

    &--inner {
      background:#EEEFF0;;
      padding:0 10px; 
    }
  }
  &__page-header {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F5F5F5;
    height: 60px;
  }
  &__btn {
    display: flex;
    justify-content: flex-end;
    margin-top: 36px;
  }
  &__header {
    margin-bottom: 14px;
    word-break: break-word;
  }
  &__form {
    max-width: 400px;
    margin: auto;
  }
  &__input {
    background-color: white;
    padding: 35px 35px 56px 35px;

  }
}