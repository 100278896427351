.c-event-sponsors {
  &--hide {
    display: none;
  }

  &--management {
    max-width: 940px;
    margin: 0 auto 100px;
  }

  &__order-text {
    display: flex;
  }

  &__preview-link {
    cursor: pointer;
    margin-left: 4px;
  }

  &__instructions {
    background-color: #F4F4F4;
    padding: 20px 25px;
    margin: 36px 0;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0 0 0;
  }

  &__add-content {
    padding: 0 160px;
    > div:first-child {
      display: flex;
      justify-content: space-between;
      margin-bottom: 94px;
    }
    > div:nth-child(2) {
      display: flex;
      justify-content: flex-end;
      button {
        width: 140px;
      }
    }
  }

  &__add-left {
    width: 350px;
  }

  &__add-right {
    width: 220px;
    .file-card {
      border: 1px solid #EBEBEB;
      box-shadow: 0 3px 5px 0 rgba(0,0,0,0.1);
    }
    .choose-file-card {
      width: 220px;
      svg {
        color: #0066B3;
      }
    }
  }

  &__delete {
    margin: 12px 0 20px;
    text-align: right;
    button {
      padding: 0;
    }
  }

  &__card {
    margin-bottom: 24px;

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 48px;
      padding: 0 16px 0 20px;
      background-color: #0066B3;
      > p:nth-child(2) {
        cursor: pointer;
        &:hover {
          color: #F4F4F4;
        }
      }
    }

    &-content {
      background-color: #F4F4F4;
      padding: 48px 33px 23px 60px;
    }
  }

  &__sponsor-name {
    height: 48px;
    background-color: white;
    border: 1px solid #C1C1C1;
    padding-left: 14px;
    display: flex;
    align-items: center;
  }

  &__sponsor-logo {
    display: flex;
  }

  &__image-instruction {
    max-width: 200px;
    margin-left: 24px;
  }

  &--preview {
    height: 100%;
  }

  &__thanks {
    padding: 74px;
    text-align: center;
  }

  &__edit-sponsors {
    display: flex;
    justify-content: flex-end;
    padding: 33px 0 29px;
  }
}
