@import '../../../shared/styles/main.scss';
.trademark{
  font-size: 89%;
  vertical-align: top;
  content: "+";
}

.sponsors-logos{
  margin-bottom: 30px;
}

.responsive-top {
  margin-top: 0;
}

.sponsors-logos-inline{
  display: flex;
}

.seperated-sponsors{
  display: flex;
}

.inline-block{
  display: inline-block;
}

.right-margin{
  border-right: 2px solid #c3c3c3
}

.text-center{
  text-align: center;
}

.sponsors-title{
  font-weight: 900;
  color: #000;
}

.c-event-sponsors-list {
  background-color: #F4F4F4;
  opacity: 0.9;
  padding-bottom: 50px;
  height: 100%;

  &__content {
    max-width: 940px;
    margin: 0 auto;
  }

  &__see-all {
    display: flex;
    justify-content: center;
  }

  &__global-sponsors {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 0 60px;
    > img {
      margin: 30px 40px 10px;
      &:nth-child(1), &:nth-child(2), &:nth-child(3) {
        margin: 10px 40px;
      }
    }
  }

  &__event-sponsors {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__sponsor-cards {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    > div {
      margin: 0 20px 20px 0;
    }
  }

  &__sponsor-logo {
    width: 220px;
    // height: 155px;
    background-color: white;
    border: 1px solid #EBEBEB;
    box-shadow: 0 3px 5px 0 rgba(0,0,0,0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    object-fit: contain;
  }

  &__no-logo {
    color: #0066B3;
    height: 60px;
    width: 60px;
  }

  @include md-max {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 40px;

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__global-sponsors {
      flex-direction: column;
    }

    &__sponsor-cards {
      width: unset;
    }

    &__sponsor-logo {
      height: unset;
    }
  }
}

@media screen and (max-width:768px) {
  .sponsors-logos-inline {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .seperated-sponsors {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .responsive-top{
    margin-top: 50px;
  }

  .sponsors-logos-inline img{
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    width: 90% !important;
    max-width: 270px;
    &.m-r-40 {
      margin-left: auto !important;
      margin-right: auto !important;
    }
    &.m-r-20 {
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }

  .right-margin {
    border: none;
  }
}

.sponsors-logos-inline img{
  width: 45%;
  object-fit: contain;
  &.m-r-40 {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  &.m-r-20 {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
