.match-scoresheet-modal {
  max-width: 963px;
  margin: auto;
  
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 76px;
    margin-top: 12px;
  }

  &__sections-wrapper {
    list-style: none;
    padding-left: 0;
  }
}