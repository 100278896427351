@import '../../../shared/styles/main.scss';

.file-card {
  min-height: 180px;
  width: 160px;
  background-color: $white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &--wide {
    width: 220px;
    min-height: 155px;
  }

  &--bggrey {
    background-color: #f4f4f4 !important;
  }

  &__icon {
    height: 60px;
    color: $endeavor-blue;
    width: 60px;
    margin: 24px auto 12px;

    &--document {
      height: 60px;
      width: 48px !important;
      margin: 24px auto;
    }
  }

  &__last-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: auto;
    padding: 0 10px 5px 10px;
  }

  &__data {
    display: flex;
    flex-direction: column;
    padding: 0 10px 10px;
    margin-top: auto;
    max-width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
    &--wide {
      max-width: 220px;
      flex-direction: row;
      padding: 0;
      // margin-top: -12px;
      justify-content: center;
    }
  }

  &__btn {
    padding: 0 !important;
    height: unset;
  }

  &__errortext{
    color: #C50E14;
    margin-top: 45px;
    padding: 0 15px;
    font-size: 14px;
  }
}
