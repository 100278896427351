@import '../../../../shared/styles/main.scss';

.c-carousel {

  &__loading {
    text-align: center;
  }
  &__slide-no-content {
    height: 450px;
    width: 100%;
    // background-color: $black;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2D2D2D;
  }
  &__slide-content {
    height: 450px;
    width: 100%;
    background-color: $black;
  }
  &__slider {
    padding: 0px 80px;
  }
  &__video {
    width: 100%;
    height: 100%;
  }

  &__image {
    max-height: 100%;
    max-width: 100%;
  }

  &__pdf {
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  &__slide {
    list-style: none;
  }
  
  &__icon {
    color: $endeavor-blue;
    font-size: 28px;
  }

  &__meta {
    padding: 0 80px;
    margin: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .swiper-wrapper {
    padding: 0;
  }


  .swiper-slide {
    opacity: 0;
    list-style: none;
    text-align: center;
    &-active {
      opacity: 1 ;
    }
  }

}