@import '../../../shared/styles/_breakpoints.scss';
@import '../../../shared/styles/_color.scss';

.hours-switch-container{
  align-items: center;
}

.enable-switch{
  color: #0066B3;
}

.header {
  background-color: #F4F4F4;
  min-height: 60px;
  text-align: center;
  padding: 0 0;

  &__local-field {
    &:focus {
      border: 1px solid $charcoal-gray;

    }
  }

  &__menu-link {
    color: $charcoal-gray !important;
    font-size: 1.4rem;
  }
  &__menu-item {
    color: $charcoal-gray !important;
    font-size: 1.4rem;
    padding: 0 !important;
  }
  &__profile-btn {
    border-color: transparent;
    background: transparent;
    text-align: left !important;
    border-radius: 0 !important;
    padding: 5px 12px;
    cursor: pointer;
    border: none;
    outline:none;
    width: 100%;
  }
  &__wrapper {
    max-width: 1100px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    margin: auto;
  }

  &__logo {
    height: $eventhub_logo_height;
    margin-bottom: 12px;
  }

  &__logo-link {
    align-self: flex-start;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    &:hover, &:active, &:focus {
      text-decoration: none;
      outline: none;
    }
  }

  &__content {
    align-items: center;
    display: flex;
    margin-left: auto;
    justify-content: space-between;
    flex-wrap: wrap;
    min-width: 40%;
    margin: 0;
    
    .form .ant-form-item {
      margin-bottom: 24px !important;
    }
  }

  .unauthorized-class {
    justify-content: flex-end;

    .header__link {
      margin-right: 30px;
      margin-top: 8px;
    }
  }

  &__local {
    min-width: 15rem;
    &:focus {
      border: 1px solid $charcoal-gray;

    }
  }

  &--right {
    display: flex;
  }

  &__avatar {
    cursor: pointer;
  }

  &__link {
    color: $charcoal-gray;
    font-size: 1.6rem;
    &:hover, &:active, &:focus {
      color: $charcoal-gray;
      text-decoration: underline;
    }
  }

  .mobile-only-menu-button {
    display: none;
  }

  @include md-max {
    .mobile-only-menu-button {
      display: inline;
    }

    .mobile-only-menu-content {
      display: flex;
      width: 260px;
      z-index: 100;
      flex-direction: column;
      align-items: flex-start;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #F4F4F4;
      box-shadow: 0 3px 23px 0 rgba(0,0,0,0.36);

      .rtl & {
        right: 0;
        left: unset;
      }

      .icon-close {
        padding: 12px;
        background-color: white;
        margin-bottom: 34px;
        margin-left: 0px;
        margin-right: 0px;
      }

      .header__link {
        margin-bottom: 18px;
        margin-left: 34px;
        color: #333333;
        font-family: Roboto;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 22px;

        .rtl & {
          margin-left: unset;
          margin-right: 34px;
        }
      }

      .last {
        margin-bottom: 48px;
      }

      .ant-badge {
        margin-right: 6px;
        .rtl & {
          margin-right: unset;
          margin-left: 6px;
        }
      }
    }

    .visible {
      display: flex;
    }

    .invisible {
      display: none;
    }

    &__wrapper {
      padding: 13px;
      justify-content: flex-start;
    }

    &__logo {
      height: $eventhub_header_logo_height_mobile;
      margin-bottom: 0;
    }

    &__logo-link {
      margin-left: 35px;
    }

    &__content {
      display: none;
    }
  }
}

.public-leaderboard {
  display: none;
  padding-bottom: 60px;
  .leaderboard-title {
    top: 160px;
  }
  @include xs {
    .leaderboard-title {
      top: 110px;
    }
  }
  
  
  @include md {
    .leaderboard-title {
      top: 140px;
    }
  }
  
  @include lg {
    padding-bottom: 0px;

    .leaderboard-title {
      top: unset;
    }
  }
}