@import '../../../shared/styles/main.scss';

.custom-switch-session.ant-switch{
  background-color: #1890ff;
}

.referee-sessions-table .referees-status-wrapper.expanded .status-badge-container p{
  // white-space: nowrap; 
  // width: 100px; 
  // overflow: hidden;
  // text-overflow: ellipsis; 
}

.status-badge {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  z-index: 1000;
  
  &-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }


  &--hollow-green {
    border: 2px solid #52C41A;
  }

  &--solid-green {
    background-color: #52C41A;;
  }

  &--solid-orange {
    background-color: #FAAD14;
  }

  &--solid-blue {
    background-color: $endeavor-blue;
  }

  &--solid-red {
    background-color: #C50E14;
  }
}