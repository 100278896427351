@mixin padding($top, $right, $bottom, $left) {
  padding-top: $top !important;
  padding-right: $right !important;
  padding-bottom: $bottom !important;
  padding-left: $left !important;
}

@mixin margin($top, $right, $bottom, $left) {
  margin-top: $top !important;
  margin-right: $right !important;
  margin-bottom: $bottom !important;
  margin-left: $left !important;
}

$spaceamounts: (4, 5, 6, 8, 10, 12, 14, 16, 18, 20, 24, 30, 36, 38, 40, 45, 48, 60, 70);
$sides: (top, bottom, left, right); 

@each $space in $spaceamounts {
  @each $side in $sides {
    .m-#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }
  
    .p-#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}