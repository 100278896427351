@import '../../../shared/styles/_breakpoints.scss';
@import '../../../shared/styles/_color.scss';

.teams-coaches-modal__manual-invite-list-container{
  margin-top: 0 !important;
}

.custom-disable-form .ant-select-disabled .ant-select-selection{
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.custom-disable-form .ant-select-disabled .ant-select-selection:hover{
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.custom-disable-form .form-dropdown .ant-select-selection{
  box-shadow: initial;
  border: 0;
}

.button-group-team{
  display: flex;
  align-items: center;  
}

.create-team-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include md {
    flex-direction: row;
    justify-content: flex-end;
    align-items: end;
  }
}

.create-team-container-col {
      padding-right: 10px;
    padding-top: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include md {
       flex-direction: column;
    align-items: flex-start;
  }
}

.width-100{
  width: 100% !important;
}


.create-team {
  width: 100%;
  padding: 20px;
  
  display: flex;
  flex-direction: column;
  
  @include md {
    margin-right:40px;
    width: 300px;
  }

  &--complete {
    background-color: #E6F7FF;
    border: 1px solid #1890FF;
  }
  &--incomplete {
    background-color: #E6F7FF;
    border: 1px solid #1890FF;
  }
  &__heading {
    margin-bottom: 14px;
  }


  &__dropdown {
    border-radius: 0px !important;
    // width: 180px;
    height: 48px !important;
    border: 1px solid #EBEBEB;
    background-color: $white;
    box-shadow: 0 3px 5px 0 rgba(0,0,0,0.1);
    .ant-select-selection {
      height: 48px !important;
      border-radius: 0px;
      background-color: $white;
      border-color: transparent;
      
    }
    .ant-select-selection__rendered {
      line-height: 48px !important;
  
    }
    .ant-select-lg {
      height: 48px !important;
      .ant-select-selection__rendered {
        line-height: 48px !important;
      }
    }
    
    .ant-select-lg .ant-select-selection__rendered {
      line-height: 48px !important;
  }

  .ant-select-selection-selected-value {
    font-size: 16px;
  }

  .ant-select-dropdown-menu-item {
    font-size: 16px !important;
  }
  
    .ant-select-selection__placeholder {
      font-size: 16px;
      letter-spacing: 0;
      line-height: 22px;
      color: $charcoal-gray;
    }
  }

}