.add-partner-organization {
  margin-top: 48px;
  padding: 20px 20px 19px 20px;
  background-color: #F4F4F4;

  > div {
    width: 100%;
  }

  .ant-form-item-label {
    margin-bottom: 8px;
    margin-right: 100px;
  }

  &__checkboxs {
    .ant-checkbox + span {
      padding-right: 0;
    }
    .ant-checkbox-wrapper {
      margin-left: 0;
      margin-bottom: 8px;
    }
  }
}
