@import '../../../shared/styles/main.scss';

.modal {
  font-family: Roboto;

  &__close-btn {
    color: $white;
    &:focus {
      outline: 1px solid $white;
    }

  }

  .ant-btn {
    font-size: 16px !important;
    height: 40px !important;
  }

  .anticon, .anticon-close, .ant-modal-close-icon {
    color: $white;
    &:focus {
      outline: 1px solid $white;
    }
  }
  .ant-modal-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .ant-modal-close {

    &:focus {
      outline: 1px solid $white;
    }
  }
  .ant-modal-body {
    // padding: 20px !important;
    @include lg {
       padding: 36px 110px 60px 110px !important;
    }
  }

  .ant-modal-content {
    height: auto;
    margin: 0;
    padding: 0;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    box-shadow: 0 2px 8px 0 rgba(0,0,0,0.15);
    .ant-modal-close {
      margin: 2px 26px;
    }
  }

  .ant-modal-header {
    border-bottom: none;
    min-height: 90px;
    color: white;
    border-radius: 0 !important;
    background: radial-gradient(circle, #00648F 0%, #003C66 100%);
    display: flex;
    position: relative;
    align-items: center;
    // padding-left: 32px;
    padding: 0 100px 0 24px;
  }

  .ant-tabs {
    max-width: 780px;
    margin: auto;
  }

  &--full {
    .ant-tabs {
      width: 100%;
      max-width: 100%;
    }
  }

  &__title-left {
    color: white;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    sup {
      top: -0.5rem;
      font-size: 1rem;
    }
  }

  // &__title-right {
  //   // position: absolute;
  //   // top: 0;
  //   // right: 108px;
  // }

  &__title {
    max-width: 940px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  &__link {
    display: flex;
    align-items: center;
    &__icon {
      margin-right: 12px;
    }
  }
  
  @include md-max {
    .ant-modal-title {
      flex-direction: column;
    }

    &__title-left {
      .event-modal-header {
        flex-direction: column;
      }
    }

    &__title-right {
      .c-event-program-logos {
        justify-content: flex-end;
      }
    }
  }
}

.ant-modal .ant-modal-content {
  padding-bottom: 15px;
}

.ant-modal .ant-modal-close {
  color: rgb(56, 56, 56) !important;
  font-weight: bolder;
}

.edit-event-info-modal {
  .ant-modal-body {
    padding: 36px 0 60px 0 !important;
  }

  .c-container--small {
    padding: 0px 20px !important;
    max-width: unset;
    @include sm {
      padding: 0px 230px !important;
    }
  }
}