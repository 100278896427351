@import '../../../shared/styles/main.scss';

.header-flex-end {
  display: flex;
  justify-content: flex-end;

  @include md-max {
    justify-content: flex-start;
  }
}

.switch-with-time-container {
  text-align: right;
  width: 100%;
}

.switch-with-time {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.event-page-header {
  min-height: 110px;
  background-image: url('../../../../public/backgrounds/first-season.png');
  background-position: top;
  background-size: cover;
  

  &--bgcolor {
    background: $bg-color;
  }

  .left-shape {
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    background-position-x: right;
  }

  .right-shape {
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: left;
    position: absolute;
  }

  &__title {
    max-width: 940px;
    // padding: 20px 145px 0 0;
    padding: 0;
    margin: 0 auto 10px;
    position: relative;
    display: flex;
    justify-content: flex-end;

    &-text {
      padding-top: 20px;
      padding-bottom: 2px;
      flex-grow: 1;
    }
  }

  &__subtitle {
    max-width: 940px;
    margin: 0 auto;
    padding-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-info {
      display: flex;
      align-items: center;
      flex-direction: row;
    }
  }

  @include md-max {
    min-height: 90px;
    padding: 0px 0px 0px 15px;

    &__title {
      margin-bottom: 10px;
    }

    &__title-text {
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 20px;
    }

    &__subtitle {
      flex-direction: column;
      align-items: flex-start;

      .h-4,
      .paragraph--large {
        font-size: 12px !important;
        letter-spacing: 0;
        line-height: 20px;
      }
    }
  }
}

@include md-max {
  .ant-popover-inner-content {
    width: 320px;

    li p {
      width: 280px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}