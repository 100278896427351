@import '../../../../shared/styles/_breakpoints.scss';
@import '../../../../shared/components/Heading/heading.scss';

.event-schedule-ceremonies {
  margin-top: 64px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @include md {
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }

  &__title {
    max-width: 245px;
    font-weight: 400;
  }

  &__cards {
    display: flex;
    flex-direction: column;
    padding: 10px 11px;
    max-width: 322px;
    background-color: #F4F4F4;
    @include md {
      flex-direction: row;
      max-width: 640px;
    }

  }
}