.volunteer-tile {
  &__roles {
    height: 64px;
    width: 150px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    .ant-btn {
      padding: 0;
    }
  }
}
