@import '../../../../shared/styles/main.scss';

.coach-matches-scoring {
  &-title {
    margin-top: 24px;
    color: #333333;
    font-family: Roboto;
    font-size: 38px;
    letter-spacing: 0;
    line-height: 46px;
    @include md-max {
      margin-top: 18px;
      font-size: 24px;
      line-height: 32px;
    }
  }

  &-upload-instruction {
    margin-top: 24px;
    color: #333333;
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    @include md-max {
      margin-top: 4px;
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.match-evidence-row {
  width: 935px;
  min-height: 114px;
  background-color: #FFFFFF;
  margin-bottom: 35px;
  @include md-max {
    width: unset;
    margin-bottom: 24px;
  }

  border: 1px solid #DDDDDD;
  &.practice-match {
    border-left:4px solid #9A989A;
  }
  &.official-match {
    border-left:4px solid #ED1C24;
  }

  .title-row {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 930px;
    font-family: Roboto;
    color: #333333;
    letter-spacing: 0;

    background-color: #F4F4F4;

    .saperator {
      display: flex;
      width: 2px;
      background-color: #FFFFFF;
    }

    .match-type {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 315px;
      font-size: 20px;
      font-weight: bold;
      line-height: 28px;
      padding: 7px 25px;
      .mobile-button-header {
        display: none;
      }
    }

    .button-header {
      display: block;
    }

    .mobile-button-header {
      display: none;
    }

    .due {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 220px;
      text-align: center;
      font-size: 12px;
      line-height: 20px;
      padding: 5px;

      .due-title {
        font-weight: bold;
        margin-right: 4px;
      }
    }

    .status {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 140px;
      text-align: center;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      padding: 5px;

      .status-title {
        font-weight: bold;
      }

      .passed-deadline {
        color: #D93024;
      }
    }

    .button-title {
      width: 245px;
      padding: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      line-height: 22px;
      
      .score-value {
        font-weight: bold;
      }
    }

    @include md-max {
      flex-direction: column;
      align-items: flex-start;
      width: unset;
      background-color: white;

      .button-header {
        display: none;
      }

      .mobile-button-header {
        display: block;
      }

      .match-type, .due, .status, .button-title {
        justify-content: space-between;
        padding-left: 26px;
        width: unset;
      }

      &.passed-deadline {
        background-color: #FFFBE6;
      }
  
      &.scored {
        background-color: #EDF9E8;
      }
  
      &.match-file-error {
        background-color: #FFF1F0;
      }
      
      .match-type {
        justify-content: space-between;
        padding-left: 26px;
        width: 100%;
        background-color: #F4F4F4;
        .mobile-button-header {
          display: inline;
          font-size: 16px;
          font-weight: 200;
          letter-spacing: 0;
          line-height: 22px;
          .score-value {
            font-weight: bold;
          }
        }
      }
      .saperator {
        display: none; 
      }
    }
  }

  .content-row {
    min-height: 71px;
    color: #333333;
    font-family: Roboto;
    width: 930px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    &.passed-deadline {
      background-color: #FFFBE6;
    }

    &.scored {
      background-color: #EDF9E8;
    }

    &.match-file-error {
      background-color: #FFF1F0;
    }

    .content-file-name {
      width: 315px;
      display: flex;
      align-items: center;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 22px;
      padding: 0 25px;
      .inner_wrapper {
        &_error {
          color: #C50E14;
        }
        max-width: 265px;
        overflow:hidden; 
        white-space:nowrap; 
        text-overflow: ellipsis;
      }
    }

    .content-update-time {
      width: 220px;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      
      .upload_time_component {
        display: flex;
        align-items: center;

        .upload_time_text {
          margin-left: 7px;
          text-align: center;
        }
      }
    }
    
    .status-content {
      width: 140px;
    }

    .content-buttons {
      width: 245px;
      display: flex;
      align-items: center;
      justify-content: center;

      .inner_wrapper {
        &_error {
          color: #C50E14;
        }
        width: 205px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    @include md-max {
      flex-direction: column;
      align-items: flex-start;
      width: unset;
      background-color: white;

      &.scored {
        background-color: #EDF9E8;
      }
      .content-file-name {
        align-items: flex-start;
      }

      .content-buttons{
        .inner_wrapper {
          &_error {
            color: #C50E14;
          }
          margin-bottom: 26px;
          justify-content: flex-start;

          .ant-btn-link {
            padding-left: 5px !important;
          }
        }
      }

      .upload_time_component {
        display: none;
      }

      .content-update-time {
        padding-left: 26px;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 12px;
        
        .upload_time_component {
          display: flex;
          align-items: center;
  
          .upload_time_text {
            margin-left: 7px;
            text-align: center;
          }
        }
      }
    }
  }
}

@include md-max {
  .scoresheet-modal {
    .ant-modal {
      width: 100% !important;
      max-width: 100% !important;
      top: 0 !important;
      margin: 0px !important;
    }

    .anticon.modal__close-btn {
      color: #4B4B4B !important;
    }

    .ant-modal-header {
      display: none;
    }

    .match-scoresheet-modal .match-scoresheet-modal__header h2 {
      display: none;
    }

    .scoresheet-module__name {
      width: 72px;
      .h-2 {
        font-size: 16px;
        letter-spacing: 0;
        line-height: 22px;
      }
    }

    .scoresheet-module__content {
      margin-left: 88px !important;
      padding-right: 20px !important;
      margin-top: -30px !important;
      width: unset;
      .h-2 {
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 22px;
      }

      .c-radio-button-wrapper {
        justify-content: flex-start;
        .c-radio-button {
          margin-right: 12px;
          margin-left: 0px;
        }
      }
    }
  }
}