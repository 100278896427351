@import '../../../../shared/styles/main.scss';

.c-fll-rubric-form {

  &__sub-title {
    // display: flex;
    // flex-direction: column;
    margin-right: 44px;
    @include lg {
      // flex-direction: row;
      margin-right: 44px;
      // justify-content: space-between;
      // align-items: center;
    }
  }
  &__section {
    border: 1px solid #C6C4C6;
    background-color: $white;
    min-height: 100px;
    display: flex;
    margin-bottom: 24px;
    padding-bottom: 24px;
    flex-direction: column;
    @include md {
      flex-direction: row;
    }
    // &:hover {
    //   box-shadow: 0 0 0 1px rgba(21, 156, 228, 0.4);

    // }
    &:focus{
      outline: none;
      box-shadow: 0 0 0 0.5px rgba(21, 156, 228, 0.4);
    }

    &-name {
      background-color: #231F20;
      color: $white;
      width: 130px;
      text-align: center;
      align-self: start;
      padding: 6px 0px; 
      text-transform: uppercase;
      margin-right: 30px;
    }
    &-icon-wrapper {
      display: flex;
      justify-content: flex-end;
    }
    &-icon {
      color: $endeavor-blue;
      font-size: 2.5rem;
    }

    &-legend {
      border-bottom: none !important;
      color: $charcoal-gray !important;
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 22px;
      margin-bottom: 0 !important;
    }
    &-content-wrapper {
      width: 100%;
      .text-content-error {
        textarea {
          border: 1px solid red;
        }
      }
    }
    &-content {
      display: flex;
      flex-direction: column;
      @include lg {
        flex-direction: row;
        margin-right: 44px;
        justify-content: space-between;
        align-items: center;
      }
    }

    &-content-reason {
      margin-top: 12px;
      @include lg {
        margin-right: 44px;
      }
    }

    &-content-legend-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @include lg {
        flex-direction: row;
        margin-right: 44px;
        justify-content: space-between;
        align-items: center;
      }
    }

    &-content-fieldset {
      display: flex;
      flex-direction: column;
      &:not(:last-child) {
        border-bottom: solid 1px #DDDCDD;
        padding-bottom: 22px;

      }
    }

    &-content-reason-title {
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 20px;
    }

    &-content-reason-text {
      font-size: 16px;
      letter-spacing: 0;
      line-height: 22px;
    }
  }
  @include md-max {
    &__section-name {
      width: 72px;
      .h-2 {
        font-size: 16px;
        letter-spacing: 0;
        line-height: 22px;
      }
    }

    &__section-content-wrapper {
      margin-left: 88px !important;
      padding-right: 20px !important;
      margin-top: -30px !important;
      width: unset;
      .h-2 {
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 22px;
      }

      .c-radio-button-wrapper, .match-scoresheet-form__radio-wrapper {
        justify-content: flex-start;
        .c-radio-button, .ant-radio-button-wrapper {
          margin-right: 12px;
          margin-left: 0px;
        }
      }
    }
  }
}

@include md-max {
  .mobile-compatitable-rubric-title {
    font-size: 24px;
    letter-spacing: 0;
    line-height: 32px;
  }
}

.rubric-line-container {
  width: 100%;
}

.rubric-line-left {
  width: 10%;
  float: inline-start;
  margin-inline-start: -110px;
  padding-top: 20px;
}

.rubric-line-right {

}

.rubric-item-container {
  width: 95%;
  vertical-align: top;
  display: inline-block;
}

.rubric-item-left {
  width: 50%;
  float: inline-start;
  margin-top: 20px;
}

.rubric-item-right {
  margin-top: 30px;
}

.core-values-background {
  background-image: url('../../../../shared/components/Icon/pngs/gear-thin-CV-symbol-transparent.png') !important;
  background-size: 50px;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: 25%;
}