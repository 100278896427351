@import '../../../shared/styles/_breakpoints.scss';
@import '../../../shared/styles/_color.scss';

.form-dropdown-small {
  border-radius: 0px !important;
  // width: 180px;
  height: 48px !important;
  border: 1px solid #EBEBEB;
  background-color: $white;
  box-shadow: 0 3px 5px 0 rgba(0,0,0,0.1);

  .ant-select-selection {
    height: 48px !important;
    border-radius: 0px;
    background-color: $white;
    border-color: transparent;
    
  }
  .ant-select-selection__rendered {
    line-height: 48px !important;

  }
  .ant-select-lg {
    height: 48px !important;
    .ant-select-selection__rendered {
      line-height: 48px !important;
    }
  }
  
  .ant-select-lg .ant-select-selection__rendered {
    line-height: 48px !important;
}

.ant-select-selection-selected-value {
  font-size: 16px;
}

.ant-select-dropdown-menu-item {
  font-size: 16px !important;
}

  .ant-select-selection__placeholder {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    // color: $charcoal-gray;
  }

}


.ant-select-dropdown-menu-item {

  font-size: 1.6rem !important;

}