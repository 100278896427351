@import '../../../shared/styles/main.scss';

.add-user-form {
  margin-top: 26px;
  @include md {
    max-width: 300px;
    margin-right: auto;
  }


  &__buttons {
    .ant-form-item {
      margin-bottom: 0 !important;
    }
    display: flex;
    margin-top: 42px;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    @include xs {
      justify-content: space-between !important;
    }
  }
}
