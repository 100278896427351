@import '../../../../../shared/components/Paragraph/paragraph.scss';
@import '../../../../../shared/styles/_space.scss';
@import '../../../../../shared/styles/_breakpoints.scss';
@import '../../../../../shared/styles/_color.scss';

.c-session-url-action-wrapper{
  display: inline-flex;
}

.c-session-url-action {
  color: $endeavor-blue;
  font-size: 24px;
  background: none;
  border: none;
  &:focus, :hover {
    color: $cobalt-blue;
  }
}

.c-session-url-actions-container {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.c-session-url {
  &__helper-text {
    display: flex;
    align-items: center;
  }

  &__regenerate-header {
    display: flex;
    align-items: center;
  }
}

