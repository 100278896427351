@import '../../../../shared/components/Paragraph/paragraph.scss';
@import '../../../../shared/styles/_breakpoints.scss';
@import '../../../../shared/styles/_color.scss';

.event-schedule-accordion {

  margin-top: 20px;
  background-color: $sand-gray;

  &__icon {
    // background: red;
    // display: flex !important;
    // align-items: center;
    // justify-content: center;
    // height: 48px;
    // width: 48px;
    color: $black !important;
  
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // max-width: 700px;
  }

  &__panel {
    background-color: $white;
    border: 0 !important;
    border-radius: 0;
    overflow: hidden;
    box-shadow: 0 3px 5px 0 rgba(0,0,0,0.1);
    &:not(:first-child) {
      margin-top: 20px;
    }

    .ant-collapse-content-box {
      background-color: $sand-gray;
      padding: 10px 5px !important;
    }

    .ant-collapse-header {
      // padding-right: 0px !important;
    }
  }

  &__content {
    // background-color: $sand-gray;
    // display: flex;

  }

  &__error {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &__error-icon {
    color: #9A989A;
    height: 24px;
    width: 24px;
    margin-right: 5px;
  }
  .clipper-loader-wrapper {
    position: absolute;
  }
}