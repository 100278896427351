.volunteer-dashboard__teams {
  .team-tile {
    width: 842px;
    height: 70px;
    border: 1px solid #EBEBEB;
    background-color: #FFFFFF;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.1);
    // display: flex;
    overflow: hidden;
    position: relative;
    margin-bottom: 20px;
    &::before {
      content: '';
      position: absolute;
      background-color: #ED1C24;
      width: 12px;
      height: 100%;
      bottom: 0;
      left: 0;
    }
  
    &__inner {
      padding: 9px 18px 11px 30px !important;
      height: 70px;
    }
  
    &__inner-row {
      display: flex;
      align-items: center;
    }
  
    &__left-wrapper {
      width: 350px;
      height: 50px;
  
      &.first-row {
          height: 28px;
          color: #333333;
          font-family: Roboto;
          font-size: 20px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 28px;
      }
      &.second-row {
          height: 22px;
          color: #333333;
          font-family: Roboto;
          font-size: 16px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 22px;
      }
    }
  
    &-separator {
      height: 40px;
      width: 1px;
      background-color: #DDDCDD;
    }
  
    &__right-wrapper {    
      margin-left: 12px;
      width: 359px;
      height: 50px;
  
      &.first-row {
          height: 28px;
          color: #333333;
          font-family: Roboto;
          font-size: 20px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 28px;
      }
      &.second-row {
          height: 20px;
          color: #333333;
          font-family: Roboto;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 20px;
      }
    }
  
    &-show-all {
      display: flex;
      justify-content: center;
      align-items: center;
      &-left {
          height: 20px;
          opacity: 0.5;
          color: #333333;
          font-family: Roboto;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 20px;
          text-align: right;
      }
    }
  }
  
}