.file-success {

  min-height: 48px;
  width: 100%;
  border-radius: 24px;
  background-color: #52C41A;
  display: flex;
  align-items: center;
  padding-left: 24px;
  &__text {
    color: white;
    font-weight: 400;
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 48px;
    
  }
  &__icon {
    width: 24px;
    height: 24px;
    color: white;
    margin-right: 12px;
  }
}