@import '../../../shared/styles/main.scss';

.panel-add-button-size{
  height: 48px;
  display: flex;
  align-items: center;
}

.error-text{
  color: red;
  font-size: 12px;
  font-weight: 400;
}

.judging-modal-judges-tab__add-select.button-size .ant-select-selection--single {
  height: 48px;
}

.judging-modal-judges-tab__add-select.button-size .ant-select-selection__rendered {
  margin-top: 8px;
}

.add-table {
  display: flex;
  align-items: center;

}

.hr-line {
  background-color: #C6C4C6;
}

.filter-area {
  display: flex;
  align-items: center;
}

.d-flex {
  display: flex;
  align-items: center;
}

.event-judging-container {
  max-width: 940px;
  margin: 0 auto;

  .panels-cards-wrapper {
    width: 100%;
  }

  .scoring-sessions-wrapper {
    display: flex;
    justify-content: space-between;

    .judge-list-wrapper {
      width: 25%;
    }

    .panels {
      width: 65%;
    }

    @include md-max {
      flex-direction: column;
      justify-content: flex-start;

      .judge-list-wrapper {
        width: 100%;
      }

      .panels {
        width: 100%;
      }
    }
  }
}