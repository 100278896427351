@import '../../../shared/components/Link/Link.scss';
@import '../../../shared/styles/_font.scss';
@import '../../../shared/styles/_color.scss';

.user-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  &__avatar {
    margin-right: 12px;
  }

  &__btn {
    position: absolute;
    z-index: 100;
    left: -16px;
    color: white;
  }
}
