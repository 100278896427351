@import '../../../shared/styles/main.scss';

.time-picker {
  border: 0!important;
  width: 100%;
  height: 48px;

  input {
 
      border-radius: 0;
      height: 48px !important;
      color: $charcoal-gray !important;

    
  }

  &__icon {
    color: #0066B3 !important;
  }
}