@import '../../../../shared/styles/main.scss';

@keyframes myanimation {
  0% {
    background-color: transparent;
    color: transparent;
  }

  35% {
    background-color: $sand-gray;
  }

  100% {
    background-color: $sand-gray;
  }
}

.event-scoring-match-header2 {

  background-color: $sand-gray;
  animation: myanimation 3s;

  &__wrapper {
    background-color: $sand-gray;

    background-color: $sand-gray;
    z-index: 2;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    border: 1px solid #EBEBEB;
    background-color: #F4F4F4;
    box-shadow: 0 3px 23px 0 rgba(0, 0, 0, 0.36);


  }

  @include md-max {
    background-color: $white;
    animation: none;
    // position: absolute;
    // top: 0;
    // left: 0;

    &__wrapper {
      background-color: $white;
      max-width: 940px;
      margin-left: auto;
      position: static;
      margin-right: auto;
      box-shadow: none;
      border: none;
      z-index: inherit;
    }
  }
}

.event-scoring-match-header {

  background-color: $white;
  // position: absolute;
  // top: 0;
  // left: 0;

  &__wrapper {
    background-color: $white;
    max-width: 940px;
    margin-left: auto;
    margin-right: auto;
  }

  &__nav {
    margin-top: 24px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;

    &.end {
      justify-content: flex-end;
    }

    @include sm {
      flex-direction: row;
    }
  }

  &__btn-wrapper {
    display: flex;
    flex-direction: column;

    @include sm {
      flex-direction: row;
    }
  }

  &__exit-btn {
    margin-bottom: 14px;

    @include sm {
      margin-bottom: 0;
      margin-right: 14px
    }
  }

  &__title {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 24px;
    margin-top: 12px;

    @include md {
      flex-direction: row;

    }

    .start-over-button {
      align-self: center;
      margin-left: auto;

      button {
        margin-right: 40px;
        padding: 8px 18px !important;
        height: unset !important;
      }
    }
  }

  &__button-tooltip {
    border-color: white;
    border-radius: 4px;
    background-color: #FFFFFF;
    padding: 12px;

    &>span {
      color: #333333;
      font-family: Roboto;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
    }
  }

  &__sumitted {
    height: 48px;
    padding: 12px 50px 12px 40px;
    border-radius: 24px;
    background-color: #52C41A;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      color: white;
      margin-right: 8px;
    }

    span {
      color: #FFFFFF;
      font-family: Roboto;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 22px;
      text-align: center;
    }
  }

  @include md-max {
    &__nav {
      align-items: flex-start;
      margin-top: 5px;
      margin-bottom: 3px;

      .btn--large {
        padding: 9px 15px !important;

        span {
          margin-right: 0px;
        }
      }
    }

    &__btn-wrapper {
      flex-direction: row;

      span {
        margin-right: 15px;
      }
    }

    &__title {
      flex-direction: row;
      margin-top: 2px;
      margin-bottom: 5px;
    }
  }

  // &__recalculate-score-btn {
  //   &--
  // }
}

.event-scoring-match-header-tooltip {
  .ant-tooltip-arrow::before {
    background-color: white;
    width: 10px;
    height: 10px;
  }

  .ant-tooltip-inner {
    color: white;
    background-color: white;
  }
}

@include md-max {
  .event-scoring-match-header__btn-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: space-around;
    background: #DDDCDD;
    z-index: 2;
    padding: 15px;

    span {
      margin-right: 0px;
    }
  }

  .event-scoring-match-header__exit-btn {
    margin-bottom: 0px;
  }
}