@import '../../../../shared/styles/main.scss';

.c-session-card {
  display: flex;
  flex-direction: row;
  min-height: 120px;

  &-btn {
    min-height: 120px !important;
    width: 100% !important;
    text-align: left !important;
    border-radius: 0 !important;
    padding: 0 !important;
  }

  &--complete {
   
    background-color: #FFFFFF;
    box-shadow: 0 3px 5px 0 rgba(0,0,0,0.1);
    @include md-max {
      width: 80%;
      margin: auto;
      margin-top: 18px;
    }
  }

  margin-top: 10px;

  &__alert-icon {
    color: #9A989A;
    height: 36px;
    width: 36px;
  }

  &__event-icon {
    color: #FFFFFF;
    width: 35px;
    height: 39px;
  }
  &__rectangle {
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;

    &--incomplete {
      background-color: #DDDCDD;
    }

    &--complete {
      background-color: #0D193F;
    }

    &--judging {
      background-color: $session-navy-blue;
    }

    &--scoring {
      background-color: $session-turquoise;
    }

    &--meeting {
      background-color: $session-dark-blue;
    }
  }

  &__data {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 16px;

    &__title {
      max-width: 180px;
    }
    &--incomplete {
      border: 2px #DDDCDD dashed;
      border-left: 0;
    }
    &--complete {
       border: 1px solid #EBEBEB;
       border-left: 0;
    }


    &__date-time {
      margin-top: 10px;
    }
  }
}