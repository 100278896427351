@import '../../../shared/styles/_breakpoints.scss';
@import '../../../shared/components/Paragraph/paragraph.scss';
@import '../../../shared/components/Heading/heading.scss';

.team-tile {
  // width: 263px;
  border: 1px solid #EBEBEB;
  background-color: #FFFFFF;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.1);
  height: 140px;
  // display: flex;
  overflow: hidden;
  position: relative;
  margin-bottom: 20px;
  &::before {
    content: '';
    position: absolute;
    background-color: #ED1C24;
    width: 8px;
    height: 100%;
    bottom: 0;
    left: 0;
  }

  &-inner {
    padding: 10px 14px !important;
  }


  &__number {
    min-height: 32px;
  }

  &__name {
    width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

  }

  &__avatar-container {
    position: absolute;
    bottom: 0;
    right: 0;
    padding-right: 10px;
    padding-bottom: 10px;
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

    }

    li {
      display: inline-block;
      position: relative;
    }
    li:nth-child(n+2) { margin-left: -7px; }
  }

  &__avatar {
    height: 37px;
    width: 37px;
    display: inline-block;
    position: relative;
  }

  &__last-row {
    display: flex;
    // margin-left: auto;
    justify-content: space-between;
    // margin-top: 12px;
  }

  &__location {
    margin-top: 8px;

    &-text {
      width: 150px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

}


.team-tile-btn {
  border-color: transparent;
  background: transparent;
  width: 100%;
  min-height: 120px !important;
  text-align: left !important;
  border-radius: 0 !important;
  padding: 0 !important;
  cursor: pointer;
  border: none;
  // outline:none;
  border: 1px solid #EBEBEB;
  background-color: #FFFFFF;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.1);
  height: 140px;
  // display: flex;
  overflow: hidden;
  position: relative;
  margin-bottom: 20px;
  &::before {
    content: '';
    position: absolute;
    background-color: red;
    width: 8px;
    height: 100%;
    bottom: 0;
    left: 0;
  }

  &:hover, &:focus {
    box-shadow: 2px 5px 8px 2px rgba(0,0,0,0.1);
    outline: 0;
  }
}