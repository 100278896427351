@import '../../../shared/styles/_color.scss';

.dropdown {

  &--240 {
    min-width: 240px;
  }

  &--180 {
    min-width: 180px;
  }

  border-radius: 0px !important;
  width: auto;
  height: 36px !important;
  .ant-select-selection {
    height: 36px !important;
    border-radius: 0px;
    background-color: #F4F4F4;
    border-color: transparent;
    color: $charcoal-gray;
  }
  .ant-select-selection__rendered {
    line-height: 36px !important;

  }
  .ant-select-lg {
    height: 36px !important;
    .ant-select-selection__rendered {
      line-height: 36px !important;
    }
  }
  
  .ant-select-lg .ant-select-selection__rendered {
    line-height: 36px !important;
}

  .ant-select-selection__placeholder {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    // color: $charcoal-gray;
  }
}