@import '../../../shared/styles/_color.scss';
@import '../../../shared/styles/_breakpoints.scss';

.invite-team-list{
  padding: 0 30px;
}

.additional-left-space{
  margin-left: 40px;
}

.invite-team-modal-container{
  padding: 20px 50px;
}

.add-team-modal {
  .ant-modal-content {
    border-radius: 0px;
    min-height: 785px;
    margin-top: 24px;
  }

  .ant-modal-close {
    color: #9A989A;
    margin: 4px;                    
    &:focus {
      outline: 1px solid #9A989A;
    }
  }


  &__or-wrapper {
    margin-top: 20px;
    margin-bottom: 20px;
    @include md {
      margin-top: 10px;
      margin-bottom: 10px;
      display: flex;
      margin-left: auto;
      justify-content: flex-end;
 
    }
  }

  &__or-text {
    width: 180px;
    text-align: center;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;


  }
  &__wrapper {
    max-width: 820px;
    margin: auto;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 46px;
    margin-bottom: 24px;
  }

  &__selection {
    display: flex;
    flex-direction: column;

    @include md {
      flex-direction: row;
      justify-content: space-between;
      // flex-wrap: wrap;
    }

  }

  &__select-wrapper {
    width: 100% !important;
  }
  &__btn-wrapper {

    text-align: center;
    // margin-top: 24px;
    margin-top: 40px;
    @include md {
      margin-top: 0px;
      padding-left: 40px;
    }
  }

  &__select {
    width: 100%;
    height: 48px;
    

    &-name {
      display: flex;
      flex-direction: row;
      width: 35%;
      // text-overflow: ;
      // flex-basis: 100%;
      // flex: 1;
      align-items: center;
      font-size: 16px;
      flex-wrap: nowrap;
      margin: 10px;

      &-child {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .ant-select-dropdown-menu-item-selected {
      background-color: #EDF9E8;
    }
    .ant-select-selection {
      height: 48px !important;
      border-radius: 0px;
      background-color: $white;
      // max-width: 600px;
      border: 2px solid $aquamarine;

      &:focus {
        border: 2px solid #1890FF;
        background-color: #FFFFFF;
        border-right-width: 2px !important;
        border-left-width: 2px !important;
    
      }
      &:hover {
        border: 2px solid #1890FF;
        background-color: #FFFFFF;
        border-right-width: 2px !important;
        border-left-width: 2px !important;
    
      }
      
    }
    .ant-select-selection__rendered {
      line-height: 48px !important;
      .ant-select-selection-selected-value {
        .add-team-modal__select-option {

          .add-team-modal__select-name {
            width: 100%;
            margin: 0px;
          }
          .add-team-modal__select-option__col {
            display: none;
          }
          .add-team-modal__select-option__team-number {
            display: none;
          }
          .add-team-modal__select-option__avatar {
            display: none;
          }
        }
      }
  
    }
    .ant-select-lg {
      height: 48px !important;
      .ant-select-selection__rendered {
        line-height: 48px !important;
      }
    }
    
    .ant-select-lg .ant-select-selection__rendered {
      line-height: 48px !important;
      font-size: 1.6rem;
  }
  
    .ant-select-selection__placeholder {
      font-size: 16px;
      letter-spacing: 0;
      color: $charcoal-gray;
      // line-height: 22px;
    }

    .ant-select-search__field {
      font-size: 16px;
      letter-spacing: 0;
      color: $charcoal-gray;
    }
  }


  &__select-option {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // margin-bottom: 8px;
    margin: -1px;
    &__avatar {
      display: none;
      @include md {
        margin-right: 12px;
        display: block;
        min-width: 48px;
        // border-radius: 50%;
        // object-fit: cover;
        // object-position: center;
      }
    }
    &__col {
      display: flex;
      flex-direction: row;
      width: 20%;
      align-items: center;
      font-size: 16px;
      flex-wrap: wrap;
      margin: 5px;
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
    }
    &__team-number {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      flex-direction: row;
      // width: 20%;
      align-items: center;
      font-size: 16px;
      // flex-wrap: wrap;
      justify-content: flex-end;
      // margin: 1px;
      margin-left: auto;
    }
  }

  &__avatar {
    margin-right: 12px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
  }
}