@import '../../../../shared/styles/main.scss';

.c-download-feedback-file {
  box-shadow: 0 3px 5px 0 rgba(0,0,0,0.1);
  width: 100%;
  max-width: 410px;
  padding: 24px;
  border-color: transparent;
  background: transparent;
  border-radius: 0 !important;
  cursor: pointer;
  border: none;
  outline:none;
  display: flex;
  align-items: center;
  @include md {
    padding: 24px 60px 24px 24px;
  }
  &:hover {
    box-shadow: 0 5px 8px 0 rgba(0,0,0,0.1);

  }
  // &__button {

  // }
}