@import '../../../../shared/styles/main.scss';


.judging-materials-row {
  margin-bottom: 34px;
  &__header {
    display: flex;
    justify-content: space-between;
    background-color: $endeavor-blue;
    min-height: 48px;
    align-items: center;
    padding: 13px 15px 13px 20px;
    color: $white;
  }
  &__content {
    background-color: $sand-gray;
    min-height: 230px;
    padding: 24px 60px
  }
  &__item {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .file-card {
      margin-right: 8px;
      margin-bottom: 8px;
    }
    .choose-file-card {
      margin-right: 8px;
      margin-bottom: 8px;
    }
    &-label {
      max-width: 145px;
      margin-right: 20px;
    }
  }

  &__card {
    height: 180px;
    width: 160px;
    border: 2px dashed rgba(0,0,0,0.15);
    background-color: rgba(0,0,0,0.02);
    display: flex;
    flex-direction: column;

    &-document {
      height: 60px;
      color: $white;
      width: 45px;
      margin: 24px auto 16px;
    }
    &-btn {
      margin: 0 auto !important;
    }

  }

  @include md-max {
    &__content {
      display: flex;
      padding: 24px 12px 24px 18px;
      justify-content: space-between;
      .paragraph--large {
        margin-right: 21px;
      }
    }
  }
}