@import '../../../../shared/styles/main.scss';

.c-join-btn {
  min-width: 74px;
  background-color: #E5EFF7;
  min-height: 24px;
  box-shadow: none;
  border-radius: 0;
  cursor: pointer;
  color: $endeavor-blue;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  border: 0;
  text-align: center;
  padding: 2px 0;

  &:hover {
    color: $endeavor-blue;
    text-decoration: underline;
  }
  &:focus {
    color: $endeavor-blue;
    text-decoration: underline;
  }


  &--large {
    min-width: 175px;
    // width: 100%;
    background-color: #E5EFF7;
    // min-height: 36px;
    box-shadow: none;
    border-radius: 0;
    cursor: pointer;
    color: $endeavor-blue;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    border: 0;
    text-align: center;
    padding: 8px 0;
    font-weight: 500;
  
    &:hover {
      color: $endeavor-blue;
      text-decoration: underline;
    }
    &:focus {
      color: $endeavor-blue;
      text-decoration: underline;
    }
  }


  &--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    text-decoration: none !important;
  }
 
}