@import '../../../shared/styles/_color.scss';
@import '../../../shared/styles/_space.scss';
@import '../../../shared/styles/_font.scss';

p {
  margin-bottom: 0;
}

.paragraph {
  &--default {
    font-size: 1.6rem;
    line-height: 1.5;
    margin-bottom: 0px;
    font-weight: 300;
  }

  &--300 {
    font-weight: 300;
  }

  &--400 {
    font-weight: 400;
  }

  &--normal {
    font-weight: normal;
  }

  &--bold {
    font-weight: 500;
  }

  &--blue {
    color: $endeavor-blue;
  }

  &--black {
    color: $black;
  }

  &--white {
    color: $white;
  }

  &--darkGray {
    color: $charcoal-gray;
  }

  &--charcoal {
    color: $charcoal-gray;
  }

  &--silver {
    color: $silver;
  }

  &--red {
    color: $red-primary;
  }

  &--dark-red {
    color: $dark-red;
  }
  
  &--xsmall {
    font-family: $roboto;
    font-size: 1.2rem;
    line-height: 16px;
  }

  &--small {
    font-family: $roboto;
    font-size: 1.2rem;
    line-height: 20px;
  }

  &--medium {
    font-family: $roboto;
    font-size: 1.4rem;
    line-height: 20px;
  }

  &--large {
    font-family: $roboto;
    font-size: 1.6rem;
    line-height: 22px;
  }

  &--xlarge {
    font-family: $roboto;
    font-size: 1.6rem;
    line-height: 24px;
  }

  &--uppercase {
    text-transform: uppercase;
  }
}
