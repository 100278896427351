// @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
@import 'shared/styles/_color.scss';

body {
  margin: 0;
  font-family: 'Roboto', sans-serif !important;  
  height: 100%;
}

html {
  font-size: 62.5%;
}

// hacks for rtl in antd

//  .admin-dashboard__search-bar-left {
//    &:hover {
//      border: 1px solid #40a9ff !important;
//    }

//    .input{
//      &:hover{
//        border-color: none;
//      }
//    }
//  }

.admin-dashboard__search-bar-left {
  border: 1px solid #e9e9e9;
}

.admin-dashboard__search-bar-left>svg {
  margin-left: 0;
  margin-right: 12px;
}

body.rtl {
  .email-language-selection {
    margin-right: 5px;
  }
  .volunteer-dashboard .ant-tabs-extra-content {
    float: left !important;
  }

  .volunteer-dashboard .ant-tabs-extra-content {
    float: left !important;
  }

  .c-event-messaging-form__actions .btn-secondary-container .m-r-20 {
    margin-left: 20px !important;
  }

  .ant-select-arrow {
    right: unset;
    left: 11px;
  }

  .ant-select-selection__rendered {
    display: flex;
  }

  .create-team-container {
    .create-team {
      margin-right: unset;
      margin-left: 40px;
    }
  
    .ant-select-selection--single .ant-select-selection__rendered {
      margin-right: 11px;
    }
    .ant-select-selection__rendered {
      margin-left: 24px;
    }
  
    .ant-select-selection__clear {
      left: 11px;
      right: unset;
    }
  }
  

  .event-overview-header-team-selection__icon {
    padding-right: 5px;
  }

  .role-selection__icon {
    padding-right: 5px;
  }

  .add-link__icon {
    padding-left: 5px;
  }

  .event-overview-collapse {
    .ant-collapse-header {
      border-top: 1px solid #DDDDDD;
      border-bottom: 1px solid #DDDDDD;
      border-left: 1px solid #DDDDDD;
    }

    .ant-collapse-header::before {
      left: unset;
      right: 0;
    } 
  }
  
  .ant-collapse-arrow {
    right: auto !important;
    left: 16px !important;
  }

  .event-overview-session-card__content {
    border-top: 1px solid #DDDDDD;
    border-bottom: 1px solid #DDDDDD;
    border-left: 1px solid #DDDDDD;
  }
  
  .registration-page-layout__footer-description{
    margin-right: unset;
    margin-left: 50px;
    font-size: 100% !important;
  }

  .anticon {
    margin-left: 5px;
    margin-right: 5px;
  }

  .no-file-chosen {
    padding-right: 24px;
  }

  .teams-coaches-import__choose-file, .import__choose-file {
    margin-left: unset;
    margin-right: 20px;
  }
  
  .admin-dashboard__search-bar-left > svg {
    margin-right: 12px;
  }

  .volunteer-dashboard__search-bar-left > svg {
    margin-right: 12px;
  }

  .event-schedule-card-btn:not(:first-child) {
    margin-left: unset;
    margin-right: 10px;
  }

  .event-schedule-card-btn {
    text-align: right !important;
  }

  .event-schedule-card__data--incomplete, .event-session-card__data--incomplete {
    border: 2px #DDDCDD dashed;
    border-right: 0px;
  }

  .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
    padding-right: 12px;
    padding-left: 40px;
  }

  .ant-select-selection__placeholder {
    text-align: right;
  }

  .m-r-36 {
    margin-right: unset !important;
    margin-left: 36px !important;
  }

  // notification

  .ant-notification-notice-close {
    left: 22px;
    right: unset;
  }

  .ant-notification-notice-with-icon .ant-notification-notice-description {
    margin-left: unset;
    margin-right: 48px;
  }
  .ant-notification-notice-with-icon .ant-notification-notice-message {
    margin-left: unset;
    margin-right: 48px;
  }
  .ant-notification-notice-closable .ant-notification-notice-message {
    padding-left: 24px;
    padding-right: unset;
  }
  .c-judging-rubric-header__exit-btn {
    margin-left: 14px;
    margin-right: unset;
  }
  .c-fll-rubric-form__section-name {
    margin-right: unset;
    margin-left: 30px;
  }
  .c-radio-button:not(:first-child) {
    margin-left: unset;
    margin-right: 12px;
  }
  .c-fll-rubric-form__section-content {
    margin-right: unset;
    margin-left: 44px;
  }
  .c-fll-rubric-form__sub-title {
    margin-right: unset;
    margin-left: 44px;
  }
  .c-fll-rubric-form__section-content-legend-wrapper, .c-fll-rubric-form__section-content-legend-wrapper {
    margin-right: unset;
    margin-left: 44px;
  }
  .user-autocomplete-option__avatar {
    margin-left: 12px;
    margin-right: unset;
  }

  .add-team-modal__selection {
    .add-team-modal__btn-wrapper {
      padding-left: unset;
    }

    .ant-select-selection__rendered {
      margin-right: unset;
    }

    .ant-select-auto-complete.ant-select .ant-select-selection__placeholder {
      margin-right: unset;
    }
  }

  .scoresheet-module {
    .match-scoresheet-form__radio-btn:not(:first-child) {
      margin-left: unset;
      margin-right: 12px;
    }
  }

  .event-scoring-match-header__btn-wrapper {
    .event-scoring-match-header__exit-btn {
      margin-right: unset;
      margin-left: 14px;
    }
  }

  .ant-tabs-ink-bar {
    display: none !important;
  }

  .ant-tabs-tab-active {
    border-bottom: 5px solid #0066B3;
  }
}

.event-overview-container .ant-tabs-tab-active {
  background-color: #F4F4F4;
}

.volunteer-dashboard {
  & .ant-tabs-tab-active {
    background-color: #F4F4F4;
  }

  & .ant-tabs-bar {
    border-bottom: 0px none white;
  }
}

