.c-event-awards {
  max-width: 940px;
  margin: 0 auto 100px;

  &__actions {
    margin-top: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__instructions {
    background-color: #F4F4F4;
    padding: 20px 38px 27px 25px;
    margin: 24px 0 36px;
  }

  &__card-header {
    height: 62px;
    display: flex;
    align-items: flex-end;
    margin-bottom: 16px;

    svg {
      margin-right: 16px;
      min-height: 46px;
    }
  }

  &__nominated-list {
    margin-top: 0;
  }

  &__nominated-team {
    display: flex;
    align-items: center;
    padding: 0 27px;
    &:last-child {
      padding-bottom: 12px;
    }
    > p:first-child {
      width: 30%;
    }
  }
}
