.file-error {
  min-height: 48px;
  width: 100%;
  border-radius: 24px;
  background-color: #FFF1F0;
  display: flex;
  align-items: center;
  padding-left: 24px;
  &__text {
    color: #333333;
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 48px;
    
  }
  &__icon {
    width: 36px;
    color: #ED1C24;
    margin-right: 12px;
  }
}