.c-screenloading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > h2 {
    margin-bottom: -76px;
  }

  h2, p {
    color: white;
  }
}
