.user-tile {
  &__last-row {
    align-items: flex-end;
  }

  &__logo {
    &--small {
      height: 16px;
      width: 60px;
      margin-bottom: 5px;
    }
  }
}
