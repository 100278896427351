@keyframes pulse {
  20% { background-color: #52C41A }
  80% { background-color: #DDDCDD }
}

.c-listloading {
  &__container {
    text-align: center;
    min-height: 400px;
    padding-top: 100px;
  }

  &__spinner {
    display: flex;
    justify-content: center;
    align-items: center;

    &-wrapper {
      display: flex;
      justify-content: space-between;
      width: 100px;
      > div {
        width: 24px;
        height: 24px;
        background-color: #DDDCDD;
        border-radius: 50%;
        animation-fill-mode: both;
        animation: pulse 1.4s .2s infinite cubic-bezier(.05,.71,.84,1.06);
     
        &:nth-child(2) {
          animation-delay: .6s;
        }
  
        &:nth-child(3) {
          animation-delay: 1s;
        }
  
        &:not(:last-child) {
          // margin-right: 12px;
        }
      }

    }
  }
}
