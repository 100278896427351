@import '../../../shared/styles/main.scss';
.back-link {
  padding: 0;
  &__container {
    display: flex;
  }
  white-space: nowrap;
  color: $endeavor-blue;
  display: flex;
  align-items: center;
  &__text {
    font-size: 1.2rem;

  }
  &__icon {
    height: 14px;
    width: 8px;
    margin-right: 5px;
  }
}