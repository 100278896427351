@import '../../../../../shared/styles/main.scss';

.organization-tile {

  height: 150px;
  border: 1px solid #EBEBEB;
  background-color: #FFFFFF;
  box-shadow: 0 3px 5px 0 rgba(0,0,0,0.1);
  margin-bottom: 20px;
  padding: 16px 15px 15px 19px;
  position: relative;
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    inline-size: 200px;
    overflow-wrap: break-word;
    overflow: auto;
    max-height: 80px;
  }
  &__name {
    font-weight: bold;
  }

  &__phone {
    margin-top: 10px;
  }
}
