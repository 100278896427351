@import '../../../../shared/styles/main.scss';

.c-fll-feedback {
  border: 1px solid #D2D2D2;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 64px;
  @include md {
    flex-direction: row;
  }

  &__comment-content {
    position: relative;
  }
  &__comment-delete {
    position: absolute;
    top: 0;
    right: 0;
    color: $endeavor-blue;
  }
  &__comment-edit {
    position: absolute;
    bottom: 5px;
    right: 8px;
    color: $endeavor-blue;
  }

  &__comments {
    list-style: none;
    padding-left: 0;
  }

  &__comment-row {
    padding: 12px 12px;
    box-shadow: 0 5px 11px 0 rgba(67,104,132,0.24);
    &:not(:last-child) {
      // border-bottom: 1px solid #D2D2D2;
      margin-bottom: 16px;
    }
  }

  &__comment-row-readonly {
    padding: 12px 12px;
    &:not(:last-child) {
      border-bottom: 1px solid #DDDCDD;
    }
  }

  &__section {
    padding: 24px;
    width: 100%;
    min-height: 270px;
    @include md {
      width: 50%;
    }
    &--left {
      border-bottom: 1px solid #D2D2D2;

      @include md {
        border-right: 1px solid #D2D2D2;
        border-bottom: none;
      }
    }

    &-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
    }
  }
}

@include md-max {
  .robot-design-comments, .core-value-comments, .innovation-project-comments {
    &-wrapper {
      .h-2 {
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 22px;
      }

      .c-fll-feedback {
        border: none;
      }

      .c-fll-feedback__section {
        border: 1px solid #D2D2D2;
        min-height: 72px;
        margin-bottom: 12px;
      }
    }

    .ant-modal-header {
      display: none;
    }

    .modal__close-btn {
      color: #4B4B4B !important;
    }
  }
}