@import '../../../shared/styles/_color.scss';
@import '../../../shared/styles/_space.scss';
@import '../../../shared/styles/_font.scss';

h1, h2, h3, h4 {
  margin: 0px;
}

.heading {
  &--primary {
    font-size: 3.0rem;
    line-height: 1.27;
    margin-bottom: 0px;
    color: $charcoal-gray;
  }

  &--white {
    color: $white;
  }

  &--black  {
    color: $black;
  }
  
  &--charcoal {
    color: $charcoal-gray;
  }
}

.h--bold {
  font-weight: 500;
}

.h--400 {
  font-weight: 400;
}

.h--300 {
  font-weight: 300;
}

.h--normal {
  font-weight: normal !important;
}

.h-1, .h-2, .h-3, .h-4, .h-2--normal {
  font-family: $roboto;
  letter-spacing: 0;
}

.h-1 {
  font-size: 38px;
  line-height: 46px;
  font-weight: normal;
}

.h-2 {
  font-size: 30px;
  line-height: 38px;
  font-weight: 500;
}

.h-2--normal {
  font-size: 30px;
  line-height: 38px;
  font-weight: normal;
}

.h-3 {
  font-size: 2.4rem;
  line-height: 32px;
}

.h-4 {
  font-size: 2.0rem;
  line-height: 28px;
}
