.confirm-modal {
  top: 10vh;

  .ant-modal-content {
    border-radius: 0;
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.2);
    border: 1px solid #DDDDDD;
  }

  .ant-modal-body {
    padding: 64px 73px 100px 73px;
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1, h2, p {
      text-align: center;
    }
  }
  
  &__footer {
    display: flex;
    button {
      width: 140px;
    }
  }

  .remove-award-content{
    & > p:first-child, & > h2 {
      color: #C50E14;
    }
  }
}
