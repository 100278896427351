@import '../../../shared/styles/main.scss';

  .c-radio-button-wrapper {  
    margin-left: auto;
    flex-wrap: wrap;
    justify-content: flex-end;
    display: flex;
    // align-items: flex-end;
  // display: flex;
  // align-items: center;
  // flex-wrap: wrap;

  // @include xs {
  //   flex-wrap: nowrap;
  //   flex-shrink: 0;
  // }
  .ant-radio-button-wrapper {
    padding: 10px 25px 8px 25px !important;
    min-height: 40px !important;
    &:first-child {
      border-radius: 0;
    }
    &:last-child {
      border-radius: 0;
    }
  }
  
  .ant-radio-button-wrapper-checked {
    color: white;
    background: #0066B3 !important;
    border-color: #0066B3 !important;
  }

  

  .ant-radio-button-wrapper-disabled,
  .ant-radio-button-wrapper-disabled:hover {
    background-color: #f5f5f5;
    border-color: #d9d9d9;
  }
}

.c-radio-button {
  font-size: 1.6rem !important;
  line-height: 20px !important;
  margin-bottom: 12px;
  padding: 10px 25px 8px 25px !important;
  white-space: nowrap;

  &:not(:first-child) {
    margin-left: 12px;
  }
}
