.matches-outter-wrapper {
    margin-top: 64px;
}
.matches-wrapper {
    background-color: #F4F4F4;
    padding: 10px;
    margin-top: 10px;
    .ant-collapse-header {
        padding: 0px !important;
    }

    &-header {
        background-color: white;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 20px;
        span {
            font-family: Roboto;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 22px;
        }
    }

    .arrow-wrapper {
        height: 48px;
        width: 48px;
        display: flex !important;
        align-items: center;
        justify-content: center;
        background-color: #0066B3;

        right: 0px !important;
    }

    .ant-collapse-content-box {
        padding: 0px !important
    }

    .matches-table-control-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #F4F4F4;
        padding: 17px 0px;

        .buttons-left, .buttons-right {
            display: flex;

            .first-button {
                margin-right: 8px;
            }
            .ant-btn {
                padding: 0px 9px!important;
            }
        }
    }

    .matches-table {
        overflow-x: scroll;
        table {
            height: 1px;
            width: 920px;
            border-radius: 0px !important;
            th {
                padding-top: 0px !important;
                padding-bottom: 0px !important;
                border-top: 1px solid #AEACAE;
                border-bottom: 1px solid #AEACAE;
                background-color: #F4F4F4;
                .ant-table-column-title {
                    color: #333333;
                    font-family: Roboto;
                    font-size: 12px;
                    letter-spacing: 0;
                    line-height: 20px;
                }
                &:first-child {
                    padding-left: 8px;
                }

                &:last-child {
                    padding-right: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                }
            }
            tr {
                height: 100%;
                td {
                    height: 100%;
                }
            }
            .ant-table-thead > tr:first-child > th:last-child {
                border-top-right-radius: 0px !important;
            }
            .ant-table-thead > tr:first-child > th:first-child {
                border-top-left-radius: 0px !important;
            }
        }
    
        .odd-row{
            background-color: #ffffff;
        }
        
        .even-row{
            background-color: #E5EFF7;
        }

         th, td {
            vertical-align: top;
            padding-left: 0px;
            padding-right: 0px;
        }
    
        .table-item-wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 100%;
            .status-badge-container {
                justify-content: flex-start;
            }
        
            p {
                color: #333333;
                font-family: Roboto;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 20px;
            }
        }
    
        .ant-table-expanded-row {
            height: 0px !important;
            th, td {
                padding: 0px !important;
            }
        }
        .hidden-table-row {
            height: 0px;
        }
    
        .panel-text-wrapper {
            text-align: right;
            margin-right: 30px;
            width: 110px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    
        .status-collection {
            display: flex;
        }
        
        .team-title {
            display: flex;
            align-items: baseline;
            .expanded-arrow{
                height: 20px;
                width: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .title-text {
                color: #333333;
                font-family: Roboto;
                font-size: 16px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 22px;
                width: 200px;
                // white-space: nowrap;
                // overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    
        .matches {
            &-times-wrapper, &-panel-wrapper, &-edits-wrapper, &-scores-wrapper, &-status-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
                .matches-time {
                    color: #333333;
                    font-family: Roboto;
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 20px;
                    width: 180px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            &-scores-wrapper {
                padding-right: 30px;
                justify-content: flex-end;
                .table-item-wrapper {
                    justify-content: flex-end;
                    padding-right: unset;
                    font-size: 14px;
                    color: #333333;
                    font-family: Roboto;
                    letter-spacing: 0;
                }
            }
        }

        .matches-panel-wrapper {
            align-items: flex-end;
        }

        .ant-pagination-total-text {
            position: absolute;
            left: 10px;

            .showing-total-wrapper {
                display: flex;
                .divider {
                    margin-left:5px;
                    margin-right: 5px;
                }
            }
        }
    }
}

.fullscreen {
    background-color: transparent;
    .matches-table {
        table {
            width: 100%;
        }
    }

    .ant-modal-body {
        padding: 30px !important;
    }

    .matches-wrapper-header {
        padding-left: unset;
        margin-bottom: 13px;
        .title {
            color: #333333;
            font-family: Roboto;
            font-size: 38px;
            letter-spacing: 0;
            line-height: 46px;
            font-weight: normal;
        }
    }

    .matches-table table th {
        background-color: #ffffff;
    }

    .matches-table-control-row {
        background-color: #ffffff;
    }
}