@import '../../../shared/styles/_space.scss';
@import '../../../shared/styles/_breakpoints.scss';
@import '../../../shared/styles/_color.scss';
@import '../../../shared/components/Link/Link.scss';


.team-coach-tile {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 8px;
  align-items: flex-start;

  &__data {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__avatar {
    margin-right: 12px;
  }
}