@import '../../../shared/styles/main.scss';

.event-overview-collapse {
  background-color: $white;
  border-radius: 0;
  .ant-collapse-item {
    border-bottom: 0;
    
  }
  .ant-collapse-content-box {
    padding-top: 0px !important;
  }

  .ant-collapse-header {
    border-top: 1px solid #DDDDDD;
    border-bottom: 1px solid #DDDDDD;
    border-right: 1px solid #DDDDDD;
    position: relative;
    padding: 4px 16px !important;
    padding-right: 40px;
    &::before {
      content: '';
      position: absolute;
      background-color: #ED1C24;
      width: 4px;
      height: 100%;
      bottom: 0;
      left: 0;
    }
  }
  &__panel-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    &__date {
      min-width: 47px;
      font-size: 30px;
      letter-spacing: 0;
      line-height: 38px;
      font-family: $roboto;
    }
    &__day {
      padding-right: 26px;

    }

  }
}