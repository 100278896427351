@import '../../../../shared/styles/main.scss';

.referee-scoresheet-notes {

  &__container {
    border: 1px solid #C6C4C6;
    background-color: $white;
    min-height: 100px;
    display: flex;
    padding: 20px;
    margin-bottom: 36px;
    flex-direction: column;
  }
  &__header {
    width: 100%;
    margin-bottom: 24px;
  }
  &__header-readonly {
    width: 100%;
    margin-bottom: 8px;
  }
  &__title {
    display: flex;
    flex-direction: column;
    @include sm {
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
    }
  }
  &__add {
    color: $endeavor-blue;
    font-size: 1.6rem;
    display: flex;
    align-items: center;
  }
  
  &__notes {
    list-style: none;
    padding: 0 !important;
  }

  &__comment-wrapper:not(:last-child) {
    margin-bottom: 16px;
  }
}