@import '../../../shared/styles/main.scss';

.volunteer-resources-modal {
  
  &__section {
    padding: 34px;
    background-color: #F4F4F4;
    max-width: 300px;
  }

  &__col {

    ul {
      padding: 0;
      list-style: none;
     
    }
    &-item {
      padding-bottom: 24px;
      a.disabled {
        pointer-events: none;
        color: #CCC;
      }
    }
  }
}