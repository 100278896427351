.referee-assignment-table {
    .assigned {
        span {
            color: #0066B3;
            font-family: Roboto;
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 20px;
        }
    }

    .unassigned {
        span {
            color: #333333;
            font-family: Roboto;
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 20px;
        }
    }

    .odd-row{
        background-color: #ffffff;
    }
    
    .even-row{
        background-color: #E5EFF7;
    }

    thead {
        tr {
            th {
                padding: 3px 5px;
                color: #333333;
                font-family: Roboto;
                font-size: 12px;
                letter-spacing: 0;
                line-height: 20px;
                background-color: #ffffff;
                &:not(:first-child){
                    text-align: center;
                }
            }
        }
    }

    tbody {
        tr {
            td {
                padding: 10px 5px;
                &:first-child{
                    color: #333333;
                    font-family: Roboto;
                    font-size: 16px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 22px;
                }
                &:not(:first-child){
                    border-left: 1px solid #DDDCDD;
                    .checkbox-wrapper {
                        padding-left: 30px;
                    }
                }
            }
        }
    }
    .ant-pagination-total-text {
        position: absolute;
        left: 10px;

        .showing-total-wrapper {
            display: flex;
            .divider {
                margin-left:5px;
                margin-right: 5px;
            }
        }
    }
}

.referee-assignment-table-wrapper {
    .search-bar-wrapper {
        display: flex;
        margin: 18px 0px;
        .admin-dashboard__search-bar {
            border: 1px solid #C1C1C1;
            padding-right: 5px;
            input {
                border: none !important;
            }
            .ant-input:focus {
                box-shadow: none;
            }
        }
    }
}