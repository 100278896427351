@import '../../../../shared/styles/main.scss';

.detailed-match-score-card {
  display: flex;
  flex-direction: row;
  background-color: #F4F4F4;
  padding: 8px 8px 8px 16px;
  min-width: 280px;
  justify-content: space-between;


  &__score {
    padding: 12px;
    background-color: $white;
    text-align: center;
  }

  &__team {
    margin-top: 12px;
    max-width: 185px;
  }
}