@import '../../../shared/styles/main.scss';

// @import 'features/EventContainer/components/EventVolunteersContainer/VolunteersModal/I'

.event-invited-list {
  background-color: #FFFFFF;
  // box-shadow: 0 5px 5px 0 rgba(67,104,132,0.24);
  box-shadow: 0 2px 8px 0 rgba(0,0,0,0.15);
  margin: 8px 4px;
  padding: 16px;

  &__errors {
    // list-style: none;
    // padding: 0;
    max-width: 700px;
    color: $red-primary;
    li {
      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }
}


.event-invited-list-container {
  margin-top: 34px;
}