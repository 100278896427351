@import '../../../shared/components/Modal/Modal.scss';
@import '../../../shared/styles/main.scss';
@import '../../../shared/components/Button/styles/BtnSecondary.scss';
@import '../../../shared/components/Button/styles/BtnPrimary.scss';

.judging-panel-modal {

  &__title {
    margin-bottom: 24px;
    margin-top: 36px;
    display: flex;
    justify-content: space-between;
  }

  &__tab-content {
    // margin-top: 24px;

  }

  &__list-container {
    padding-top: 36px;
    width: 100%;
    @include md {
      width: 50%;


    }
    
  }

  &__list {
    max-width: 300px;
    margin-top: 8px;
    max-width: 100%;

    @include md {
      max-width: 300px;
      margin-left: auto;
    }

    &-label {
      margin-left: auto;
      max-width: 100%;

      @include md {
        max-width: 300px;
        margin-left: auto;
      }
    }
    &--no-data {
      border: 1px solid #D9D9D9;
      min-height: 330px;
    }

    &--has-data {
      box-shadow: 0 5px 11px 0 rgba(67,104,132,0.24);
      margin: 4px;
      min-height: 330px;
      padding: 20px;
    }


    &-btn-primary-wrapper {
      button {
        height: unset;
        border-radius: 0;
        &:disabled {
          background-color: $endeavor-blue-disabled !important;
          color: $white !important;
          border-color: $endeavor-blue-disabled !important;
        }
        &[disabled] {
          background-color: $endeavor-blue-disabled !important;
          color: $white !important;
          border-color: $endeavor-blue-disabled !important;
        }
      }
    }

    &-buttons-row {
      margin-top: 36px;
    }

    &-btn {
      width: 100%;
      padding-top: 13px;
      padding-bottom: 13px;
      margin-bottom: 12px;
    }
   
  }


  &__add {
    &-container {
      
      width: 100%;

      @include md {
        width: 50%;
        border-right: 1px #DDDCDD solid;
        min-height: 650px;
      }

    }

    &-form {
      padding-top: 36px;
      max-width: 100%;

      @include md {
        max-width: 300px;

      }
    }

    &-select {
      border-radius: 0px !important;
      margin-top: 12px;
      margin-bottom: 36px;
      border: 1px solid #EBEBEB;
      .ant-select-selection {
        height: 48px !important;
        border-radius: 0px;
        background-color: $white;
        border: 2px solid #1890FF;

        &:focus {
          border: 2px solid #1890FF !important;
          background-color: #FFFFFF;
          border-right-width: 2px !important;
          border-left-width: 2px !important;
      
        }
        &:hover {
          border: 2px solid #1890FF !important;
          background-color: #FFFFFF;
          border-right-width: 2px !important;
          border-left-width: 2px !important;
          border-bottom-width: 2px !important;
          // box-shadow: none;
          border-bottom: 2px solid #1890FF;
        }
        
      }

      .ant-select-selection__rendered {
        overflow: hidden;
        line-height: 48px;
        color: $charcoal-gray;
      }
    }
  }
}

.in-person-scoring-modal {
  .form-dropdown {
    min-height: 40px !important;
    .ant-select-selection {
      min-height: 40px !important;
      padding-top: 4px !important;
      padding-bottom: 4px !important;

      .ant-select-selection__rendered {
        height: 30px;
      }
    }
  }
  .ant-select-selection--multiple .ant-select-selection__clear, .ant-select-selection--multiple .ant-select-arrow {
    top: 20px;
  }
}