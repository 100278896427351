@import '../../../shared/components/Heading/heading.scss';
@import '../../../shared/styles/_color.scss';
@import '../../../shared/styles/_space.scss';
@import '../../../shared/styles/_font.scss';

.white{
  color: #fff
}

.custom-switch.ant-switch{
  &[aria-checked="false"] {
    // background-color: rgba(255, 255, 255, 0.5) !important;
    background-color: #1890ff !important; 
  }
}

.role-selection {
  // padding: 28px 14px;

  &__menu-item {
    display: flex;
    align-items: center;
    color: $endeavor-blue;
  }

  &__item {
    font-size: 16px;
    line-height: 1.5;
    font-family: $roboto;
    list-style: none;
  }

  &__sub-title {
    font-size: 1.4rem;
    color: $charcoal-gray;
    margin-bottom: 12px;
    padding: 16px;
  }

  &__menu {
    border: transparent;
  }

  &__title {
    display: flex;
    align-items: center;
    margin-top: 27px;
    height: 32px;
  }

  &__title-text {
    color: #FFFFFF;
    font-family: Roboto;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 32px;
    font-weight: normal;
  }

  &__icon {
    margin-left: 10px;
    color: $white !important;
    cursor: pointer;
  }
}
