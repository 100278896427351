@import '../../../shared/styles/main.scss';

.range-picker {
  width: 100%;
  border: 0;
  .ant-input {
    border-radius: 0;
    height: 48px;
    color: $charcoal-gray !important;

  }
}