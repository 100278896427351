.c-download-link {
  display: flex;
  align-items: center;

  p, i {
    color: #0066B3;
  }

  i {
    font-size: 20px;
    margin-right: 8px;
  }

  &--disabled {
    p, i {
      color: #9A989A;
    }

  }
}
