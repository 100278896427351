.subpage-layout {

  &__skeleton {
    max-width: 940px;
    margin-left: auto;
    margin-right: auto;
  }

  &__content {
    position: relative;
      // max-width: 940px;
      margin-left: auto;
      margin-right: auto;
  }
  // margin-top: 18px;
}