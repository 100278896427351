@import '../../../shared/styles/_breakpoints.scss';

.c-container {

  &--default {
    padding-left: 20px;
    padding-right: 20px;

    @include md {
      padding-left: 30px;
      padding-right: 30px;
    }

    @include lg {
      padding-left: 100px;
      padding-right: 100px;
    }
    @include xl {
      padding-left: 0;
      padding-right: 0;
      max-width: 940px; // (1440 - 250 - 250)
      margin-right: auto;
      margin-left: auto;
    }
  }

  &--medium {
    padding-left: 20px;
    padding-right: 20px;

    @include md {
      padding-left: 40px;
      padding-right: 40px;
    }

    @include lg {
      padding-left: 100px;
      padding-right: 100px;
    }
    @include xl {
      padding-left: 0;
      padding-right: 0;
      max-width: 780px; // (1440 - 250 - 250)
      margin-right: auto;
      margin-left: auto;
    }
  }

  &--small {
    padding-left: 20px;
    padding-right: 20px;

    @include md {
      padding-left: 40px;
      padding-right: 40px;
    }

    @include lg {
      padding-left: 100px;
      padding-right: 100px;
    }

    @include xl {
      padding-left: 0;
      padding-right: 0;
      max-width: 620px; // (1440 - 250 - 250)
      margin-right: auto;
      margin-left: auto;
    }
  }

  &--delete-option {
    padding-right: 20px;

    @include sm {
      padding-right: 210px;
    }

    @include xl {
      padding-right: 0;
    }
  }
}
