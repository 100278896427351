@import '../../../../shared/styles/_breakpoints.scss';

.teams-add-coaches-modal__manual-invite-list-container{
 margin-top: 73px !important;
}

.side-padding{
  padding: 0 20px;
}

.edit-event-team-content {
  min-height: 500px;
  padding: 30px;
  border: 1px solid #EBEBEB;
  background-color: #FFFFFF;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.1);
  overflow: hidden;
  margin-top: 28px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    width: 8px;
    height: 100%;
    bottom: 0;
    left: 0;
  }

  .add-coach-content{
    display: flex;
    align-items: center;
  }

  &--red {
    &::before {
      background-color: #ED1C24;
    }
  }
  
  &--purple {
    &::before {
      background-color: #7131A2;
    }
  }

  &--green {
    &::before {
      background-color: #01B75A;
    }
  }

  &--orange {
    &::before {
      background-color: #F57E25;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__header-inner {
    flex-direction: column;
  }

  .team-info-edit-button {
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
  }
}

.edit-event-delete-option-section {
  top: 20px;
  left: 0;
  right: 25px;
  width: 180px;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;

  @include sm {
    top: 154px;
    left: initial;
    position: absolute;
  }

  .title {
    font-weight: bold;
  }

  .delete-checkbox-wrapper {
    margin: 6px 0px 16px 0px;
  }
  .checkbox-text {
    width: 160px;
  }

  .button-wrapper {
    .ant-btn {
      padding: 6px 40px;
    }
  }
}