@import '../node_modules/antd/dist/reset.css';
@import 'shared/components/Paragraph/paragraph.scss';
@import 'shared/components/Link/Link.scss';
@import 'shared/styles/_space.scss';
@import 'shared/components/Modal/Modal.scss';

#root {
  height: 100%;
  &.fullscreen {
    overflow: auto;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.u-sr-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 0.1rem;
  margin: -0.1rem;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 0.1rem;
  z-index: -99999;
  
  &:not(:focus):not(:active) { 
    border: 0;
    clip: rect(0 0 0 0);
    height: 0.1rem;
    margin: -0.1rem;
    overflow: hidden;
    padding: 0;
    position: absolute; // tells the browser to remove the element from the page flow and to begin positioning it
    width: 0.1rem;
    z-index: -99999;
  }
}

.link {
  color: $endeavor-blue;
  font-family: $roboto;
  font-size: 1.4rem;
  letter-spacing: 0;
  line-height: 20px;
  &:focus {
    text-decoration: underline;
  }

  &--email {
    color: $endeavor-blue;
    font-family: $roboto;
    font-size: 1.2rem;
    letter-spacing: 0;
    line-height: 20px;

    &:focus {
      text-decoration: underline;
    }
  }

  &--large {
    color: $endeavor-blue;
    font-family: $roboto;
    font-size: 1.6rem;
    letter-spacing: 0;
    line-height: 22px;
    &:focus {
      text-decoration: underline;
    }
  }
}