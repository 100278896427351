@import '../../../../shared/styles/main.scss';
.match-scoresheet {
  position: relative;

  &__form {
    max-width: 940px;
    margin-left: auto;
    margin-right: auto;
  }

  ol li{
    list-style-type: none;
    
  }
  ol {
    padding-left: 0px !important;
  }
  &__list {
    max-height: 700px;
    overflow: auto;
  }

  @include md-max {
    &__referee-notes {
      .ant-modal-header {
        display: none;
      }

      .modal__close-btn {
        color: #4B4B4B !important;
      }
    }

    &-referee{
      .scoresheet-module__name {
        width: 72px;
        .h-2 {
          font-size: 16px;
          letter-spacing: 0;
          line-height: 22px;
        }
      }

      .scoresheet-module__content {
        margin-left: 88px !important;
        padding-right: 20px !important;
        margin-top: -30px !important;
        width: unset;
        .h-2 {
          font-size: 16px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 22px;
        }

        .c-radio-button-wrapper, .match-scoresheet-form__radio-wrapper {
          justify-content: flex-start;
          .c-radio-button, .ant-radio-button-wrapper {
            margin-right: 12px;
            margin-left: 0px;
          }
        }
      }
    }
  }
}