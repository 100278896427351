@import '../../../shared/styles/main.scss';

.btn-wrapper {



}


.btn {
  height: unset;
  // border-radius: 0;
  border-radius: 4px;



  &--180 {
    min-width: 180px;
  }

  &--140 {
    min-width: 140px;
  }



  // border-color: $endeavor-blue;
  // &:disabled {
  //   background-color: $endeavor-blue;
  //   opacity: 0.3;
  // }
  // &[disabled] {
  //   // background-color: purple !important;
  // }

  // button sizes

  &--small {
    height: 24px;
    @include padding(2px, 17px, 2px, 17px);
    font-size: 1.4rem;
  }

  &--medium {
    height: 36px !important;
    font-size: 1.4rem;
    @include padding(8px, 19px, 8px, 19px);
  }

  &--large {
    @include padding(10px, 30px, 10px, 30px);
    font-size: 1.6rem;
    height: 48px !important;
  }


  &--primary {
    background-color: $endeavor-blue;
    &:hover {
      color: #fff;
      background-color: #1890FF;
      border-color: #1890FF;
    }
  }

  &--secondary {
    background-color: $white;
    color: $endeavor-blue;
    border: 1px solid $endeavor-blue;
    &:hover {
      color: #fff;
      background-color: #1890FF;
      border-color: #1890FF;
    }

    &--disabled {
      background-color: $white;
      color: $endeavor-blue;
      border: 1px solid $endeavor-blue;
      // pointer-events: none;
      // pointer-events: none;
      // cursor: default;
      // opacity: 0.3;
    }
  }

  &--tertiary {
    background-color: #D8D8D8;
    color: $endeavor-blue;
    border: 2px solid #D8D8D8;
    &:hover {
      color: #1890FF;;
      background-color: #D8D8D8;
      border-color: #D8D8D8;
    }
  }

  &--disabled {
    // background-color: #DDDCDD !important;
    // border-color: #DDDCDD !important;
    color: white !important;
    opacity: 0.3;
  }

  &__text {
    &--primary {
      font-size: 1.6rem;
      @include padding(10px, 30px, 10px, 30px);
    }
  }
}