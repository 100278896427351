@import '../../../shared/styles/_space.scss';
@import '../../../shared/styles/_breakpoints.scss';
@import '../../../shared/styles/_color.scss';
@import '../../../shared/components/Link/Link.scss';

.event-modal-header {
  display: flex;
  padding-right: 50px;

  .event-date-wrapper, .event-location-wrapper {
    display: flex;
  }

  &__point {
    color: $white;
  }

  &__text {
    margin-right: 12px;
    &--right {
      margin-left: 12px;
      font-weight: 300;
    }
  }

}