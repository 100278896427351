@import '../../../../shared/components/Modal/Modal.scss';
@import '../../../../shared/components/Paragraph/paragraph.scss';
@import '../../../../shared/styles/_breakpoints.scss';
@import '../../../../shared/components/RangePicker/RangePicker.scss';
@import '../../../../shared/components/TimePicker/TimePicker.scss';
@import '../../../../shared/styles/_color.scss';
@import '../../../../shared/styles/main.scss';

.schedule-session-modal {
  max-width: 940px;
  margin-top: 36px;
  margin-left: auto;
  margin-right: auto;

  &__external-link {
    white-space: nowrap;
    overflow: hidden;
    border-bottom: none !important;
    cursor: inherit !important;
    text-decoration: none !important;
  }

  &__range-picker {
    width: 100% !important;
    .ant-input {
      border-radius: 0;
      height: 48px;
      color: $charcoal-gray !important;
  
    }
    .ant-calendar-range-picker-separator {
      vertical-align: baseline;
    }
  }
  &__title {
    display: flex;
    flex-direction: column;
    @include xs {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

  }

  &__content {
    max-width: 540px;
    min-height: 750px;
    margin: auto;
    display: flex;
  }

  &__meeting-content {
    max-width: 640px;
    min-height: 750px;
    display: flex;
    // margin: auto;
  }

  &__rectangle {
    margin-right: 24px;
    margin-top: 68px;
    display: flex;
    width: 65px;
    background-color: #03D2E2;
    align-items: center;
    justify-content: center;
    height: 146px;

    &--opening {
      background-color: $endeavor-blue;
    }
    &--trophy {
      background-color: $endeavor-blue;
    }
    &--judging {
      background-color: $session-navy-blue;
    }
    &--scoring {
      background-color: $session-turquoise;
    }
    &--score {
      background-color: $session-turquoise;
    }
  }
  &__rectangle-meeting {
    margin-right: 24px;
    margin-top: 68px;
    display: flex;
    width: 65px;
    background-color: #0F6F8E;
    align-items: center;
    justify-content: center;
    height: 146px;
  }
  &__icon {
    width: 35px;
    height: 39px;
    color: white;
  }

  &__form {
    width: 100%;
    margin-top: 48px;
  }

  &__details {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    margin-top: 24px;

    &-team {
      line-height: 50px !important;
    }
  }
  &__manage {
    align-self: flex-end;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
  }


}