.event-overview-layout {
  &__hero {
    height: 550px;
    z-index: -1;
    position: absolute;
    width: 100%;
    top: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background: radial-gradient(circle, #00648F 0%, #003C66 100%);
  }

  &__hero-image {
    background-image: url('../../../../public/audience-cheering.png');;
    z-index: 1000;
    width: 100%;
    min-height: 500px;
    margin-top: 100px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  &__content {
    max-width: 940px;
    margin: 0 auto 40px;
    background-color: transparent;
  }

  &__sponsor-header {
    padding: 61px 0 64px;
  }
}
