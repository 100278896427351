.c-custom-link {
  font-size: 1.6rem;
  line-height: 2.2rem;
  font-weight: 400;
  color: #0066B3;

  &__icon {
    padding-right: 8px;
  }

  &--white {
    color: white
  }

  &--primaryBlue {
    color: #0066B3;
  }

  &__inner {
    display: flex;
    align-items: center;
  }
}
