.c-event-messaging {
  max-width: 940px;
  margin: 0 auto 100px;

  &__actions {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__instructions {
    background-color: #F4F4F4;
    padding: 20px 38px 27px 25px;
    margin: 24px 0 36px;
  }

  &__channels {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px 50px;

    &--modal {
      max-height: 478px;
      overflow-y: scroll; 
    }
    
    > section {
      margin: 10px;
    }
  }
}

.c-event-messaging-channel {
  border: 1px solid #DDDDDD;
  position: relative;
  padding: 31px 57px 0 34px;
  width: 460px;
  height: 219px;

  &__edit {
    position: absolute;
    top: 11px;
    right: 0;
    height: 20px;
    > div {
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__title {
    display: flex;
    svg {
      margin-right: 6px;
    }
  }
  
  &__description {
    height: 40px;
    overflow-y: auto;
    margin: 2px 0 15px !important;
  }

  &__roles {
    height: 40px;
    margin-bottom: 6px !important;
  }

  &__link {
    width: 120px;
    background-color: #E5EFF7;
    height: 36px;
    border: none;
    border-radius: 0;

    &:hover, &:visited, &:focus {
      background-color: #eff5fa;
    }
    
    > div {
      height: 36px;
      justify-content: center;
      font-size: 14px;
      font-weight: bold;
    }
  }
}

.c-event-messaging-form {
  .ant-form-item {
    align-items: flex-start;
    margin-bottom: 32px !important;
  }
  
  .ant-form-item-label {
    width: 200px;
    margin-right: 20px;
    white-space: pre-wrap;
    height: 48px;
    display: flex;
    align-items: center;
    > label {
      font-size: 16px;
      line-height: 22px;
      font-weight: 600;
      color: #333333;
    }
  }

  .ant-form-item-control-wrapper {
    width: 100%;
  }

  #AddMessageChannel_title {
    width: 52%;
  }

  &__roles {
    display: flex;
    justify-content: space-between;
    padding-right: 22px;
    .checkbox {
      margin: 0 0 8px !important;
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
  }
}
