@import '../../../shared/styles/main.scss';

.judges-list {
  min-width: 220px;

  &__unassigned {
    border-bottom: 1px solid #EBEAEB;
    padding-bottom: 16px;
  }

  &__assigned {
    padding-top: 36px;
  }

  &__header {
    padding-bottom: 8px;
    border-bottom: 4px solid #EBEAEB;
  }
  &__tile {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 8px;

    &-name {
      margin-left: 8px;
    }
  }
}