.collapse-header-judging {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -20px;
    margin-bottom: 36px;

    &_title-section {
        flex:1;
        height: 48px;
        background-color: #0066B3;
        color: #FFFFFF;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 22px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 13px 20px
    }

    &_title-section-left {
        font-weight: bold;
    }

    &_title-section-icon {
        width:48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #DDDCDD;
    }
}