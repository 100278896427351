@import '../../../../shared/styles/_breakpoints.scss';
@import '../../../../shared/styles/main.scss';

.event-schedule-card {
  // width: 297px;
  // width: 100% !important;
  display: flex;
  flex-direction: row;
  min-height: 120px;
  position: relative;

  &-btn {
    border-color: transparent;
    background: transparent;
    width: 297px;
    min-height: 120px !important;
    text-align: left !important;
    border-radius: 0 !important;
    padding: 0 !important;
    cursor: pointer;
    border: none;
    outline:none;
    &[disabled]{
      cursor: default;
    }
    &:disabled{
      cursor: default;
    }

    &:not(:first-child) {
      margin-top: 10px;
    }
    @include md {
      &:not(:first-child) {
        margin-left: 10px;
        margin-top: 0;
      }
      
    }


  }
  
  &--complete {
   
    background-color: #FFFFFF;
    box-shadow: 0 3px 5px 0 rgba(0,0,0,0.1);

    @include md-max {
      width: 80%;
      margin: auto;
    }
  }


  &__alert-icon {
    color: #9A989A;
    height: 36px;
    width: 36px;
  }

  &__event-icon {
    color: #FFFFFF;
    height: 36px;
    width: 36px;
  }
  &__rectangle {
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;

    &--incomplete {
      background-color: #DDDCDD;
    }

    &--ceremony {
      background-color: $endeavor-blue;
    }


    &--judging {
      background-color: #0D193F;
    }

    &--scoring {
      background-color: #03D2E2;
    }
  }

  &__data {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 16px;
    padding-right: 16px;

    &__title {
      max-width: 180px;
    }
    &--incomplete {
      border: 2px #DDDCDD dashed;
      border-left: 0;
    }
    &--complete {
       border: 1px solid #EBEBEB;
       border-left: 0;
    }


    &__date-time {
      margin-top: 10px;
    }
  }


}