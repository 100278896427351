@mixin borderless {
  height: 32px;
  .ant-select-selection__rendered {
    height: 32px;
    line-height: 32px;
    margin-right: 30px;
  }
  .ant-select-selection {
    border: none;
    height: 32px;
    background-color: #F4F4F4;
    color: #333333;
    border-radius: 0px;
  }
  .ant-select-arrow svg {
    width: 12px;
  }
}

@mixin form {
  width: 100%;
  height: 48px;
  .ant-select-selection__rendered {
    height: 48px;
    line-height: 48px;
  }
  .ant-select-selection {
    height: 48px;
    border-radius: 0px;
    &:focus{
      outline: 0;
      box-shadow: none;
    }
  }
}

.select {
  border-radius: 0px !important;

  &--transparent {
    @include borderless;
  }

  &--gray {
    @include borderless;
    .ant-select-selection {
      background-color: #DDDCDD;
    }
  }

  &--blue {
    height: 36px;
    min-width: 162px;
    .ant-select-selection--single {
      height: 36px;
    }
    .ant-select-selection__rendered {
      height: 36px;
      line-height: 36px;
      margin-right: 30px;
    }
    .ant-select-search__field,
    .ant-select-selection-selected-value {
      font-size: 14px;
      color: #333333;
    }
    .ant-select-selection__placeholder {
      font-size: 14px;
      color: #9A989A;
    }
    .ant-select-arrow svg {
      width: 12px;
      color: #0066B3;
    }
  }

  &--regular, &--blue {
    .ant-select-selection {
      background-color: white;
      border: 1px solid #EBEBEB;
      box-shadow: 0 3px 5px 0 rgba(0,0,0,0.1);
    }
  }

  &--regular {
    @include form;
  }
}
