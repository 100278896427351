@import '../../../shared/styles/main.scss';
.ant-form {
  @media screen and (max-width: 560px) {
    width: 80%;
  }
}

.c-toc-steps {
  z-index: 5;
  background-color: white;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 560px) {
    width: 80%;
  }

  &__description {
    width: 78%;
    height: 80px;
    background-color: #FEEAC4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  &__content {
    width: 100%;
    height: 580px;
    padding-right: 32px;
    background-color: white;
    overflow: -moz-scrollbars-vertical; 
    overflow-y: scroll;
    overflow-x: hidden;

    @media screen and (max-width:560px) {
      overflow-x: scroll;
    }

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, .3);
      box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }
    div:not(:first-child) {
      h4 {
        margin-top: 16px;
        &:first-child {
          margin-top: 36px;
        }
      }
    }
    ul, ol {
      padding-left: 30px;
    }
    h4, p, li {
      font-family: 'Roboto', sans-serif;
      color: #333333;
    }
    h4 {
      font-size: 20px;
      line-height: 28px;
      letter-spacing: 0;
      font-weight: normal !important;
      margin-bottom: 12px;
    }
    p, li {
      font-size: 16px;
      line-height: 24px;
    }
    p {
      margin-bottom: 8px;
    }
    li {
      margin-bottom: 6px;
    }
    address > p {
      margin-bottom: 0;
    }
    .toc-tooltip, a {
      color: #0067B9;
      text-decoration: underline;
    }
    a {
      cursor: pointer;
    }
    .checkbox {
      margin: 12px 0 0;
    }
    .ant-form-item {
      margin-top: 0px !important;
      margin-bottom: 0px !important;
    }
  }

  &__action {
    padding: 48px 44px 0 0;
    display: flex;
    justify-content: center; 
  }
}