@import '../../shared/styles/main.scss';

.public-scoresheet-header {
    &2{
        z-index: 2;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        border: 1px solid #EBEBEB;
        background-color: #F4F4F4;
        box-shadow: 0 3px 23px 0 rgba(0,0,0,0.36);

        .public-scoresheet-header-start-over {
            border: 1px solid #C1C1C1 !important;
            border-radius: 4px !important;
            background-color: #FFFFFF !important;
        }
    }

    &-wrapper {
        max-width: 940px;
        margin: auto;
        display: flex;
        justify-content: space-between;
        padding-top: 30px;
        padding-bottom: 10px;
    }

    &-logo-wrapper {
        display: flex;
        align-items: flex-start;
    }

    &-logo-two {
        margin-left: 30px;
    }

    &-title {
        color: #333333;
        font-family: Roboto;
        font-size: 38px;
        letter-spacing: 0;
        line-height: 46px;
        text-align: right;
    }

    &-buttons-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 30px;
    }

    &-start-over {
        margin-right: 40px;
        border: none !important;
        border-radius: 4px !important;
        background-color: #F4F4F4 !important;
        padding: 8px 18px !important;
        height: unset !important;

        span {
            font-size: 12px;
            letter-spacing: 0;
            line-height: 20px;
            text-align: center
        }
    }

    @include md-max {
        &-wrapper {
            padding-left: 20px;
            padding-right: 20px;
            flex-wrap: wrap;
        }

        &-logo-two {
        }

        &-title {
            font-size: 24px;
            letter-spacing: 0;
            line-height: 32px;
            text-align: center;
        }

        &-title-buttons-wrapper {
            flex: 1;
            margin-top: 32px;
        }

        &-buttons-wrapper {
            justify-content: space-between;
        }

        &2 {
            .public-scoresheet-header-wrapper {
                padding-top: 0px;
            }

            .public-scoresheet-header-title-buttons-wrapper {
                margin-top: 10px;
            }
            .public-scoresheet-header-buttons-wrapper {
                margin-top: 10px;
            }
            .public-scoresheet-header-logo-wrapper {
                display: none;
            }
        }
    }
}