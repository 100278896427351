@import '../../../shared/styles/main.scss';

.panel-management-container {
  &__content {
    max-width: 940px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 18px;
  }

  &__add {
    display: flex;
    justify-content: space-between;

    .title {
      color: #333333;
      font-family: Roboto;
      font-size: 30px;
      letter-spacing: 0;
      line-height: 38px;
    }
  }


}