@import '../../../../shared/styles/main.scss';


.match-scoresheet-form {
  // .ant-radio-button-wrapper {
  //   border-radius: 0 !important;

  // }

  &__radio-wrapper {
    margin-left: auto;
    flex-wrap: wrap;
    justify-content: flex-start;
    display: flex;
    align-items: flex-start;
    .ant-radio-button-wrapper {
      padding: 10px 20px !important;
      min-height: 40px !important;
      &:first-child {
      border-radius: 0;
      }
      &:last-child {
        border-radius: 0;
      }
  }

  .ant-radio-button-wrapper-disabled {
    color: rgba(0,0,0,.25);
  }
    .ant-radio-button-wrapper-checked {
      color: $white;
      background: $endeavor-blue !important;
      border-color: $endeavor-blue !important;
    }


    .ant-radio-button-wrapper-disabled, .ant-radio-button-wrapper-disabled:hover {
      // color: rgba(0, 0, 0, 0.25);
      background-color: #f5f5f5;
      border-color: #d9d9d9;
    }
  }
  &__radio-btn {
    line-height: 20px !important;
    color: $charcoal-gray;
    // border: 1px solid #C1C1C1;
    // background-color: #FFFFFF;
    // overflow: hidden;
    white-space: nowrap;
    font-size: 1.6rem !important;
    margin-bottom: 12px;
    padding: 10px 30px !important;

    &:not(:first-child) {
      margin-left: 12px;
    }
  }
}