.dashboard-layout {
  background-image: url('../../../../../public/backgrounds/first-season.png');
  background-repeat: no-repeat;
  background-position: top;
  background-size: 150%;
  background-position-y: -200px;
  background-color: black;

  // &__hero {
  //   height: 550px;
  //   z-index: -1;
  //   position: absolute;
  //   width: 100%;
  //   top: 0;
  //   background-position: center;
  //   background-repeat: no-repeat;
  //   background-size: cover;
  //   background: radial-gradient(circle, #00648F 0%, #003C66 100%);
  // }

  &__hero-image {
    position: absolute;
    top: 0;
    background-image: url('../../../../../public/backgrounds/first-season.png');
    width: 100%;
    min-height: 500px;
    margin-top: 100px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
  }

  &__content {
    position: relative;
    max-width: 958px;
    margin: 0 auto 0px;
    background-color: transparent;
    padding-bottom: 61px;
  }

  &__sponsor-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &__sponsor-logo {
    margin-top: 14px;
    height: auto;
    width: 300px;
    margin-bottom: 21px;
    // position: absolute;
    // top: 24px;
    // right: 0;
  }
}
