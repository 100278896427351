@import '../../../../shared/styles/_color.scss';

.autocomplete {
  .ant-select-selection__rendered {
    line-height: 48px !important;
  }
  
  .ant-input {
    border-radius: 0 !important;
    color: $charcoal-gray;
    height: 48px !important;
    font-size: 1.6rem;
  }
}
