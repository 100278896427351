.event-overview-schedule {
  max-width: 820px;
  margin: 60px auto;
  margin-top: 20px;
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    .ant-select-disabled .ant-select-selection {
      cursor: initial;
    }
  }

  &__dropdown {
    width: 234px;
  }

  .schedule_matches_judging_button {
    height: unset;
    padding: 6px 43px;
    color: #0066B3;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;
    border: 1px solid #0066B3;
    border-radius: 4px;
    background-color: transparent;
  }
}