@import '../../../shared/components/Heading/heading.scss';
@import '../../../shared/styles/_color.scss';
@import '../../../shared/styles/_space.scss';
@import '../../../shared/styles/_font.scss';
@import '../../../shared/styles/main.scss';

.volunteer-dashboard {
  background-color: $white;
  padding: 39px 47px;
  margin-top: -3px;
  border-radius: 12px;
  min-height: 800px;

  &__teams {
    margin-bottom: 46px;
  }

  &__teams-heading {
    margin-bottom: 18px;
    height: 32px;
    color: #333333;
    font-family: Roboto;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 32px;
    font-weight: normal;
  }
  
  &__search-bar-left {
    margin-bottom: 17.5px;
  }
  
  @include md-max {
    .mobile-compatible {
      min-width: 100px;
    }
  }
  
}