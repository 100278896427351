.c-pageloading {
  position: fixed;
  top: 60px;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding-bottom: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(rgba(255, 255, 255, 0.1) 0%, rgba(3, 57, 95, 0.3) 100%);
  z-index: 1001;
}
