@import '../../../shared/components/Paragraph/paragraph.scss';
@import '../../../shared/styles/_breakpoints.scss';
@import '../../../shared/components/RangePicker/RangePicker.scss';
@import '../../../shared/components/TimePicker/TimePicker.scss';
@import '../../../shared/styles/_color.scss';

.panel-select-dropdown {
  width: 120px;
  margin-top: 15px;
}

.panel-select-dropdown-lg {
  width: 150px;
  margin-top: 15px;
}

.panel-select-dropdown .ant-select-arrow .ant-select-arrow-icon {
  color: #0166b3;
  font-size: 14px;
}

.selected-panel-wrapper {
  display: flex;
  align-items: flex-start;
}

.panel {
  display: inline-block;
  margin-top: 5px;
  margin-right: 10px;
  background-color: #f4f4f4;
  border-radius: 36px;
  padding: 4px 24px;
  position: relative;

  .remove {
    position: absolute;
    top: 0;
    right: 5px;
  }

  .remove:hover {
    cursor: pointer;
  }

  .title {
    color: #333333;
    font-family: Roboto;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
    white-space: nowrap;
  }

  .subtitle {
    color: #333333;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;

    .addition {
      color: #1890ff;
    }
  }
}

.session-panel-users {
  border-radius: 36px;
  background-color: #f4f4f4;
  min-height: 72px;
  display: flex;
  align-items: center;
  padding-left: 32px;
  padding-right: 70px;

  &__title {
    color: #333333;
    font-family: Roboto;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 32px;
    white-space: nowrap;
  }

  &__subtitle {
    color: #333333;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    margin-left: 50px;
  }

  &__content {
    margin-left: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    // justify-content: space-evenly;
    width: 100%;
    overflow: auto;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
      width: 10px;
    }

    &::-webkit-scrollbar:horizontal {
      height: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.35);
      border-radius: 5px;
      border: 1px solid #ffffff;
    }

    &::-webkit-scrollbar-track {
      border-radius: 5px;
      background-color: #ffffff;
    }
  }
  &__user {
    margin-right: 70px;
  }
}

.referee-assignment-panel-user-row {
  margin-top: 16px;
  margin-bottom: 50px;
  .session-panel-users {
    border-radius: 8px;
  }
}
