@import '../../../shared/styles/_breakpoints.scss';

.sort-dropdown .ant-select-selection__placeholder{
  color: #333333 !important;
}

.admin-dashboard {
  padding: 20px;
  background-color: white;
  min-height: 800px;

  &__logo {
    height: 40px;
    margin-right: 16px;
  }
  &__list {
    padding: 30px 0;
    @include md {
      padding: 30px 40px;
    }
  }

  &__grid {
    flex-direction: column;
    align-content: center;
    display: flex;
    align-items: center;
    // margin: -12px;
    // max-width: 842px;
    position: relative;
    // overflow: hidden;
    @include md {
      // justify-content: space-between;
      // justify-content: center;
      flex-wrap: wrap;
      flex-direction: row;
      
    }

    // @include xl {
    //   justify-content: flex-start;
    // }

  }

  &__title-sort {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;
    flex-direction: column;
    // max-width: 830px;
    // max-width: 826px;
    @include md {
      justify-content: space-between;
      flex-wrap: wrap;
      flex-direction: row;
      
    }
    .title-sort__title {
      height: 32px;
      color: #333333;
      font-family: Roboto;
      font-size: 38px;
      letter-spacing: 0;
      line-height: 32px;
      font-weight: normal;
    }

  }
  &__search-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-left {
      display: flex;
      align-items: center;
      > svg {
        cursor: pointer;
        margin-left: 12px;
      }
      .input {
        border: none !important;
        border-radius: 4px !important;
      }
      .ant-input {
        border: 1px solid #f8f8f8;
        // outline: none;
        // box-shadow: none;
      }
    }
  }

  &__edit-modal-buttons {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

