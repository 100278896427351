
// Mobile Devices
$screen-xs-min: 480px;

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;

//max screen with 768px
$screen-md-max: 768px;

@mixin xs-max {
  @media (max-width: #{$screen-sm-min}) {
    @content;
}
}

// Small devices
@mixin xs {
  @media (min-width: #{$screen-xs-min}) {
      @content;
  }
}

// Small devices
@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
      @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) {
      @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
      @content;
  }
}

@mixin lg-max {
  @media (max-width: #{$screen-lg-min}) {
    @content;
}
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
      @content;
  }
}

@mixin custom-bp($screen) {
  @media (min-width: $screen+'px') {
    @content; 
  }
}

@mixin md-max {
  @media only screen and (max-width:  #{$screen-md-max}) {
      @content;
  }
}

$eventhub_logo_height: 111px;
$eventhub_footer_logo_height: 111px;
$eventhub_footer_logo_width: 403px;
$eventhub_header_logo_height_mobile: 70px;
$eventhub_logo_height_mobile: 91px;
$eventhub_logo_width_mobile: 330px;
