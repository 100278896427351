.confirmation-modal {

  &__heading-wrapper {
    text-align: center;
    margin-bottom: 36px;
  }
  max-width: 345px;
  .ant-modal-content {
    border-radius: 0;
  }


  &__btn-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

}