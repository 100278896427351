@import '../../shared/styles/_breakpoints.scss';

.public-leaderboard {
    position: relative;
    .header__wrapper {
        max-width: unset;
        padding: 18px 24px 18px 30px;
        position: relative;
    }
    .leaderboard-title {
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
        color: #0066B3;
        font-family: Roboto;
        font-size: 38px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 46px;
    }
    .registration-page-layout__footer {
        max-width: unset;
        padding: 18px 24px 18px 30px;
    }

    &-wrapper {
        min-height: 100%;
        background-image: url('../../../public/backgrounds/first-season.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position-x: right;
        width: 100%;
        padding-bottom: 50px;

        .fullscreen-link {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 20px 42px 0 42px;

            button {
                span {
                    font-size: 18px;
                }
            }
            .auto-scorll-toggler {
                display: flex;
                align-items: center;
                .switch-title {
                    color: #1890ff;
                    font-size: 18px;
                    font-weight: 400;
                    margin-right: 10px;
                    &.unchecked {
                        color: #AEACAE;
                    }
                }
                .ant-switch {
                    background-color: #AEACAE;
                }
                .ant-switch-checked {
                    background-color: #1890ff;
                }
            }
        }
    
        &-header {
            position: relative;
            background-color: transparent;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin: 0px 42px;
            padding-top: 20px;
            align-items: center;
            @include lg-max {
                flex-direction: column;
            }
            .left-shape {
                background-size: cover;
                background-repeat: no-repeat;
                position: absolute;
                background-position-x: right;
              }
            
            .right-shape {
                background-size: cover;
                background-repeat: no-repeat;
                background-position-x: left;
                position: absolute;
            }
            .title-wrapper {
                color: #FFFFFF;
                font-family: Roboto;
                text-align: center;
                .title {
                    font-size: 30px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 38px;
                }
                .subtitle {
                    margin-top: 11px;
                    font-size: 18px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 21px;
                }
            }
            .dashboard-layout__sponsor-logo {
                height: 80px;
                width: unset;
            }
            .dashboard-layout__first-logo {
                height: unset;
                width: 277px;
            }
            .c-event-program-logos {
                height: 80px;
            }
            .c-event-program-logos .c-event-fll-current-logo {
                height: 80px;
                margin-top: 0px;
                margin-right: 0px;
            }
            .c-event-program-logos svg:nth-child(2) {
                height: 80px;
                width: 93px;
            }
        }
        .table-wrapper {
            margin: 23px 42px;
        }

        .sponsor-logos-wrapper {
            margin: 23px 42px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .sponsors-logos {
                width: 49%;
                height: 169px;
                border-radius: 12px;
                background-color: #FFFFFF;
                display: flex;
                flex-direction: row;
                align-items: center;
                &.fullsize {
                    width: 100%;
                }
                .inner-wrapper {
                    margin: 32px;
                    height: 45px;
                    width: 100%;
                    overflow: hidden;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    .left-wrapper-animation, .right-wrapper-animation {
                        background-color: #FFFFFF;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        img {
                            height: 45px;
                            margin-right: 0px !important;
                            margin-left: 0px !important;
                            padding-right: 42px;
                        }
                        .c-event-sponsors-list__no-logo {
                            height: 45px;
                            width: unset;
                            margin-right: 0px !important;
                            margin-left: 0px !important;
                            padding-right: 42px;
                            svg {
                                height: 45px;
                            }
                        }
                    }
                }
            }
        }
    }

    &-table {
        thead {
            tr {
                border: 0px solid transparent;
                th {
                    background-color: #727272 !important;
                    color: white !important;
                    font-family: Roboto;
                    font-size: 16px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 22px;
                    border-bottom: 0px solid transparent !important;
                }

                & > th:not(:nth-child(2)) {
                    text-align: center;
                }
            }
        }

        tbody {
            tr {
                background-color: #fff;
                color: #333333;
                font-family: Roboto;
                font-size: 20px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 28px;
                & > td {
                    border: 0px solid transparent;
                    border-bottom: 1px solid rgb(4, 8, 32);
                }

                & > td:not(:nth-child(2)) {
                    text-align: center;
                }
                & > td:first-child {
                    background-color: #0066B3;
                    color: white;
                }
                & > td:nth-child(3) {
                    background-color: #EBEAEB;
                }
            }
        }
    }
}