
.optional-awards {
    .label {
        color: #333333;
        font-family: Roboto;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 22px;
    }
    .awards-label {
        color: #333333;
        font-family: Roboto;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 22px;
    }

    .ant-checkbox-disabled {
        .ant-checkbox-inner {
          border: 1px solid #D2D2D2 !important;
          border-radius: 2px !important;
          background-color: #F5F5F5 !important;
        }
      }
  
      .ant-checkbox-checked.ant-checkbox-disabled {
        .ant-checkbox-inner {
          background-color: #AEACAE !important;
          border: none !important;
        }
      }
}