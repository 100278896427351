@import '../../../shared/styles/main.scss';

.missing-panel-judge {
  display: flex;
  flex-direction: column;

  

  @include md {
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }

  &__icon {
    color: $red-primary;
    height: 39px;
    width: 39px;
    margin-right: 15px;
  }

  &__text {
    max-width: 223px;
    overflow: hidden;
  }
}