@import '../../../shared/components/Modal/Modal.scss';
@import '../../../shared/styles/_space.scss';
@import '../../../shared/styles/_breakpoints.scss';

.width-300{
  width: 300px;
}

.max-width-940{
  max-width: 940px !important;
}


.teams-coaches-modal {
  &-wrapper {
    position: relative;
    .disabled-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(255,255,255,0.65);
    }
  }

  .ant-modal-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .ant-modal-close {

    &:focus {
      outline: 1px solid $white !important;
    }
  }

  .ant-modal .ant-modal-close {
    color: white !important;
    font-weight: bolder;
  }
  .ant-modal-content {
    height: auto;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    padding: 0;
    @include md {
      // height: 680px;
    }
  }
  .ant-modal-header {
    border-bottom: none;
    min-height: 60px;
    color: white;
    border-radius: 0 !important;
    background: radial-gradient(circle, #00648F 0%, #003C66 100%);
    display: flex;
    position: relative;
    align-items: center;
    padding: 0 100px 0 24px;
  }
  &__title {
    max-width: 940px;
    margin: auto;
    margin-top: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    &__link {
      display: flex;
      align-items: center;
      &__icon {
        margin-right: 12px;
      }
    }
    
  }

  &__top-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    .switcher-control {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-radius: 4px;
      background-color: #0066B3;
      border: 1px solid #0066B3;
      height: 40px;
      .switch-button {
        width: 250px;
        height: 40px;
        padding: 10px 18px;
        font-family: Roboto;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 22px;
        text-align: center;
        border: none;
        cursor: pointer;
        border-radius: 4px;
        &.active {
          background-color: #0066B3;
          color: #FFFFFF;
        }
        &.inactive {
          height: 38px;
          background-color: #FFFFFF;
          color: #0066B3;
        }
      }
      @include xs-max {
        height: unset;
        .switch-button {
          height: unset;
          width: unset;
          &.inactive {
            height: unset;
          }
        }
      }
    }
    @include md-max {
      flex-direction: column;
      width: unset;
    }
    .invite-language {
      align-items: flex-end;
      display: flex;
      flex-direction: column;
      @include md-max {
        margin-top: 10px;
        align-items: center;
        flex-direction: row;
      } 
    }
  }
  .ant-tabs {
    max-width: 940px;
    margin: auto;
  }

  &__manual-buttons-wrapper {
    margin-top: 36px;
    display: flex;
    flex-direction: column;
    @include xs {
      justify-content: space-between !important;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
    }
    .ant-form-item {
      margin-bottom: 0 !important;
    }
  }

  // &__manual-row {
  //   margin-left: -10px;
  //   margin-right: -10px;
  //   display: flex;
  // }
  &__manual-col {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;

    @include md {
      width: 34%;
      padding-left: 10px;
      padding-right: 10px;
    }

    &--middle {
      width: 100%;

      @include md {
        width: 30%;
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }

  &__manual-invite-list {
    height: 344px;
    border: solid 1px $alto-gray;
    margin-top: 12px;
    
    &--invitees {
      background-color: $white;
      margin: 20px 5px 12px 2px;
      padding: 20px;
      box-shadow: 0 2px 8px 0 rgba(0,0,0,0.15);
      overflow-y: auto;
      height: 296px;
      padding: 20px;
    }

    &-container {
      margin-top: 40px;
    }

    &__btn-wrapper--outer {
      display: flex;
      margin-top: 36px;
    }
    &__btn-wrapper--inner {
      margin-left: auto;
    }
  }

  @include md-max {
    .ant-modal {
      width: 100% !important;
      margin: 0px;
      max-width: 100%;
      top: 0px;
      bottom: 0px;
      padding-bottom: 0px;
    }

    .ant-modal-header {
      display: none;
    }
  
    .modal__close-btn {
      color: #4B4B4B !important;
    }
  }

  .manual-form {
    &-top-section{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      min-height: 400px;
      @include lg-max {
        flex-direction: column;
      }
      .colum-wrapper {
        display: flex;
        flex-direction: column;
      }
      .add-teams-coaches-container {
        &__switcher {
          margin-bottom: 10px;
          width: 430px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          @include xs-max {
            flex-direction: column;
            align-items: flex-start;
          }
    
          button {
            height: 48px;
            width: 180px;
            display: flex;
            align-items: center;
            justify-content: center;
    
            color: #0066B3;
            font-family: Roboto;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 22px;
            text-align: center;
            &.outlined-btn {
              border: 2px solid #1890FF;
              background-color: #FFFFFF;
              &.disabled {
                opacity: 0.4;
              }
            }
            &.primary-btn {
              color: #FFFFFF;
              border-radius: 4px;
              background-color: #0066B3;
              &.disabled {
                opacity: 0.4;
              }
            }
          }
    
          .or-text {
            margin-top: 13px;
            margin-bottom: 13px;
            color: #333333;
            font-family: Roboto;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 22px;
          }
        }

        &__search {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 440px;
          button {
            margin-top: 6px;
            padding: 0px;
          }
          .ant-select-show-search {
            height: 48px;
            width: 384px;
            border: 2px solid #1890FF;
            background-color: #FFFFFF;
            input {
              height: 48px;
              width: 384px;
              border: none !important;
              color: #333333;
              font-family: Roboto;
              font-size: 16px;
              letter-spacing: 0;
              line-height: 22px;
              -webkit-box-shadow: none;
              box-shadow: none;
            }
            .ant-select-selection {
              height: 100%;
              &__placeholder {
                color: #0066B3;
                font-family: Roboto;
                font-size: 16px;
                letter-spacing: 0;
                line-height: 22px;
                vertical-align: center;
              }
            }

            @include xs-max {
              width: unset;
              input {
                width: unset;
              }
            }
          }
        }

        &__create {
          width: 440px;
          .create__title {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .title {
              color: #333333;
              font-family: Roboto;
              font-size: 16px;
              font-weight: bold;
              letter-spacing: 0;
              line-height: 22px;
            }
            button {
              padding: 0px !important;
            }
          }
          .create-team {
            width: 440px;
            padding: 32px 20px 28px 20px;
            background-color: #E6F7FF;
            border: 1px solid #1890FF;
            .form .ant-form-item {
              margin-bottom: 28px !important;
              &:last-child {
                margin-bottom: 0px !important;
              }
            }
            .add-button {
              display: flex;
              justify-content: flex-end;
            }
          }

          @include xs-max {
            width: unset;
            .create-team {
              width: unset;
            }
          }
        }

        &__create, &__search, &__switcher {
          position: relative;
          .disabled-overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(255,255,255,0.65);
          }
        }
      }
    }

    &-selection {
      margin-top: 30px;
      margin-bottom: 30px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .team-list, .coach-list {
        width: 440px;
        @include xs-max {
          width: unset;
        }
      }

      .list-item {
        margin-bottom: 6px;
        height: 48px;
        width: 440px;
        padding: 0px 14px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: #E6F7FF;
        border: 1px solid #1890FF;
        .text {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 380;
          color: #333333;
          font-family: Roboto;
          font-size: 16px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 22px;
        }
        @include xs-max {
          width: 100%;
        }
      }

      @include lg-max {
        flex-direction: column;
      }
    }

    .bottom-btn-wrapper {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      .add-primary-btn {
        height: 48px;
        width: 140px;
        border-radius: 4px;
        background-color: #0066B3;
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 22px;
        text-align: center;
        &.disabled {
          opacity: 0.4;
        }
      }
    }
  }
}
#search-teams-autocomplete {
  .ant-select-dropdown {
    width: 500px !important;
    max-height: 295px !important;
    padding: 25px 21px 26px 10px;
  
    .ant-select-dropdown-menu-item {
      padding: 6px !important;
      margin: 0px !important;
  
      &.ant-select-dropdown-menu-item-selected {
        background-color: #E5EFF7;
        font-weight: normal;
      }
  
      .add-team-modal__select-option {
        justify-content: space-between;
        span {
          margin: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #333333;
          font-family: Roboto;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 20px;
        }
        span:first-child {
          width: 50px;
          text-align: end;
        }
        span:nth-child(2){
          width: 150px;
          font-weight: bold;
        }
        span:nth-child(3){
          width: 80px;
        }
        span:nth-child(4){
          width: 95px;
        }
        span:nth-child(5){
          width: 16px;
        }
      }
      .user-autocomplete-option {
        justify-content: space-between;
        span {
          margin: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #333333;
          font-family: Roboto;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 20px;
        }
        span:first-child {
          width: 120px;
        }
        span:nth-child(2){
          width: 160px;
        }
        span:nth-child(3){
          width: 92px;
        }
        span:nth-child(4){
          width: 16px;
        }
      }
    }
    @include xs-max {
      width: unset !important;
    }
    ul {
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: #C6C4C6;
      }
    }
  }

  .ant-select-search__field__wrap {
    height: 44px !important;
    display: flex;
    flex-direction: row;
    input {
      align-self: center;
      height: 44px !important;
      width: 380px !important;
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }
  }
  .ant-select-selection__clear {
    height: 18px;
    width: 18px;
    margin-top: -9px;
    .anticon-close-circle {
      font-size: 18px;
    }
  }
}