@import '../../../shared/styles/_space.scss';
@import '../../../shared/styles/_breakpoints.scss';
@import '../../../shared/styles/_color.scss';
@import '../../../shared/components/Link/Link.scss';

.teams-coaches-import {
  margin-top: 40px;
  min-height: 500px;


  &__row {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @include md {
      flex-direction: row;
    }
  }
  &__choose-file {
    margin-top: 20px;
    margin-left: 0px;
    @include md {
      margin-left: 20px;
      margin-top: 0px;      
    }
  }

  &__list {
    background-color: #FFFFFF;
    // margin: 20px 5px 12px 5px;
    margin-top: 20px;
    padding: 24px 16px 16px 16px;
    // box-shadow: 0 2px 8px 0 rgba(0,0,0,0.15);
    overflow-y: auto;
    max-height: 377px;
    border: 2px solid #1890FF;

    &--errors {
      border: 2px solid #F5222D !important;
    }
    
    &-wrapper {
      margin-top: 24px;
    }

  }

}