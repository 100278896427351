@import '../../../shared/styles/_breakpoints.scss';
.page-layout {
  min-height: 60vh;
  &__hero {
    height: 420px;
    z-index: -1;
    position: absolute;
    width: 100%;
    top: 0;
  }
  &__content {
    background-color: transparent;
    background-image: url('../../../../public/backgrounds/first-season.png');
    background-position: top;
    background-size: 120%;
  }
}