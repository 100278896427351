@import '../../../shared/styles/_breakpoints.scss';

.title-sort-row {
  margin-bottom: 18px;

  &__title {
    display: flex;     
    display: -webkit-flex; 
    align-items: center;
    flex-wrap: wrap;
    @include xs {
      flex-wrap: nowrap;
    }
  }
}