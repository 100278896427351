@import '../../../../shared/styles/main.scss';

.registration-page-layout {
  overflow: hidden;

  &__container {
    position: relative;
    min-height: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url('../../../../../public/backgrounds/first-season.png');
    background-position-x: center;
    background-position-y: top;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 70px 20px;
    @include xs {
      padding: 71px;
    }

    &.logged-in {
      background-image: url('../../../../../public/backgrounds/first-season.png');
    }
  }

  &__triangle {
    position: absolute;
    height: 197px;
    width: 304px;
    background-repeat: no-repeat;
    background-size: cover;
    right: -30px;
    bottom: -20px
  }

  &__hero {
    height: 100%;
    background: linear-gradient(0deg, #091942 0%, #0282B1 100%);
    z-index: -1;
    position: absolute;
    width: 100%;
    top: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__invited {
    text-align: center;
    margin-bottom: 12px;
  }

  &__image {
    background-image: url('../../../../../public/audience-cheering.png');;
    z-index: 3;
    width: 100%;
    height: 610px;
    margin-top: 150px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    &-bottom {
      height: calc(100% - 760px);
      background: #091942;
    }
    &-footer {
      height: calc(100% - 710px);
      background: #091942;
    }
  }

  &__logo {
    z-index: 2;
    display: flex;
    justify-content: center;
    padding-top: 14px;
    padding-bottom: 9px;
    background-color: white;
  }

  &__logo_wrapper {
    display: flex;
    flex-direction: column;
  }

  &__header_logo {
    height: $eventhub_logo_height_mobile;
    @include xs {
      height: $eventhub_logo_height;
    }
  }

  &__error {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    background-color: #FFFBE6;
    padding: 22px 32px;
    margin-bottom: 16px;
    position: relative;
    justify-content: space-between;
    .icon-close {
      cursor: pointer;
      color: #d6d6d6;
      position: absolute;
      top: 16px;
      right: 16px;
      &:hover {
        color: #a7a7a7;
      }
    }
  }

  &__form {
    margin: 0 auto;
    background-color: white;
    border: 1px solid #DDDDDD;
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.2);
    max-width: 600px;
    width: 100%;
    padding: 38px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    a {
      color: #0066B3;
    }
    > h1 {
      text-align: center;
    }
    @include xs {
      padding: 38px 75px 48px;
      > h1 {
        text-align: initial;
      }
    }
  }

  &__options {
    width: 100%;
    padding: 25px;
    background-color: #F4F4F4;
    display: flex;
    flex-direction: column;
    align-items: center;
    > div {
      width: 100%;
      background-color: white;
      border: 1px solid #C1C1C1;
      height: 48px;
      padding-top: 14px;
      text-align: center;
      cursor: pointer;
      &:hover {
        border: 1px solid #1890FF;
        > p {
          color: #1890FF;
        }
      }
    }
    > p {
      margin: 4px 0;
    }
  }

  &__footer-container {
    background-color: #F4F4F4;
    padding: 55px 0 57px 0;
  }

  &__footer {
    max-width: 940px;
    margin: 0 auto;
  }

  &__footer-select {
    min-width: 200px;
  }

  &__footer-logo-container {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    flex-direction: column;
  }

  &__footer-logo {
    height: $eventhub_logo_height_mobile;
    width: $eventhub_logo_width_mobile;
    @include xs {
      height: $eventhub_footer_logo_height;
      width: $eventhub_footer_logo_width;
    }
  }

  &__footer-content {
    display: flex;
    margin-top: 36px;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__footer-description {
    max-width: 780px;
  }

  @include md-max {
    &__footer {
      max-width: unset;
      padding: 0 15px;
    }

    &__footer-logo {
      height: $eventhub_logo_height_mobile;
    }
  }

  .react-pdf__Page__textContent {
    top: 0 !important;
    left: 0 !important;
    transform: unset !important;
    & span {
      color: transparent !important;
      &::selection {
        color: transparent !important;
        background-color: rgba(24,144,255,0.2) !important;
      }
    }
  }
}
