@import '../../../shared/styles/main.scss';

.c-session-status {
  min-height: 32px;
  max-width: 50%;
  // max-width: 180px;
  min-height: 32px;
  /* max-width: 50%; */
  max-width: 250px;
  border-radius: 16px;
  background-color: #EDF9E8;
  display: inline-block;
  padding: 5px 10px;
  position: relative;

  &__badge {
    height: 10px;
    width: 10px;
    border-radius: 50% !important;
    &--hollow-green {
      &:before {
        position: absolute;
        content: '';
        top: 10px;
        left: 10px;
        width: 10px;
        height: 10px;
        background-color: #52C41A;
        border-radius: 50%;
      }
    }
  
    &--solid-green {
      &:before {
        position: absolute;
        content: '';
        top: 10px;
        left: 10px;
        width: 10px;
        height: 10px;
        background-color: #52C41A;
        border-radius: 50%;
      }
    }
  
    &--solid-orange {
      &:before {
        position: absolute;
        content: '';
        top: 10px;
        left: 10px;
        width: 10px;
        height: 10px;
        background-color: #FAAD14;
        border-radius: 50%;
      }
    }
  
    &--solid-blue {
      &:before {
        position: absolute;
        content: '';
        top: 10px;
        left: 10px;
        width: 10px;
        height: 10px;
        background-color: $endeavor-blue;
        border-radius: 50%;
      }
    }
    
  }
  
  &-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }


  &--hollow-green {
    background-color: #EDF9E8;;
  }

  &--solid-green {
    background-color: #EDF9E8;
  }

  &--solid-orange {
    background-color: #FFFBE6;
  }

  &--solid-blue {
    background-color: #B2D1E9;
  }
}