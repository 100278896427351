@import '../../../shared/styles/main.scss';

.event-overview-container {
  background-color: $white;
  min-height: 600px;
  max-width: 960px;
  margin: auto;

  &__top-section {
    background-color: $sand-gray;
    padding: 20px 60px 30px;

    &__text {
      max-width: 820px;
      margin: 0 auto;
    }

    &__description {
      margin-top: 12px;
      // @include md {
      //   max-width: 75%;
      // }
    }

    &__trademark {
      top: -.6em;
      position: relative;
      font-size: 1.4rem;
      line-height: 0;
      vertical-align: baseline;
    }

    &__link {
      background-color: $white;
      padding: 12px 40px;
      width: fit-content;
      margin-top: 20px;
    }
  } 

  &__back {
    max-width: 920px;
    // padding-bottom: 18px;
    margin-left: -13px;
  }
  @include md-max {
    padding: 0px 15px;
  }
}