@import '../../../../shared/styles/main.scss';

.event-timestamp-modal {
  max-width: 940px;
  margin: auto;

  &__form {
    // max-width: 460px;
    width: 100%;
  }

  &__rectangle {
    margin-right: 24px;
    margin-top: 20px;
    display: flex;
    width: 65px;
    background-color: #03D2E2;
    align-items: center;
    justify-content: center;
    height: 146px;

    &--opening {
      background-color: $endeavor-blue;
    }
    &--trophy {
      background-color: $endeavor-blue;
    }
    &--judging {
      background-color: $session-navy-blue;
    }
    &--scoring {
      background-color: $session-turquoise;
    }
    &--score {
      background-color: $session-turquoise;
    }
  }
}