@import '../../../shared/components/Paragraph/paragraph.scss';
@import '../../../shared/styles/_space.scss';
@import '../../../shared/styles/_breakpoints.scss';
@import '../../../shared/styles/_color.scss';
@import '../../../shared/components/Link/Link.scss';

.add-team-accordion {
  margin-top: 46px;
  .ant-collapse {
    border-radius: 0px !important;
    background-color: $white;
  }

  &__link-container {
    display: flex;
  }
  &__link {
    margin-left: auto;
    padding: 0;
    color: $endeavor-blue;
  }

  &__panel {
    background: rgb(237, 249, 232);
    border-radius: 0px;
    margin-bottom: 24px;
    border: 0px;
    overflow: hidden;
    padding: 10px;
  }

}