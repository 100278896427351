@import '../../../../shared/styles/main.scss';

.c-judging-rubric-header {
  &__nav {
    margin-top: 24px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include sm {
      flex-direction: row;
    }
  }

  &__btn-wrapper {
    display: flex;
    flex-direction: column;
    @include sm {
      flex-direction: row;
    }

    @include md-max {
      flex-direction: row;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      align-items: center;
      justify-content: space-around;
      background: #DDDCDD;
      z-index: 2;
      padding: 15px;
    }
  }

  &__exit-btn {
    margin-bottom: 14px;
    @include sm {
      margin-bottom: 0;
      margin-right: 14px; 
    }
    @include md-max {
      margin-bottom: 0;
      margin-right: 14px;
    }
  }

  &__title {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    margin-bottom: 76px;
    margin-top: 12px;
    @include md-max {
      margin-bottom: 24px;
      margin-top: 6px;
      .h-2 {
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 28px;
      }
    }
  }

  &__button-tooltip {
    border-color: white;
    border-radius: 4px;
    background-color: #FFFFFF;
    padding: 12px;

    & > span {
      color: #333333;
      font-family: Roboto;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
    }
  }

  &__sumitted {
    height: 48px;
    padding: 12px 50px 12px 40px;
    border-radius: 24px;
    background-color: #52C41A;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      color: white;
      margin-right: 8px;
    }

    span {
      color: #FFFFFF;
      font-family: Roboto;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 22px;
      text-align: center;
    }
  }
}

.c-judging-rubric-header-tooltip {

  .ant-tooltip-arrow::before{
    background-color: white;
    width: 10px;
    height: 10px;
  }

  .ant-tooltip-inner {
    color: white;
    background-color: white;
  }

}

@include md-max {
  .registration-page-layout__footer {
    margin-bottom: 78px;
  }
}