@import '../../../../shared/styles/_color.scss';

.modal-tabs {
  .ant-tabs-ink-bar {
    height: 4px;
    background: $endeavor-blue;
  }

  .ant-tabs-nav .ant-tabs-tab {
    margin: 0px !important;
    padding: 12px 24px;
    text-align: center;
    min-width: 134px;
    color: $endeavor-blue;
    font-size: 1.6rem;
  }

  // .ant-tabs-extra-content {
  //   float: right;
  //   top: 0;
  //   position: absolute;
  //   top: 8px;
  //   right: 0;
  //   padding-left: 8px;
  //   z-index: 100;
  // }

  .ant-tabs-tab-active {
    color: $charcoal-gray !important;
    font-size: 1.6rem;
    font-weight: normal !important;
    &:hover {
      color: $endeavor-blue;
    }
  }

  // .ant-tabs-bar {
  //   margin: 0 0 0 0;
  //   position: relative;
  // }
}
