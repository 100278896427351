.divider {
  &--vertical {
    height: 100%;
  }

  &--horizontal {
    width: 100%;
    margin: 8px 0;
  }
}
