@import '../../../../shared/styles/main.scss';
@import '../button.scss';

.btn-primary-container {
  button {
    height: unset;
    border-radius: 5px;
    // border-radius: 4px;
    // &:disabled {
    //   background-color: $endeavor-blue-disabled !important;
    //   color: $white !important;
    //   border-color: $endeavor-blue-disabled !important;
    // }
    // &[disabled] {
    //   background-color: $endeavor-blue-disabled !important;
    //   color: $white !important;
    //   border-color: $endeavor-blue-disabled !important;
    // }
  }
}


.btn-primary {
  background-color: $endeavor-blue;
  border-color: $endeavor-blue;
  &:hover {
    color: #fff;
    background-color: $aquamarine;
    border-color: $aquamarine;
  }
}