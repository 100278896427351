@import '../../../../shared/styles/_color.scss';
@import '../../../../shared/styles/_space.scss';

.event-tabs {

  .ant-tabs-nav-wrap, .ant-tabs-nav-list { 
    margin: auto;
  }

  .ant-tabs-tab {
    &:hover {
      // text-decoration: underline;
      color: $white;
    }
  }

  .ant-tabs-tab-prev, .ant-tabs-tab-next {
    color: $white;
  }
  .ant-tabs-ink-bar {
    background: transparent;
  }
  .ant-tabs-nav {
    color: $white;
    margin: 0;
  }
  .ant-tabs-nav .ant-tabs-tab {
    padding: 7px 18px;
    font-size: 1.6rem;
    margin: 0;
    
  }
  .ant-tabs-content {
    margin-top: 0;
    background: white;
    // padding-left: 8px;
    // padding-right: 8px;
  }
  .ant-tabs-content-holder {
    background: #f4f4f4;
  }
  .ant-tabs-extra-content {
    line-height: inherit;
  }
  .ant-tabs-tab-active {
    color: $charcoal-gray !important;
    font-size: 1.6rem;
    font-weight: bold;
    background-color: $white;
    &:hover {
      color: $endeavor-blue;
    }
  }

  .ant-tabs-bar {
    // margin: 0 0 26px 0;
    max-width: 956px;
    margin: auto;
    border-bottom: 0;
  }


}