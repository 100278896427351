@import '../../../shared/styles/_color.scss';
@import '../../../shared/styles/_font.scss';

.text-box {
  border-radius: 0 !important;
  font-size: 1.6rem;
  font-family: $roboto;
  color: $charcoal-gray !important;
  .ant-input {
    border-radius: 0 !important;
    font-family: $roboto;
    color: $charcoal-gray;
  }
  &:focus, &:hover {
    // border: 1px solid #1890FF;
    background-color: #FFFFFF;
    border-right-width: 1px !important;
    border-left-width: 1px !important;
    outline: none;
    box-shadow: none;
  }
}

.text-box--disabled {
  border-radius: 0;
  font-size: 1.6rem;
  border: 1px solid #EDEDED;
  background-color: #EDEDED !important;
  color: $charcoal-gray !important;
  &:hover {
    border: 1px solid #EDEDED !important;
  }
}
