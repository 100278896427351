@import '../../../../shared/styles/_space.scss';
@import '../../../../shared/styles/_breakpoints.scss';
@import '../../../../shared/styles/_color.scss';
@import '../../../../shared/components/Link/Link.scss';


.user-autocomplete-option {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  &__data {
    display: flex;
  }
  &__name {
    font-weight: 500;
    text-overflow: ellipsis;
    font-size: 1.4rem;
    white-space: nowrap;
    overflow: hidden;
    color: $charcoal-gray
  }
  &__avatar {
    margin-right: 12px;
  }
}