@import '../../../shared/styles/_breakpoints.scss';
.add-event-modal {
  margin-right: 50px;
  margin-left: 50px;
  margin-bottom: 50px;
  &__title {
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 22px;
  }

  &__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__content {
    position: relative;
    max-width: 780px;
    //margin: auto;
    margin-top: 36px;
    margin-bottom: 50px;
  }

  &__btn {
    padding: 13px 55px !important;
  }

  &__dates{
    margin-top: 48px;
  }

  .event-option-container {
    display: flex;
    margin-bottom: 5px;
  }
  
  .event-type {
    &-wrapper {
      margin-top: 26px;
      padding: 18px 30px;
      background-color: #F4F4F4;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include md-max {
       flex-direction: column;   
      }
    }

    &-title {
      &-wrapper {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
      }
      &-header {
        color: #333333;
        font-family: Roboto;
        font-size: 30px;
        letter-spacing: 0;
        line-height: 38px;
        text-align: center;
        margin-bottom: 4px;
      }
      &-hint {
        color: #333333;
        font-family: Roboto;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 22px;
      }
    }

    &-button-wrapper {
      display: flex;
      align-items: center;
      @include xs-max {
        flex-direction: column;
      }
    }

    &-remote-button, &-in-person-button, &-official-button, &-unofficial-button {
      padding-top: 13px;
      padding-bottom: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 220px;
      border: 1px solid #0066B3;
      border-radius: 4px;
      background-color: #FFFFFF;
      .button-title {
        position: relative;
        color: #0066B3;
        font-family: Roboto;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 22px;
        text-align: center;
        .title-icon {
          position: absolute;
          left: -32px;
        }
      }

      &.selected {
        background-color: #52C41A;
        border: none;

        .button-title {
          color: #FFFFFF;
        }
      }

      &.not-selected {
        border: 1px solid #C1C1C1;
        border-radius: 4px;

        span {
          color: #BBBBBB;
        }
      }
    }

    &-in-person-button, &-official-button {
      margin-right: 16px;
      @include xs-max {
        margin-top: 10px;
        margin-bottom: 10px;
        margin-right: unset;
      }
    }
  }
  .add-event-modal-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -100px;
    right: -100px;
    background-color: rgba(255,255,255, 0.7);
  }
  .timezone-error {
    label {
      color: #C50E14;
      font-family: Roboto;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 20px;
    }
    .ant-select-selection__placeholder {
      color: #C50E14;
      font-family: Roboto;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
    }
  }
}
