@import '../../../../shared/styles/main.scss';

.judging-results-feedback {
  border: 1px solid #EBEBEB;
  padding: 0 20px 0 20px;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  box-shadow: 0 3px 5px 0 rgba(0,0,0,0.1);

  .centered {
    align-self: center;
    text-align: center;
  }

  &-inner {
    min-height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    .event-name-location {
      color: #333333;
      font-family: Roboto;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
    }

  }

  &__rubric-form {
    text-align: center !important;

  }
  &__rubric-modal-btn {
    border-color: transparent;
    background: transparent;
    // min-width: 297px;
    min-height: 120px !important;
    border-radius: 0 !important;
    padding: 0 !important;
    cursor: pointer;
    border: none;
    outline:none;
    box-shadow: 0 3px 5px 0 rgba(0,0,0,0.1);

    &:hover {
      box-shadow: 1px 8px 8px 0 rgba(0,0,0,0.1);
    }
  }

  &__fll {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    max-width: 500px;
    text-align: center;
    margin: auto;
    &.centered {
      justify-content: center;
    }
  }

  @include md-max {
    border: none;

    &-inner {
      border: 1px solid #BFBFBF;
    }
  }
}

.judging-comments-title {
  margin: 18px 0px 8px 0px;
}

.judging-results-feedback-with-comments {
  border: 1px solid #EBEBEB;
  background-color: #FFFFFF;
  box-shadow: 0 3px 5px 0 rgba(0,0,0,0.1);
  height: 226px;
  padding: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .c-download-feedback-file {
    flex-direction: column;
    box-shadow: none;
    align-items: flex-start;
    padding: 0px;
    .c-rubric-upload__download-icons {
      padding-right: 0px;
      margin-bottom: 28px;
    }
  }

  &-inner {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__rubric-form {
    text-align: center !important;

  }
  &__rubric-modal-btn {
    border-color: transparent;
    background: transparent;
    // min-width: 297px;
    min-height: 120px !important;
    border-radius: 0 !important;
    padding: 0 !important;
    cursor: pointer;
    border: none;
    outline:none;
    box-shadow: 0 3px 5px 0 rgba(0,0,0,0.1);

    &:hover {
      box-shadow: 1px 8px 8px 0 rgba(0,0,0,0.1);
    }
  }

  &__fll {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    max-width: 500px;
    text-align: center;
    margin: 20px auto;
  }
  .results-and-feedback {
    .judging-results-feedback-inner {
      min-height: unset;
    }
  }
  @include md-max {

    border: 1px solid #BFBFBF;
    background-color: #FFFFFF;
    box-shadow: none;
    padding: 20px;
    height: unset;

    .results-and-feedback {
      .judging-results-feedback-inner {
        border: none;
      }
    }
  }
}

.empty-comment-placeholder {
  color: #333333;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
}

.judges-comments-wrapper {
  height: 226px;
  width: 404px;
  border: 1px solid #EBEBEB;
  box-shadow: 0 3px 5px 0 rgba(0,0,0,0.1);
  background-color: #FFFFFF;
  padding: 25px 25px 25px 25px;

  .judges-comments-content-wrapper {
    padding-bottom: 19px;
    border-bottom: 1px solid #DDDCDD;
  }

  .judges-comments-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
  }
  
  .judges-comments-content {
    width: 360px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }

  .judges-comments-see-more {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 18px;

    button {
      padding-right: 0px;
    }
  }
  @include md-max {
    width: unset;
    height: unset;
  }
}

.judges-comments-scroll-section {
  height: 600px;
  width: 540px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .judges-comments-content-wrapper {
    padding-bottom: 19px;
    border-bottom: 1px solid #DDDCDD;
    margin-bottom: 24px;
  }

  .judges-comments-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
  }
  
  .judges-comments-content {
    width: 490px;
  }
}

.judging-comments-meta-wrapper {
  display: flex;
  justify-content: space-between;
}

.with-margin-right{
  margin-right: 40px;
}