@import '../../../shared/styles/main.scss';

.referee-sessions-table .table-item-wrapper p {
  z-index: 1000 !important;
  white-space: nowrap;
}

.elipsis {
  white-space: nowrap;
  width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.public-link-button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;

  a {
    border-bottom: 1px solid #1890ff;
  }
}

.c-table {

  th,
  td {
    color: $charcoal-gray;
    font-family: $roboto;
  }

  .ant-table-thead {
    >tr>th {
      background-color: $white  !important;
      font-weight: normal;
      padding: 4px 16px;
      font-size: 12px;
      line-height: 20px;
    }

    svg {
      width: 10px;
      color: #0066B3;
      margin-left: 4px;
    }
  }

  .ant-table-tbody {
    >tr>td {
      height: 48px;
      font-size: 16px;
      line-height: 22px;
      padding: 0 16px;
    }
  }

  .ant-table-column-sorter {
    display: none !important;
  }

  // .ant-table-column-has-sorters {
  //   &:hover {
  //     background: $white !important;
  //   }
  // }
}

.score-table-row-mobile {
  @include md-max {

    .ant-table-pagination.ant-pagination {
      float: unset !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .matchTeam-column-mobile {
      width: 135px;

      p {
        color: #333333;
        font-family: Roboto;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 20px;
      }
    }

    .matchType-column-mobile {
      width: 100px;
    }

    .status-column-mobile {
      width: 100px;
    }

    .score-column-mobile {
      width: 80px;
    }

    .scoresheet-column-mobile {
      width: 120px;
    }
  }
}

.assigned-judging-sessions-table-mobile {
  @include md-max {

    .ant-table-pagination.ant-pagination {
      float: unset !important;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    th {
      font-size: 12px;
      font-weight: normal !important;
      padding-top: 5px !important;
      padding-bottom: 5px !important;
      padding-right: 8px !important;
      padding-left: 8px !important;
    }

    tbody>tr>td {
      padding-top: 8px !important;
      padding-bottom: 8px !important;
      padding-right: 8px !important;
      padding-left: 8px !important;
    }

    .teamTitle-colum-mobile {
      width: 135px;

      p {
        color: #333333;
        font-family: Roboto;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .startTime-colum-mobile {
      font-family: Roboto;
      font-size: 12px !important;
      letter-spacing: 0;
      line-height: 20px;
      width: 150px;

      p {
        color: #333333;
        font-family: Roboto;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 20px;
      }
    }

    .sessionStatusName-colum-mobile {
      width: 200px;

      p {
        color: #333333;
        font-family: Roboto;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 20px;
      }
    }

    .sessionId-colum-mobile {
      width: 80px;

      a {
        font-size: 14px !important;
      }
    }
  }

  .ant-table-body {
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.assigned-judging-sessions-table-mobile,
.score-table-row-mobile {
  .ant-table-footer {
    display: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  @include md-max {
    .ant-table-default {
      border: 1px solid #DDDCDD;
    }

    .ant-table-footer {
      display: block;
      padding: 0;
    }

    .scrollbar {
      height: 24px;
      background-color: #EBEAEB;
      display: flex;
      align-items: center;
      padding-left: 6.5px;

      .scrollbar-wrapper {
        background-color: #ffffff;
        border-radius: 3px;
        height: 6px;
        flex: 1;
        margin: 0px 6.5px;

        .scrollbar-thumb {
          height: 6px;
          width: 120px;
          border-radius: 3px;
          background-color: #C6C4C6;
        }
      }
    }
  }
}

.assigned-judging-sessions-table-mobile {
  @include md-max {
    .scrollbar {
      padding-right: 80px;
    }
  }
}

.assigned-judging-sessions-table-mobile,
.score-table-row-mobile {
  @include md-max {
    .scrollbar {
      padding-right: 120px
    }
  }
}

.judge-sessions-table {
  .ant-pagination-total-text {
    position: absolute;
    left: 10px;

    @include md-max {
      .text {
        font-size: 12px;
      }
    }

    .showing-total-wrapper {
      display: flex;

      .divider {
        margin-left: 5px;
        margin-right: 5px;
      }
    }
  }

  .odd-row {
    background-color: #ffffff;
  }

  .even-row {
    background-color: #E5EFF7;
  }

  thead {
    tr>th {
      background-color: #ffffff;
    }

    .c-sort-field {
      svg {
        display: none !important;
      }
    }
  }
}

.referee-sessions-table {

  .ant-table-tbody>tr>td,
  .ant-table-thead>tr>th {
    border-bottom: 1px solid #2166b3 !important;
  }


  .odd-row {
    background-color: #ffffff;
  }

  .even-row {
    background-color: #E5EFF7;
  }

  .team-title {
    display: flex;
    align-items: center;
    margin-left: 6px;

    .expanded-arrow {
      height: 20px;
      display: flex;
      align-items: center;
      z-index: 1001;
    }

    .title-text {
      color: #333333;
      font-family: Roboto;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 22px;
      width: 200px;
      white-space: nowrap;
      // overflow: hidden;
      text-overflow: ellipsis;
      z-index: 1001;
      margin-left: 5px;
    }

    .long-title {
      overflow: hidden;
      width: 330px;
    }

  }

  .ant-pagination-total-text {
    position: absolute;
    left: 10px;

    .showing-total-wrapper {
      display: flex;

      .divider {
        margin-left: 5px;
        margin-right: 5px;
      }
    }
  }

  thead {
    tr>th {
      padding: 3px 0px;
      background-color: #ffffff;
    }

    tr>th:last-child {
      text-align: end;
      padding-right: 30px;
    }

    tr>th:first-child {
      padding-left: 5px;
    }
  }

  tbody {
    tr>td {
      padding-left: 0px;
      padding-right: 0px;
    }

    tr>td:first-child {
      vertical-align: top;
      padding-left: 10px;
    }

    tr>td:last-child {
      text-align: end;
    }
  }

  .table-item-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 30px;

    .status-badge-container {
      justify-content: flex-start;
    }

    p {
      color: #333333;
      font-family: Roboto;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
    }
  }

  .score-label-title {
    padding-right: 28px;
  }

  .referees {

    &-times-wrapper,
    &-scores-wrapper,
    &-status-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      & .table-item-wrapper {
        align-items: center;
      }

      .referees-time {
        color: #333333;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
        width: 180px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &-scores-wrapper {
      padding-right: 30px;
      justify-content: flex-end;

      .table-item-wrapper {
        justify-content: flex-end;
        padding-right: unset;
        font-size: 14px;
        color: #333333;
        font-family: Roboto;
        letter-spacing: 0;
      }
    }

    &-status-wrapper {
      flex-direction: row;

      .status-badge-container {
        p {
          color: #0066B3;
        }
      }

      &.expanded {
        flex-direction: column;

        .status-badge-container {
          p {
            color: #333333;
          }
        }
      }

      &.unexpanded {
        .table-item-wrapper {
          width: 50px;

          .status-badge-container {
            width: 50px;
          }
        }

        a[disabled] {
          .status-badge-container {
            width: 50px;

            p {
              margin-left: 8px;
              width: 20px;
              text-align: end;
            }
          }
        }
      }
    }
  }

  .ant-table-pagination.ant-pagination {
    float: unset !important;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  th {
    font-size: 12px;
    font-weight: normal !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-right: 8px !important;
    padding-left: 8px !important;
  }

  tbody>tr>td {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
    padding-right: 3px !important;
    padding-left: 3px !important;
  }

  .teamTitle-colum-mobile {
    width: 135px;

    p {
      color: #333333;
      font-family: Roboto;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .startTime-colum-mobile {
    font-family: Roboto;
    font-size: 12px !important;
    letter-spacing: 0;
    line-height: 20px;
    width: 150px;

    p {
      color: #333333;
      font-family: Roboto;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 20px;
    }
  }

  .sessionStatusName-colum-mobile {
    width: 200px;

    p {
      color: #333333;
      font-family: Roboto;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 20px;
    }
  }

  .sessionId-colum-mobile {
    width: 80px;

    a {
      font-size: 14px !important;
    }
  }

  .ant-table-body {
    &::-webkit-scrollbar {
      display: none;
    }
  }
}