.c-event-leaderboard {
  max-width: 940px;
  margin: 0 auto 0px;
  padding-bottom: 100px;

  &__actions {
    margin-top: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__instructions {
    background-color: #F4F4F4;
    padding: 20px 38px 27px 25px;
    margin: 24px 0;
  } 
}
