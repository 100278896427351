.teams-coaches-invite-tile {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 8px;
  padding: 8px 15px;
  &--error {
    background-color: #FFF1F0;
  }


  &__error-icon {
    width: 16px;
    height: 16px;
    color: #ED1C24;
  }
  &__error-message-row {
    display: flex;
    align-items: center;
  }
  &__error-message {
    color: #ED1C24;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 0;
    padding-left: 6px;
  }

  &__btn-delete {
    align-items: center;
    display: flex;
  }

  &__col {
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
    flex: 1;
    // align-items: center;
  }

  &__team-col {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    // align-items: center;

  }

  &__avatar {
    margin-right: 12px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
  }

  &__name {
    color: #333333;
    font-family: Roboto;
    font-size: 1.4rem;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
  }

  &__team {
    color: #333333;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
  }
}