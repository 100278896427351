@import '../../../../shared/styles/main.scss';

.c-fll-rubric-instructions {
  padding: 20px 17px 20px 36px;
  background-color: $sand-gray;


  &__reviewing-criterion {
    width: 100%;
    max-width: 200px;

    &-number {
      background-color:#231F20;
      padding: 10px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 50px;
      overflow: hidden;
    }
    &-numberFLLE {
      background-color: #0166b3;
        padding: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 50px;
        overflow: hidden;
    }
    &-label {
      // text-transform: uppercase;
      // font-family: $roboto;
      // font-size: 1.4rem;
      // letter-spacing: 0;
      // line-height: 20px;
      // color: ;
    }
  }

  .title-with-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @include md-max {
    padding: 18px 20px;
    .h-2--normal {
      font-size: 20px;
      letter-spacing: 0;
      line-height: 28px;
    }

    &__reviewing-criterion {
      display: flex;
      max-width: 80%;

      &-number {
        height: 40px;
        width: 49px;
      }

      .mobile-content-wrapper {
        margin-left: 13px;
      }
    }
  }
}

