.event-schedule-container {
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 48px;
  min-height: 500px;
  padding-bottom: 64px;

  .success-info-block {
    box-sizing: border-box;
    border: 1px solid #97DC76;
    background-color: #EDF9E8;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 12px 16px 24px;
    margin-bottom: 36px;

    .checkmark-with-text {
      display: flex;
      align-items: center;

      .title {
        color: #333333;
        font-family: Roboto;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 22px;
        margin-left: 16px
      }
    }
  }

}

.schedule-generating-loading {
  .title {
    color: #333333;
    font-family: Roboto;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;
    margin-top: 17px;
  }
}