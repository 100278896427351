@import '../../../shared/styles/main.scss';

.c-event-overview-awards {
  padding: 0 0;

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    > button {
      margin-left: 16px;
    }
    .awards-title {
      color: #333333;
      font-family: Roboto;
      font-size: 38px;
      letter-spacing: 0;
      line-height: 46px;
    }
  }

  &__instructions {
    background-color: #F4F4F4;
    padding: 20px 28px 30px 25px;
    margin-bottom: 48px;
  }

  &__downloads {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    // flex-wrap: wrap;
    // margin-right: 45%;
  }

  &__add-award {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 32px;
  }

  &__card {
    margin-bottom: 96px;
  }

  &__card-header {
    height: 62px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 16px;
    > p {
      margin-bottom: 7px;
    }
  }

  &__card-title {
    display: flex;
    align-items: flex-end;
    svg {
      margin-right: 16px;
      min-height: 46px;
    }
  }

  &__nominate {
    display: flex;
    margin-top: 4px;
    button {
      margin-left: 12px;
    }
  }

  &__nominated-team {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div {
      display: flex;
      align-items: center;
    }
  }

  &__place {
    width: 150px;
  }

  &__nominated-actions {
    p {
      margin-right: 16px;
      cursor: pointer;
      &:nth-child(3) {
        margin-right: 0;
      }
    }
  }

  &__assign-del {
    margin-left: 18px;
    width: 18px;
    height: 18px;
    cursor: pointer;
  }

  &__ftc-link {
    padding: 12px 30px;
    background-color: $endeavor-blue;
    color: $white;
    font-size: 1.6rem;
    &:hover {
      text-decoration: underline;
      color: $white;
    }
  }
}


.award-table-wrapper {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
  }
  
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: rgba(0,0,0,.5);
    box-shadow:  0 0 1px rgba(255,255,255,0.2);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,0.2);
  }
  margin-bottom: 100px;
  .table-title {
    color: #333333;
    font-family: Roboto;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 32px;
    margin-bottom: 18px;
  }
  .award-table-control-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 17px 0px;

    .buttons-left, .buttons-right {
        display: flex;
        align-items: center;

        .first-button {
            margin-right: 8px;
        }
        .ant-btn {
            padding: 0px 9px!important;
        }
    }
  }
  .sort-by-dropdown {
    width: 180px;
    .ant-select-selection-selected-value {
      color: #333333;
      font-family: Roboto;
      font-size: 12px !important;
      letter-spacing: 0;
      text-align: center;
    }
    .ant-select-arrow {
      margin-left: 8px;
    }
  }
  .award-table-sort-by-row {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .sort-by-title, .sort-by-dropdown, .buttons-right, .sort-by-direction-button {
      margin-left: 8px;
    }

    .sort-by-title {
      font-weight: normal;
      margin-right: 10px;
    }

    .sort-by-dropdown {
      width: 180px;
      
      .ant-select-selector {
        font-size: 14px;
      }

      .ant-select-selection-selected-value {
        color: #333333;
        font-family: Roboto;
        font-size: 12px;
        letter-spacing: 0;
        text-align: center;
      }
      .ant-select-arrow {
        margin-left: 8px;
      }
    }

    .sort-by-direction-button {
      border: 1px solid #0066B3;
    }
  }

  .award-table {
    min-height: 500px;
    border-top: 1px solid #AEACAE;
    overflow-x: auto;
    table {
      height: 1px;
      width: 960px;
      border-radius: 0px !important;
      font-size: 12px;
      th, td {
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 8px;
        padding-bottom: 8px;
      }
      td {
        vertical-align: top;
      }

      th {
        vertical-align: bottom;
      }
      th {
          text-align: start !important;
          line-height: 16px !important;
          height: 16px;
          padding-top: 0px !important;
          padding-bottom: 0px !important;
          .ant-table-column-title {
              color: #333333;
              font-family: Roboto;
              font-size: 12px;
              letter-spacing: 0;
              line-height: 16px;
          }
          .ant-table-header-column {
            width: 100%;
          }
      }
      tr {
          height: 100%;
          text-align: center;
          td:nth-child(1), td:nth-child(2), td:nth-child(3), td:nth-child(13) {
              height: 100%;
              text-align: start;
          }
          td:nth-child(14) {
              height: 100%;
              text-align: end;
          }
      }
      .ant-table-tbody > tr > td:first-child {
        padding-left: 8px;
      }
      .ant-table-thead > tr > th:first-child {
        padding-left: 8px;
      }
      .ant-table-tbody > tr > td:last-child {
        padding-right: 8px;
      }
      .ant-table-thead > tr > th:last-child {
        padding-right: 8px;
      }

      .ant-table-thead > tr:first-child > th:last-child {
          border-top-right-radius: 0px !important;
      }
      .ant-table-thead > tr:first-child > th:first-child {
          border-top-left-radius: 0px !important;
      }

      .ant-table-thead > tr:first-child > th {
        font-weight: bold;
      }
      
      .ant-table-thead > tr:not(:first-child) > th {
        font-weight: normal;
      }

      .ant-table-thead {
        & > tr:nth-child(2) > th:nth-child(n+3):nth-child(-n+7) {
          text-align: center !important;
        }

        & > tr:nth-child(2) > th:nth-child(11) {
          text-align: start !important;
        } 
      }

      .ant-table-thead {
        & > tr > th {
          background-color: #ffffff;
        }
        & > tr:first-child > th {
          padding-top: 7px !important;
          .ant-table-column-title {
            font-size: 14px !important;
            line-height: 20px !important;
          }
        }

        & > tr:nth-child(1) > th:not(:last-child) {
          border-right: 4px solid #DDDCDD;
        }
        & > tr:nth-child(2) {
          & >th:nth-child(2),& >th:nth-child(4),& >th:nth-child(7),& >th:nth-child(9) {
            border-right: 4px solid #DDDCDD;
          }
        }
        & > tr:nth-child(3) {
          & > th:nth-child(3) {
            border-right: 4px solid #DDDCDD;
          }
        }
        & > tr:nth-child(4), &>tr:nth-child(5) {
          & > th:nth-child(3) {
            border-right: 4px solid #DDDCDD;
          }
        }

        & > tr:nth-child(1) {
          & > th:nth-child(2), & > th:nth-child(4) {
            background-color: #F4F4F4;
          }
        }
        & > tr:nth-child(2) {
          & > th:nth-child(3), & > th:nth-child(4), & > th:nth-child(8), & > th:nth-child(9) {
            background-color: #F4F4F4;
          }
        }
        & > tr:nth-child(3) {
          & > th:nth-child(1), & > th:nth-child(2), & > th:nth-child(3){
            background-color: #F4F4F4;
          }
        }
        & > tr:nth-child(4), & > tr:nth-child(5) {
          & > th:nth-child(1), & > th:nth-child(2), & > th:nth-child(3){
            background-color: #F4F4F4;
          }
        }
      }

      .ant-table-tbody {
        & > tr > td:nth-child(2), & > tr > td:nth-child(4), & > tr > td:nth-child(7), & > tr > td:nth-child(12) {
          border-right: 4px solid #DDDCDD;
        }
      }

      .advance-title-wrapper {
        margin-left: -20px;
      }
    }
    label.checkbox {
      display: inline-block;
      width: 16px;
      height: 16px;
    }

    .checkbox .ant-checkbox-checked::after {
      height: 16px !important;
    }

    .checkbox .ant-checkbox-input {
      height: 16px !important;
      width: 16px !important;
    }

    .checkbox .ant-checkbox-inner {
      box-sizing: border-box !important;
      height: 16px!important;
      width: 16px!important;
      border: 1.5px solid #B7B7B7 !important;
      border-radius: 3px!important;
    }

    .ant-checkbox-inner::after {
      width: 3px;
      height: 6px;
      top: 50%;
      left: 25%;
      position: absolute;
    }
    
    .checkbox {
      margin-bottom: 0px;
      .ant-checkbox-disabled {
        .ant-checkbox-inner {
          border: 1px solid #D2D2D2 !important;
          border-radius: 2px !important;
          background-color: #F5F5F5 !important;
        }
      }
  
      .ant-checkbox-checked.ant-checkbox-disabled {
        .ant-checkbox-inner {
          background-color: #AEACAE !important;
          border: none !important;
        }
      }
    }

    .title-indicator-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 6px;
      div {
        font-size: 3px;
        letter-spacing: 0;
        line-height: 4px;
      }
    }

    .odd-row{
      background-color: #ffffff;
    }
  
    .even-row{
      background-color: #F4F4F4;
    }

    .expanded-row {
      border-top: 1px solid #AEACAE;
      border-bottom: 1px solid #AEACAE;
    }

    .ant-table-expanded-row, .hidden-table-row {
      display: none;
    }

    // .place-wrapper {
    //   .place-empty-title {
    //     font-weight: bold;
    //   }
    //   .ant-select-selection-item {
    //     font-weight: bold;
    //     text-overflow: clip;
    //   }
    //}

    .toggle-wrapper {
      display: flex;
      align-items: center;
      font-weight: bold;

      .inner-text {
        margin-left: 8px;
      }
    }

    .team-name-wrapper {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 120px;
      font-weight: bold;
    }

    .row-replacement {
      height: 21px;
    }

    .innovation-project-rank-wrapper {
      position: relative;
      .view-rubric-link {
        position: absolute;
        top: 36px;
        width: 100px;
        text-align: start;
      }
    }
    

    .award-wrapper {
      #selectFormDropdown {
        height: 24px !important;
        margin-bottom: 8px;
      }
      .form-dropdown {
        width: 130px !important;
        height: 24px !important;
        min-height: unset !important;
        .ant-select-selection {
          height: 24px !important;
          min-height: unset !important;
        }
        .ant-select-selection-selected-value {
          font-size: 12px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 20px;
        }
      }

      .add-new-dropdown {
        .ant-select-selection-selected-value {
          color: #0066B3;
          font-weight: normal;
        }
      }

      .award-dropdown-wrapper {
        height: 402px;
        width: 362px;
        min-width: unset !important;
        left: unset !important;
        right: 0px !important;
        
        .add-section {
          margin: 19px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          border-bottom: 1px solid  #EBEAEB;
          padding-bottom: 19px;

          .title {
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 20px;
          }

          .add-input-row {
            padding-top: 13px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .ant-input {
              width: 230px;
              margin-right: 13px;
            }
            .ant-btn {
              height: 32px;
              width: 80px;
              &:disabled {
                background-color: #B2D1E8;
                border: none;
                span {
                  color: white;
                }
              }
            }
          }
        }

        .menu-section {
          padding: 0 19px 19px 19px;
          height: 210px;
          width: 361px;   
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          text-align: start;

          & > div:nth-child(2) {
            width: 100%;
          }

          .title {
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 20px;
          }

          .ant-select-dropdown-menu-item {
            padding-left: 0px !important;
          }
        }

        .remove-section {
          padding: 13px 20px;
          border-top: 1px solid  #EBEAEB;
          a {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            color: #C50E14;
          }
          &.disabled {
            a {
              color: #BBBBBB;
            }
          }
          
          .title {
            margin-left: 8px;
          }
        }

        .ant-select-dropdown-menu {
          max-height: unset;
        }
      }
    }
    
    .place-wrapper {
      #selectFormDropdown {
        height: 24px !important;
        margin-bottom: 8px;
      }
      .form-dropdown {
        width: 50px !important;
        height: 24px !important;
        min-height: unset !important;
        .ant-select-selection {
          height: 24px !important;
          min-height: unset !important;
        }
        .ant-select-selection-selected-value {
          font-size: 12px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 20px;
        }
      }
    }

    .place-title, .awards-title {
      font-weight: bold;
    }

    .place-title {
      text-align: start;
      padding-left: 11px;
    }
  }
}

.fullscreen {
  background-color: transparent;
  .award-table {
      table {
          width: 100%;
      }
  }

  .ant-modal-body {
      padding: 30px !important;
  }
}

.new-award-added {
  background-color: #EDF9E8;
}