@import '../../../shared/styles/main.scss';

.team-box{
  display: flex;
  align-items: center;
  width: 100%;
  height: 32px;
  border: 1px solid #d9d9d9;
  padding-left: 11px;
  overflow-x: scroll;
  overflow-x: auto;
  white-space: nowrap;
  overflow-y: hidden;
}

.hr-line{
  background-color: #C6C4C6;
}

.empty-panels{
  width: 100%;
  background-color: #E5EFF7;
  padding: 20px 25px;
  font-size: 16px;
  margin-bottom: 106px;
}

.danger{
  color: #C50E14;
}

.judging-panel-card__judges-user.delete_action{
  position: relative;
}

.assigned-to-panel{
  font-weight: 700;
}

.panel-card-title{
  display: flex;
  align-items: baseline;
}

.panel-card-title-container{
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.cursor{
  cursor: pointer;
}

.team-name{
  font-size: 16px;
  color: #333333;
  font-weight: bold;
}

.judging-panel-card-container {
  margin-top: 28px;
  position: relative;
}

.panel-delete-button{
  position: absolute;
  right: -5px;
  top: -15px;
}

.user-delete-button {
  position: absolute;
  right: 0px;
  top: -10px;
}

.judging-panel-card {
  // border-radius: 8px;
 
  padding: 19px 30px;

  &--complete {
    background-color: $sand-gray;
  }

  &--incomplete {
    background-color: $light-yellow;
  }

  &__btn {
    padding: 0;
  }
  &__btn-container {
    text-align: right;
  }

  &__content {
    display: flex;
    flex-direction: column;
    
    @include md {
      flex-direction: row;
      width: 100%;
    }
  }

  &__judges {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    @include md {
      flex-grow: 1;
      margin-bottom: 0px;
    }

    h4 {
      white-space: nowrap;
      color: #333333;
      font-family: Roboto;
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 32px;
    }

    &-list {
      display: flex;
      overflow-x: auto;
      padding: 10px 0;
      // margin-left: 28px;
      width: 100%;
      &::-webkit-scrollbar {
        -webkit-appearance: none;
      }
      
      &::-webkit-scrollbar:vertical {
          width: 10px;
      }
      
      &::-webkit-scrollbar:horizontal {
          height: 10px;
      }
      
      &::-webkit-scrollbar-thumb {
          background-color: rgba(0, 0, 0, .35);
          border-radius: 5px;
          border: 1px solid #ffffff;
      }
      
      &::-webkit-scrollbar-track {
          border-radius: 5px;
          background-color: #ffffff;
      }
    }
    &-user {
        background-color: #fff;
        padding: 10px 30px;
        border-radius: 35px;

        &:not(:last-child) {
          margin-right: 28px;
        }
    }
  }

  &__teams-box {
    background-color: $white;
    margin-top: 11px;
    padding: 0 22px;
    flex-grow: 1;

    .sub-title {
      color: #333333;
      font-family: Roboto;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
      margin-bottom: 10px;
    }

    .warning-text {
      color: #C50E14;
      font-family: Roboto;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
      margin-bottom: 10px;
    }

    &-item {
      color: #333333;
      font-family: Roboto;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 22px;
    }
  }

  &__teams {
    background-color: $white;
    margin-top: 11px;
    padding: 22px;
    flex-grow: 1;

    .sub-title {
      color: #333333;
      font-family: Roboto;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
      margin-bottom: 10px;
    }

    .warning-text {
      color: #C50E14;
      font-family: Roboto;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
      margin-bottom: 10px;
    }

    &-item {
      color: #333333;
      font-family: Roboto;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 22px;
    }
  }

  &__matches-item {
    color: #333333;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
  }
}