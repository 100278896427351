@import './_color.scss';

@import './_font.scss';

@import './_space.scss';

@import './_breakpoints.scss';

.ant-select-dropdown-menu-item {
  font-family: $roboto;
  font-size: 1.4rem !important;
  color: $charcoal-gray;
}

.ant-select-dropdown-menu-item-disabled {
  color: $silver;
}
