.table-custom-row {
    margin-top: 38px;
}

.referee-sessions-table {
    .ant-table-body {
        overflow-x: hidden !important;
        overflow-y: hidden !important;
    }
}

.referee-sessions-table .ant-table-fixed-right {
    box-shadow: initial !important;
    -webkit-box-shadow: initial !important;
    z-index: 10000;
}


.each-row {
    td {
        position: relative;
    }
}

thead .selected-column {
    background-color: #2166b3 !important;
    color: #fff !important;
    font-weight: 600 !important;
}

.white-row-bg {
    position: absolute;
    width: 700%;
    height: 38px;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: #f4f4f4;

    &.expanded {
        background-color: #E5EFF7;
        border-bottom: #0066B3 solid 2px;
    }
}

.white-row-bg-team-number {
    position: absolute;
    width: 525%;
    height: 38px;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: #f4f4f4;

    &.expanded {
        background-color: #E5EFF7;
        border-bottom: #0066B3 solid 2px;
    }
}

.white-row-bg-judge {
    position: absolute;
    width: 550%;
    height: 38px;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: #f4f4f4;

    &.expanded {
        background-color: #E5EFF7;
        border-bottom: #0066B3 solid 2px;
    }
}

.white-row-bg-judge-team-number {
    position: absolute;
    width: 480%;
    height: 38px;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: #f4f4f4;

    &.expanded {
        background-color: #E5EFF7;
        border-bottom: #0066B3 solid 2px;
    }
}

.table-first-row {
    margin-top: 16px;
    margin-left: 10px;
}

.time-only {
    font-weight: bold;
    color: #000;
}

.blank-space {
    background-color: aqua;
    width: 100%;
    height: 30px;
}

.team-name-number {
    background-color: #e5eff7;
}

.sort-section {
    display: flex;
    margin-right: 80px;
}

.sort-section-overview {
    display: flex;
    margin-right: 0;
    align-items: center;
}

.sort-section-admin {
    display: flex;
    color: #fff;
    font-size: 14px;
    align-items:center;
}

.scoring-table-control-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 17px 0px;

    .buttons-left,
    .buttons-right {
        display: flex;
        align-items: center;

        .first-button {
            margin-right: 8px;
        }

        .ant-btn {
            padding: 0px 9px !important;
        }
    }

    .admin-dashboard__search-bar {
        border: 1px solid #C1C1C1;
        padding-right: 5px;
        margin-right: 19px;

        input {
            border: none !important;
        }

        .ant-input:focus {
            box-shadow: none;
        }
    }
}

@media only screen and (max-width: 600px) {
    .referee-sessions-table .ant-table-fixed-right {
        box-shadow: -6px 0 6px -4px rgba(0, 0, 0, 0.1%) !important;
        -webkit-box-shadow: -6px 0 6px -4px rgba(0, 0, 0, 0.1) !important;
    }
}

@media screen and (min-width:340px) and (max-width:768px) {
    .referee-sessions-table {
        .ant-table-body {
            overflow-x: scroll !important;
            overflow-y: initial !important;
        }
    }

}