@import '../../../shared/styles/main.scss';

.card-tile {
  height: 150px;
  border: 1px solid #EBEBEB;
  background-color: #FFFFFF;
  box-shadow: 0 3px 5px 0 rgba(0,0,0,0.1);
  margin-bottom: 20px;
  padding: 16px 15px 15px 19px;
  position: relative;

  &__delete-btn {
    position: absolute;
    right: 0;
    height: unset;
    bottom: 0;
    border: 1px solid transparent;

    &:focus {
      border: 1px solid;
    }

    &-top-right {
      position: absolute;
      right: -25px;
      top: -10px;
    }
  }

  &--pending {
    background-color: $sand-gray !important;
    box-shadow: none !important;
    border: transparent !important;

  }

  &__heading-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &__heading-text-wrapper {
    flex: 1;
  min-width: 0;
  p, a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
    // span {
    //   p, a {
    //     white-space: nowrap;
    //     overflow: hidden;
    //     text-overflow: ellipsis;
    //   }
    // }
  }
  &__buttons-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  
  }

  &__button {
    padding: 0px;
    height: unset;
    cursor: default;
    &:focus, &:hover {
      outline: 1px solid;
    }
  }

  &__edit-btn {
    display: flex;
    height: 25px;
    &:focus, &:hover {
      outline: 1px solid;
    }
  }

  &__edit-icon {
    color: $endeavor-blue;
    font-size: 20px;
  }

  &__resend-btn {
    display: flex;
    
  }

  &__resend--expired {
    display: flex;
    align-items: center;
  }

  &__resend-text {

    &--expired {

    }
  }

  &__resend-icon   {
    color: $endeavor-blue;
    font-size: 20px;

    &--expired {
      color: $dark-red;
      font-size: 20px;
    }
  }

  &__avatar {
    border: 1px solid #EBEBEB;
    margin-top: 4px;
    margin-right: 5px;
    width: 50%;
    height: 50%;
  }

  &__last-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    overflow: hidden;
  }
}
