@import '../../../shared/styles/_breakpoints.scss';
@import '../../../shared/styles/_color.scss';

.form-dropdown {

  border-radius: 0px !important;
  // width: 180px;
  min-height: 48px !important;
  // border: 1px solid transparent;
  background-color: $white;
 
  .ant-select-selection__choice {
    font-size: 1.6rem;
    color: $charcoal-gray;
    // height: 30px;
    // line-height: 30px;
    // background-color: #F4F4F4;
    // // padding-right: 4px;
    // &__remove {
    //   line-height: 28px;
    // }
  }
  .ant-select-selection__choice__content {
    padding-right: 5px;
  }
  .ant-select-selection {
    min-height: 48px !important;
    border-radius: 0px;
    background-color: $white;
    box-shadow: 0 3px 5px 0 rgba(0,0,0,0.1);
    // border: 1px solid transparent;
    // &:focus {
    //   border: 1px solid #1890FF !important;
    //   background-color: #FFFFFF;
    //   border-right-width: 2px !important;
    //   border-left-width: 2px !important;
  
    // }
    // &:hover {
    //   border: 1px solid #1890FF !important;
    //   background-color: #FFFFFF;
    //   border-right-width: 1px !important;
    //   border-left-width: 1px !important;
    //   border-bottom-width: 1px !important;
    //   box-shadow: none;
    //   border-bottom: 1px solid #1890FF;
    // }
    
  }
  .ant-select-selection__rendered {
    line-height: 48px !important;
    color: $charcoal-gray;

  }
  .ant-select-lg {
    height: 48px !important;
    .ant-select-selection__rendered {
      line-height: 48px !important;
    }
  }
  
  .ant-select-lg .ant-select-selection__rendered {
    line-height: 48px !important;
}

.ant-select-selection-selected-value {
  font-size: 16px;
}

.ant-select-dropdown-menu-item {
  font-size: 16px !important;
}

  .ant-select-selection__placeholder {
    font-size: 16px;
    letter-spacing: 0;
    // line-height: 22px;
    // color: $charcoal-gray;
  }

}


.ant-select-dropdown-menu-item {

  font-size: 1.6rem !important;

}