@import '../../../shared/styles/_space.scss';
@import '../../../shared/styles/_breakpoints.scss';
@import '../../../shared/styles/_color.scss';
@import '../../../shared/components/Link/Link.scss';
@import '../../../shared/components/Paragraph/paragraph.scss';

.team-added {

  &__name-wrapper {
    display: flex;
    align-items: center;
  }

  &__label {
    color: #333333;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 20px;
  }

  &__row-wrapper {
    margin-bottom: 12px;
  }
  &__row {
    min-height: 48px;
    background: white;
    padding: 14px;
    cursor: not-allowed;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;

    &--name {
      cursor: not-allowed;
      width: 170px;
      height: 48px;
      background: white;
      padding: 14px;
      display: flex;
      flex-wrap: nowrap;
      display: flex;
      flex-direction: row;
    }
  }

  &__name {
    font-weight: 500;
    text-overflow: ellipsis;
    font-size: 1.6rem;
    white-space: nowrap;
    overflow: hidden;
    color: $charcoal-gray
  }

  &__data {
    color: $charcoal-gray;
    font-size: 1.6rem;
    letter-spacing: 0;
    line-height: 22px;
  }

}