@import '../../../shared/styles/main.scss';

.add-users-list {
  margin-top: 42px;
  @include md {
    max-width: 300px;
    margin-left: auto;
  }

  &__data {
    padding: 13px;
    &--no-data {
      border: 1px solid #D9D9D9;
      min-height: 330px;
      margin-top: 8px;
    }

    &--has-data {
      box-shadow: 0 5px 11px 0 rgba(67,104,132,0.24);
      margin: 4px;
      min-height: 330px;
    }
  }

  &__btn {
    text-align: right;
    margin-top: 36px;
  }
}