@import '../../../shared/styles/main.scss';
.c-title-card {

  &__team {
    max-width: 150px;
  }

  @include md-max {
    &__team {
      display: flex;
      max-width: unset;
      flex-wrap: wrap;
      p {
        margin-right: 5px;
      }
    }
  }
}