@import '../../../../shared/styles/_breakpoints.scss';
@import '../../../../shared/components/Heading/heading.scss';
@import '../../../../shared/styles/_color.scss';

.event-schedule-section {
  margin-top: 64px;
  position: relative;

  &__manage {
    position: absolute;
    right: 0;
    padding-right: 20px;
  }

  &__new-meeting {
   button {
     padding: 0;
   }
  }

  &__cards {
    padding: 10px 11px;
    margin-top: 10px;
    background-color: $sand-gray;
    position: relative;
  }

  &__cards-section {
    display: flex;
    flex-direction: column;

    @include md {
      flex-direction: row;
    }
  }

  &__delete-event {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 16px 0 -48px;

    > .checkbox {
      margin-bottom: 0;
      align-items: center;
      .ant-checkbox-input, .ant-checkbox-inner {
        margin-top: 1px;
        height: 16px !important;
        width: 16px !important;
      }
      .ant-checkbox-checked::after {
        height: 16px !important;
      }
      .ant-checkbox-inner::after {
        left: 25%;
        width: 3.5px;
        height: 7px;
      }
    }
  }

  &__delete-btn {
    padding: 7px 40px !important;
  }
}