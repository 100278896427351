@import '../../../../../../shared/styles/_color.scss';
@import '../../../../../../shared/styles/_breakpoints.scss';
@import '../../../../../../shared/components/Link/Link.scss';
@import '../../../../../../shared/components/Paragraph/paragraph.scss';


.invite-list {
  height: 296px;
  border: solid 1px $alto-gray;
  // overflow-y: scroll; 
  padding: 20px;
  margin-top: 20px;

  &--invitees {
    background-color: $white;
    margin: 20px 5px 12px 2px;
    padding: 20px;
    box-shadow: 0 2px 8px 0 rgba(0,0,0,0.15);
    overflow-y: auto;
    height: 296px;
    padding: 20px;
  }
  &__btn-wrapper--outer {
    display: flex;
  }
  &__btn-wrapper--inner {
    margin-left: auto;
  }
  &__wrapper {
    margin-top: 40px;
  }
}

.manual {
  justify-content: space-between !important;

  &--btn-wrapper {
    display: flex;
    flex-direction: column;
    @include xs {
      justify-content: space-between !important;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
    }
    .ant-form-item {
      margin-bottom: 0 !important;
    }
  }

  &__form {
    margin-top: 26px;

    &__label {
      color: rgba(0, 0, 0, 0.25);
    }

  &__checkbox {
    margin-top: 8px;
  }
  }

}


.add-volunteers-manually {

  &__tile {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 8px;
    align-items: center;
    justify-content: space-between;
    
    &-delete {
      display: none;
 
    }

    &:hover {

      .add-volunteers-manually__tile-delete {
        display: block;

      }
    }


    &-inner {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 8px;
      align-items: center;
    }

    &-avatar {
      margin-right: 8px;
    }
    &-name {
      color: #333333;
      font-family: Roboto;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 20px;
    }

    &-email {
      color: #0066B3;
      font-family: Roboto;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 20px;
    }


    
  }


}