.c-event-program-logos {
  // position: absolute;
  // top: 0;
  // right: 0;
  height: 60px;
  display: flex;
  align-items: center;

  svg:nth-child(2) {
    width: 69px;
    height: 60px;
  }

  .c-event-fll-current-logo {
    // width: 60px;
    height: 85px;
    margin-top: 35px;
    padding-right: 5px;
    padding-bottom: 5px;
  }
}
