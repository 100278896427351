@import '../../../shared/styles/_color.scss';
@import '../../../shared/styles/_font.scss';

.input {
  border-radius: 0px !important;
  font-family: $roboto;
  color: $charcoal-gray;
  
  .ant-input {
    border-radius: 0 !important;
    font-family: $roboto;
    color: $charcoal-gray;
  }
  &:focus, &:hover {
    // border: 1px solid #1890FF;
    background-color: #FFFFFF;
    border-right-width: 1px !important;
    border-left-width: 1px !important;
    outline: none;
    box-shadow: none;
  }

  &--large {
    height: 48px;
  }

  .ant-input-large {
    height: 48px;
    color: $charcoal-gray !important;
  }
}

.input--disabled {
  height: 48px;
  border-radius: 0;
  border: 1px solid #EDEDED;
  background-color: rgba(0,0,0,0.04) !important;
  color: $charcoal-gray !important;
  &:hover {
    border: 1px solid #EDEDED !important;
  }

  .ant-input-large {
    height: 48px;
    color: $charcoal-gray !important;
  }
}
