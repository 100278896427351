@import '../../../../../shared/styles/main.scss';

.add-first-user {
  &__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    sup {
      top: -1.1em;
      position: relative;
      font-size: 1.2rem;
      line-height: 2rem;
      vertical-align: baseline;
    }
  }

  &__content {
    max-width: 780px;
    margin: auto;
    margin-top: 36px;
  }

  &__manual-tab {
    display: flex;
    flex-direction: column;
    @include md {
      flex-direction: row;
    }
  }

  &__manual-col {
    width: 100%;
    @include md {
      width: 50%;
    }
    &--form {
      @include md {
        border-right: 1px #DDDCDD solid;
      }
    }
  }
}
