@import '../../../shared/styles/main.scss';


.checkbox {
  margin-bottom: 12px;
  display: flex;
  
  .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: $white;
    border: 1.5px solid #EDEDED !important;

  }
  &-label {
    color: #333333;
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;

    &--disabled {
      color: rgba(0, 0, 0, 0.25);
      font-size: 16px;
      letter-spacing: 0;
      line-height: 22px;
    }
  }
  .ant-checkbox-inner {
    box-sizing: border-box !important;
    height: 24px !important;
    width: 24px !important;
    border: 1.5px solid #B7B7B7 !important;
    border-radius: 3px !important;
    // background-color: #FFFFFF !important;
  }
  // .ant-checkbox {
  //   box-sizing: border-box;
  //   height: 24px;
  //   width: 24px;
  //   border: 1.5px solid #B7B7B7;
  //   border-radius: 3px;
  //   background-color: #FFFFFF;
  // }

    .ant-checkbox-input {
      height: 24px !important;
      width: 24px !important;
    }
 .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0066B3;
  border: 1.5px solid #0066B3 !important;


  &::after {
    border: 1px solid #fff;
    transform: rotate(45deg) scale(1.5) translate(-50%, -50%);
    position: absolute;
    display: table;
    border: 1px solid #fff;
    border-top: 0;
    border-left: 0;
    
    // -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
    // -ms-transform: rotate(45deg) scale(1) translate(-50%, -50%);
    transform: rotate(45deg) scale(1.5) translate(-50%, -50%);
    opacity: 1;
    // -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    content: ' ';
  }
 }

 .ant-checkbox-checked {
   &::after {
     height: 24px !important;
   }
 }

}