@import '../../../shared/styles/main.scss';

.c-comment-tile {
  padding: 16px 24px;
  background-color: $white;
  position: relative;
  &--scoring {
    box-shadow: 0 5px 11px 0 rgba(67,104,132,0.24);

  }
  &--judging {
    border: 1px solid #DDDDDD;
    
  }
  &__meta {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 24px;
    @include sm {
      flex-direction: row;
    }
  }

  &__delete {
    position: absolute;
    top: 0;
    right: 0;
    color: white;
  }

  &__edit {
    position: absolute;
    bottom: 24px;
    right: 9px;
    color: $endeavor-blue;
  }
}

.with-right-margin {
  margin-right: 20px;
}