.input-password {
  border-radius: 0px !important;
  &:focus {
    
  }
  &--large {
    height: 45px;
  }
  .ant-input-large {
    height: 45px;
  }
  input {
    border-radius: 0px !important;
  }
}