@import '../../../../shared/styles/_color.scss';
@import '../../../../shared/styles/_space.scss';

.tabs-dashboard {

  .ant-tabs-ink-bar {
    background: transparent;
  }
  .ant-tabs-nav {
    color: $white;
  }
  .ant-tabs-nav .ant-tabs-tab {
    padding: 8px 28px 8px 28px;
    font-size: 1.6rem;
    
  }
  .ant-tabs-content {
    // padding-left: 8px;
    // padding-right: 8px;
  }
  .ant-tabs-extra-content {
    line-height: inherit;
    
  }
  .ant-tabs-tab-active {
    color: $charcoal-gray !important;
    font-size: 1.6rem;
    font-weight: bold;
    background-color: $white;

  }

  .ant-tabs-bar {
    margin: 0 0 16px 0;
    border-bottom: 0;
  }

  .ant-tabs-tab {

    &:hover {
      // text-decoration: underline;
      color: $white;
    }
  }

  .ant-tabs-tab-prev, .ant-tabs-tab-next {
    color: $white;
  }
}