@import '../../../shared/styles/main.scss';

.choose-file-card {
  height: 180px;
  width: 160px;
  border: 2px dashed rgba(0,0,0,0.15);
  background-color: rgba(0,0,0,0.02);
  display: flex;
  flex-direction: column;

  &-icon {
    height: 60px;
    color: $white;
    width: 60px;
    margin: 24px auto;

    &--document {
      height: 60px;
      color: $white;
      width: 48px !important;
      margin: 24px auto;
    }
  }
  &-btn {
    margin: 0 auto !important;
  }
}