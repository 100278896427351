@import '../../../../shared/styles/_breakpoints.scss';
.c-edit-event-info {

  &__title {
    display: flex;
    justify-content: space-between;
  }
  &__title-schedule {
    display: flex;
    justify-content: space-between;
    margin-bottom: 48px;
  }
}

.more-detail-collapse {
  background-color: white;

  .ant-collapse-item {
    border: none;
  }

  .ant-collapse-header {
    background-color: #F4F4F4;
    padding: 8px 15px !important;

    .ant-collapse-arrow {
        color: #0066B3;
    }
  }

  &-title {
    color: #333333;
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    margin-right: 20px;
  }
}
.submit-group-wrapper{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  margin-top: 64px;

  .submit-message {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 24px;

    span {
      width: 90%;
      margin-left: 18px;
    }
  }

  .submit-button-group {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  
    .save-button {
      margin-right: 12px;
    }
  }
}

@include md-max {
  .submit-group-wrapper {
    flex-direction: column;
    align-items: flex-start;
    .submit-button-group {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      .save-button {
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .generate-tooltip-wrapper {
        width: 100%;
      }

      .btn-primary-container {
        width: 100%;
        .ant-btn {
          width: 100%;
        }
      }
    }
  }
}