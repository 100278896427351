@import '../../../shared/styles/main.scss';

.event-overview-header {
  display: flex;
  flex-direction: column;

  &__link-tab {
    color: #0066B3;
    &:hover {
      color: #0066B3;
    }
  }

  &__title {
    border-bottom: 1px solid #4085AF;
    padding-top: 20px;
    padding-bottom: 2px;
    margin-bottom: 24px;
    margin-right: 150px;
  }

  &__subtitle {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }

  &__date-location {
    flex-direction: row;
    display: flex;
    align-items: center;
    color: $white;
  }
}

.event-overview-header-team-selection {
  padding: 28px 14px;

  &__menu-item {
    display: flex;
    align-items: center;
    color: $endeavor-blue;
  }

  &__item {
    font-size: 16px;
    line-height: 1.5;
    font-family: $roboto;
    list-style: none;
  }

  &__sub-title {
    font-size: 1.4rem;
    color: $charcoal-gray;
    margin-bottom: 12px;
    padding: 16px;
  }

  &__menu {
    border: transparent;
  }

  &__title {
    display: flex;
    align-items: center;
  }

  &__icon {
    margin-left: 10px;
    color: $white !important;
    cursor: pointer;
    // &:hover {
    //   -webkit-transform: rotate(180deg);
    //   -moz-transform: rotate(180deg);
    //   -o-transform: rotate(180deg);
    //   -ms-transform: rotate(180deg);
    //   transform: rotate(180deg);
    // }
  }
}

.event-overview-back-row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &-link {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div:not(:last-child) {
      margin-right: 28px;
    }
  }
}