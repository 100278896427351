.event-volunteers {
  min-height: 500px;
  margin-right: auto;
  margin-left: auto;
  max-width: 822px;
  margin-top: 12px;
}

.email-language-selection {
  margin-left: 5px;
  background-color: #F4F4F4;
  width: 150px;
}