@import '../../../../shared/styles/main.scss';


.c-judging-rubric-evidence {
  padding: 48px 0 36px 0;
  background-color: $sand-gray;

  @include md-max {
    padding: 0px;
  }
}